import { ArrayHelpers, Field, FieldArray, Form, Formik, getIn } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Button, Icon, TextArea } from "components";
import { useEffect, useState } from "react";
import { services } from "services";
import validationSchema from "views/Api/BankSoal/validationSchema";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import useAsset from "services/useAsset";
import toast from "react-hot-toast";
import MultipleChoicesItem from "./components/MultipleChoicesItem";
import CustomDropdown, { MathOption } from "./components/CustomDropdowns";
import numberToAlphabet from "utilities/convertIntegerToChar";
import SubmitButtons from "./components/SubmitButtons";
import DropdownField from "./components/DropDownField";
import ScoreFields from "./components/ScoreField";
import TextField from "./components/TextField";

const initialQuestion = {
  description: null,
  is_essay: 0,
  asset: {
    id: "",
    name: "",
    url: "",
    full_asset: "",
  },
  question: "",
  options: [
    {
      option: "",
      optionImageFile: null,
      optionImageUrl: "",
      asset_id: null,
      is_answer: false,
    },
    {
      option: "",
      optionImageFile: null,
      optionImageUrl: "",
      asset_id: null,
      is_answer: false,
    },
  ],
  answer: "",
  explanation: "",
  explanation_asset_id: "",
  explanationAssetFile: "",
  explanationAssetUrl: "",
  explanationAssetName: "",
  true_score: "",
  false_score: "",
  questionAssetFile: "",
  questionAssetUrl: "",
  asset_id: null,
  questionAssetName: "",
};

export default function CreateQuestionBaru() {
  const router = useNavigate();
  const [grades, setGrades] = useState<any[]>([]);
  const [subjects, setSubjects] = useState<any[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpload, setIsUpload] = useState(false);
  const [selectedOption, setSelectedOption] = useState<
    { question_id: number; option: MathOption }[]
  >([]);
  const { directoryId } = useParams();
  const location: {
    state: {
      directory_id?: number | null;
    };
  } = useLocation();
  if (directoryId !== undefined && location.state.directory_id === undefined) {
    location.state.directory_id = parseInt(directoryId);
  }
  const { upload } = useAsset();

  const currentUrl = window.location.href;

  const params = useParams();
  const id = params ? params.id : null;

  // state default value for edit page
  const [currentDirectory, setCurrentDirectory] = useState<Number | null>(null);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    subject: "",
    difficulty: 0,
    grade: "",
    true_score: "",
    false_score: "",
    questions: [initialQuestion],
    explanation_asset: {
      name: "",
      full_asset: "",
      id: "",
    },
  });
  async function GetGrade() {
    services
      .get("admin/dropdown/grade")
      .then((resp) => {
        const data = resp?.data?.data;

        setGrades(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function GetSubject() {
    services
      .get("admin/dropdown/subject")
      .then((resp) => {
        const data = resp?.data?.data;
        setSubjects(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    if (directoryId !== undefined) {
      setCurrentDirectory(Number(directoryId));
    }
    async function GetDetailSoal() {
      services
        .get(`/question-package/${id}`)
        .then(async (resp) => {
          const data: any = resp?.data.data;
          if (data.directory && data.directory.id) {
            setCurrentDirectory(data.directory.id);
          }
          const mappedInitialFormState = {
            name: data?.name || "",
            description: data?.description || "",
            subject: data?.subject.id || "",
            difficulty: data?.difficulty || 0,
            grade: data?.grade.id || "",
            true_score: data?.true_score ?? "",
            false_score: data?.false_score ?? "",
            questions: data?.questions.map((question: any) => {
              const multipleChoices = question?.options || [];

              if (!question?.is_essay && multipleChoices.length < 2) {
                multipleChoices.push({
                  option: "",
                  is_answer: 0,
                });
              }

              return {
                ...question,
                options:
                  question?.is_essay === 1
                    ? [
                        {
                          option: "",
                          optionImageFile: null,
                          optionImageUrl: "",
                          asset: null,
                          asset_id: null,
                          is_answer: false,
                        },
                        {
                          option: "",
                          optionImageFile: null,
                          optionImageUrl: "",
                          asset_id: null,
                          asset: null,

                          is_answer: false,
                        },
                      ]
                    : multipleChoices.map((option: any) => ({
                        ...option,
                        optionImageFile: option?.asset?.full_asset || null,
                        optionImageUrl: option?.asset?.full_asset || null,
                        asset_id: option?.asset?.id || null,
                      })),
                questionAssetFile: isImage(question.asset?.full_asset)
                  ? "image"
                  : "",
                questionAssetUrl: question.asset?.full_asset || "",
                asset_id: question.asset?.id || null,
                explanationAssetFile: isImage(
                  question.explanation_asset?.full_asset
                )
                  ? "image"
                  : "",
                explanationAssetUrl:
                  question.explanation_asset?.full_asset || "",
                explanation_asset_id: question.explanation_asset?.id || null,
                questionAssetName: question.asset?.name || "",
                explanationAssetName: question.explanation_asset?.name || "",
              };
            }) || [initialQuestion],
            explanation_asset: data?.explanation_asset || {
              name: "",
              full_asset: "",
              id: "",
            },
          };
          setFormData(mappedInitialFormState);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    if (currentUrl.includes("edit")) {
      setIsEdit(true);
      GetDetailSoal();
    }
    GetGrade();
    GetSubject();
  }, [currentUrl, directoryId, id]);

  const isImage = (path: string) => {
    return ["jpg", "jpeg", "png"].some((val) => path?.includes(val));
  };

  const handleChangeOptions = (question_id: number, option: MathOption) => {
    const updatedSelectedOption = [...selectedOption];
    const index = updatedSelectedOption.findIndex(
      (item) => item.question_id === question_id
    );

    if (index > -1) {
      updatedSelectedOption[index] = { question_id, option };
      setSelectedOption(updatedSelectedOption);
    } else {
      setSelectedOption([...selectedOption, { question_id, option }]);
    }
  };

  const getValidTrueAnswer = (question: any) => {
    let currentAnswer = question.answer;
    if (!currentAnswer) {
      return {
        label: "",
        value: "",
      };
    }
    question.options.forEach((option: any, index: number) => {
      const prefix = numberToAlphabet(index + 1) + ". ";
      if (option.option.replace(prefix, "") === currentAnswer) {
        currentAnswer = option.option;
      }
    });
    return {
      label: currentAnswer,
      value: currentAnswer,
    };
  };

  const handleSubmit = async (values: any) => {
    const questions = values.questions.map((value: any) => ({
      ...value,
      is_essay: 0,
      description: "",
    }));

    const newUpdatedQuestions = questions.map((question: any) => {
      // Find the index of the option with the same content as the answer
      const updatedOptions: any[] = question.options.map((option: any) => ({
        ...option,
        is_answer: option.option === question.answer, // Set is_answer to true only for the matched option
      }));
      const fifthOption = updatedOptions[4];
      if (!fifthOption?.option && updatedOptions.length === 5) {
        updatedOptions.pop();
      }

      return {
        ...question,
        options: updatedOptions,
      };
    });

    const data = {
      description: values.description,
      grade_id: parseInt(values.grade),
      subject_id: parseInt(values.subject),
      major_id: null,
      name: values.name,
      difficulty: values.difficulty,
      true_score: values?.true_score,
      false_score: values?.false_score,
      questions: newUpdatedQuestions,
      explanation_asset_id: values.explanation_asset.id,
      directory_id: location.state?.directory_id ?? currentDirectory ?? null,
    };
    toast.loading("Sedang menyimpan soal...", {
      duration: 3000,
    });

    if (!isEdit) {
      await services
        .post("/question-package", data)
        .then((resp) => {
          toast.success("Berhasil menyimpan soal!");
          router("/bank-soal");
        })
        .catch((err: any) => {
          const message = err.response.data.message;
          let errorMessage = "Something went wrong";
          if (message.includes("option")) {
            errorMessage = "Opsi Jawaban harus diisi";
          } else if (message.includes(".answer")) {
            errorMessage = "Jawaban Harus di isi";
          } else if (message.includes(".explanation")) {
            errorMessage = "Pembahasan Harus di isi";
          }
          toast.error("Gagal menyimpan soal! " + errorMessage);
        });
    } else {
      await services
        .put(`/question-package/${id}`, data)
        .then((resp) => {
          toast.success("Berhasil menyimpan soal!");
          router("/bank-soal");
        })
        .catch((err: any) => {
          const message = err.response.data.message;
          let errorMessage = "Something went wrong";
          if (message.includes("option")) {
            errorMessage = "Opsi Jawaban harus diisi";
          } else if (message.includes(".answer")) {
            errorMessage = "Jawaban Harus di isi";
          } else if (message.includes(".explanation")) {
            errorMessage = "Pembahasan Harus di isi";
          }
          toast.error("Gagal menyimpan soal! " + errorMessage);
        });
    }
  };

  return (
    <>
      <h1 className="font-bold text-xl">{isEdit ? "Edit" : "Tambah"} Soal</h1>
      {isLoading && isEdit ? (
        <div>Loading</div>
      ) : (
        <Formik
          validationSchema={validationSchema}
          initialValues={formData}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <div className="bg-white p-6 shadow-xl rounded-xl mt-5 text-[#2C3131]">
                <DropdownField
                  name="grade"
                  label="Pilih Tingkatan"
                  options={grades}
                  errors={errors.grade ?? ""}
                />
                <DropdownField
                  name="subject"
                  label="Pilih Pelajaran"
                  options={subjects}
                  errors={errors.subject ?? ""}
                />
                <DropdownField
                  name="difficulty"
                  label="Pilih Tingkatan Kesulitan"
                  options={[
                    { id: 1, name: "Mudah" },
                    { id: 2, name: "Sedang" },
                    { id: 3, name: "Sulit" },
                  ]}
                  errors={errors.difficulty ?? ""}
                />
                <TextField
                  name="name"
                  label="Judul"
                  placeholder="Masukan Judul"
                  errors={errors.name ?? ""}
                />
                <TextArea
                  label="Deskripsi"
                  required
                  isRichText
                  value={values.description}
                  onChange={(val) => setFieldValue("description", val)}
                />
                {errors.description && (
                  <p className="text-[#F04438] mt-0.5">{errors.description}</p>
                )}
                <ScoreFields />
                <h1 className="font-bold text-xl my-6">
                  Materi pembahasan soal
                </h1>
                {values.explanation_asset.name ? (
                  <div className="flex items-center bg-gray-200 py-2 px-4 rounded-lg justify-between">
                    <div className="flex items-center gap-x-2">
                      <FontAwesomeIcon icon={icon({ name: "file-lines" })} />
                      <span>{values.explanation_asset.name}</span>
                    </div>
                    <div className="flex items-center gap-x-2">
                      <FontAwesomeIcon
                        icon={icon({ name: "eye", style: "regular" })}
                        className="mr-3 cursor-pointer"
                        onClick={() =>
                          window.open(
                            values.explanation_asset.full_asset,
                            "_blank"
                          )
                        }
                      />
                      <FontAwesomeIcon
                        icon={icon({ name: "trash-can" })}
                        className="cursor-pointer"
                        onClick={() => {
                          setFieldValue("explanation_asset", {
                            name: "",
                            full_asset: "",
                            id: "",
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <input
                      type="file"
                      name="discussion-material"
                      id="discussion-material"
                      hidden
                      disabled={isUpload}
                      onChange={(e) => {
                        const currentFile = e.target.files?.[0];

                        if (currentFile) {
                          setIsUpload(true);
                          toast.promise(
                            upload({
                              file: currentFile,
                              directory_id: currentDirectory
                                ? currentDirectory
                                : 0,
                            }),
                            {
                              loading: "...",
                              success: (response) => {
                                if (response) {
                                  setFieldValue("explanation_asset", {
                                    name: response.name,
                                    full_asset: response.full_asset,
                                    id: response.id,
                                  });
                                }
                                setIsUpload(false);

                                return `Uploaded ${currentFile?.name} successfully!`;
                              },
                              error: () => {
                                setIsUpload(true);
                                return "Gagal upload.";
                              },
                            }
                          );
                        }
                      }}
                    />
                    <label
                      htmlFor="discussion-material"
                      className="flex items-center gap-x-2 bg-primary w-max px-3 py-2 rounded-lg cursor-pointer"
                    >
                      <FontAwesomeIcon icon={icon({ name: "add" })} />
                      <span>Tambah materi pembahasan</span>
                    </label>
                  </>
                )}
                <h1 className="font-bold text-xl my-6">Soal</h1>
                <FieldArray name="questions">
                  {({ push, remove }: ArrayHelpers) =>
                    values.questions?.length > 0 && (
                      <div className="space-y-6">
                        {values.questions.map((question, index) => {
                          const number = index + 1;
                          return (
                            <div key={index} className="border rounded-xl">
                              <div className="p-6">
                                <div className="flex flex-col md:flex-row md:items-center">
                                  <h1 className="text-[#2C3131] font-bold items-center">
                                    Nomor {number}
                                  </h1>
                                  <div className="flex mt-2 md:mt-0 md:ml-auto">
                                    <button
                                      type="button"
                                      title="Delete"
                                      disabled={values.questions?.length <= 1}
                                      onClick={() => remove(index)}
                                      className="w-[40px] aspect-square bg-[#F04438] rounded-xl flex justify-center items-center ml-3"
                                    >
                                      <Button.Icon action="delete" />
                                    </button>
                                  </div>
                                </div>
                                <div className="flex gap-x-2 items-start">
                                  <div className="w-[calc(100%-6rem)]">
                                    <TextArea
                                      isRichText
                                      required
                                      label="Pertanyaan"
                                      value={values.questions[index].question}
                                      onChange={(val) =>
                                        setFieldValue(
                                          `questions.${index}.question`,
                                          val
                                        )
                                      }
                                      placeholder="Masukan Pertanyaan Anda..."
                                    />
                                    {getIn(
                                      errors,
                                      `questions.${index}.question`
                                    ) && (
                                      <p className="text-[#F04438] mt-0.5">
                                        Question is required
                                      </p>
                                    )}
                                  </div>
                                  {values.questions[index].questionAssetUrl ? (
                                    <div className="relative rounded-sm overflow-hidden group text-center flex flex-col items-center justify-center">
                                      {values.questions[
                                        index
                                      ].questionAssetFile.includes("image") ? (
                                        <img
                                          src={
                                            values.questions[index]
                                              .questionAssetUrl
                                          }
                                          alt="question-media"
                                          className="w-24 h-24 object-cover mt-8"
                                        />
                                      ) : (
                                        <div className="rounded-sm bg-gray-300 px-2 py-7 w-24 h-24 flex items-center justify-center mt-10">
                                          <FontAwesomeIcon
                                            icon={icon({ name: "photo-film" })}
                                            size="xl"
                                          />
                                        </div>
                                      )}
                                      <div className="mt-1 flex flex-col items-center justify-center gap-y-2">
                                        <div className="absolute w-24 h-24 top-0 flex items-center justify-center bg-gray-900 opacity-75 hidden group-hover:flex gap-x-2 mt-10">
                                          <FontAwesomeIcon
                                            icon={icon({
                                              name: "trash-can",
                                              style: "regular",
                                            })}
                                            color="white"
                                            size="lg"
                                            className="cursor-pointer"
                                            onClick={() => {
                                              setFieldValue(
                                                `questions.${index}.questionAssetFile`,
                                                ""
                                              );
                                              setFieldValue(
                                                `questions.${index}.questionAssetUrl`,
                                                ""
                                              );
                                              setFieldValue(
                                                `questions.${index}.asset_id`,
                                                null
                                              );
                                            }}
                                          />
                                        </div>
                                        <span
                                          className="label label-warning text-sm font-bold w-full cursor-pointer bg-yellow-200 p-2 rounded"
                                          title={
                                            values.questions[index]
                                              .questionAssetName
                                          }
                                          onClick={() =>
                                            window.open(
                                              values.questions[index]
                                                .questionAssetUrl,
                                              "_blank"
                                            )
                                          }
                                        >
                                          Lihat
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <label
                                      htmlFor={`asset-${index}`}
                                      className={
                                        "px-2 py-7 w-24 h-24 rounded border-dashed border cursor-pointer mt-10 text-center"
                                      }
                                    >
                                      <input
                                        type="file"
                                        accept="image/*,audio/*,video/*"
                                        id={`asset-${index}`}
                                        hidden
                                        disabled={isUpload}
                                        onChange={async (e) => {
                                          const currentIndex = parseInt(
                                            e.target.id.split("-")[1]
                                          );
                                          const currentFile =
                                            e.target.files?.[0];

                                          if (currentFile) {
                                            setIsUpload(true);
                                            toast.promise(
                                              upload({
                                                file: currentFile,
                                                directory_id: currentDirectory
                                                  ? currentDirectory
                                                  : 0,
                                              }),
                                              {
                                                loading: "...",
                                                success: (response) => {
                                                  if (response) {
                                                    setFieldValue(
                                                      `questions.${currentIndex}.questionAssetFile`,
                                                      response.type
                                                    );
                                                    setFieldValue(
                                                      `questions.${currentIndex}.questionAssetUrl`,
                                                      response.full_asset
                                                    );
                                                    setFieldValue(
                                                      `questions.${currentIndex}.asset_id`,
                                                      response.id
                                                    );

                                                    setFieldValue(
                                                      `questions.${currentIndex}.questionAssetName`,
                                                      response.name
                                                    );
                                                  }
                                                  setIsUpload(false);

                                                  return `Uploaded ${currentFile?.name} successfully!`;
                                                },
                                                error: () => {
                                                  setIsUpload(true);
                                                  return "Gagal upload.";
                                                },
                                              }
                                            );
                                          }
                                        }}
                                      />
                                      <span>Tambah media</span>
                                    </label>
                                  )}
                                </div>

                                <div className="flex items-center mt-8 relative justify-between flex-wrap">
                                  <h1 className="text-[#2C3131] font-bold items-center">
                                    Jawaban
                                  </h1>
                                  <div className="flex flex-col">
                                    <Field
                                      as="select"
                                      name={`questions.${index}.is_essay`}
                                      className={
                                        getIn(
                                          errors,
                                          `questions.${index}.is_essay`
                                        ) &&
                                        getIn(
                                          touched,
                                          `questions.${index}.is_essay`
                                        )
                                          ? "outline-none border-b border-rose-400 placeholder:text-rose-400 mt-3 pb-2 w-full"
                                          : "outline-none border-b border-[#9DA4B3] placeholder:text-[#9DA4B3] mt-3 pb-2 w-full mb-4"
                                      }
                                    >
                                      <option value={0}>Pilihan Ganda </option>
                                    </Field>
                                    {getIn(
                                      errors,
                                      `questions.${index}.is_essay`
                                    ) && (
                                      <p className="text-[#F04438] mt-0.5">
                                        Type of question is required
                                      </p>
                                    )}
                                  </div>
                                </div>
                                {values.questions[
                                  index
                                ]?.is_essay?.toString() === "0" ? (
                                  <>
                                    <div className="space-y-4 mt-5">
                                      {values.questions[index].options?.length >
                                        0 &&
                                        values.questions[index].options.map(
                                          (option, optionIndex) => {
                                            return (
                                              <MultipleChoicesItem
                                                option={option}
                                                index={index}
                                                question={question}
                                                values={values}
                                                errors={errors}
                                                setFieldValue={setFieldValue}
                                                optionIndex={optionIndex}
                                                touched={touched}
                                                directoryIndex={currentDirectory?.toString()}
                                              />
                                            );
                                          }
                                        )}
                                      <button
                                        type="button"
                                        className="bg-[#FFF200] text-[#38393A] font-medium text-sm px-5 py-2 rounded-xl flex items-center justify-center gap-2"
                                        onClick={() => {
                                          const newOption = {
                                            option:
                                              numberToAlphabet(
                                                values.questions[index].options
                                                  .length + 1
                                              ) + ". ",
                                            optionImageFile: null,
                                            optionImageUrl: "",
                                            asset_id: null,
                                            is_answer: false,
                                          };

                                          const updatedQuestions = [
                                            ...values.questions,
                                          ];
                                          updatedQuestions[index].options.push(
                                            newOption
                                          );

                                          setFieldValue(
                                            `questions.${index}.options`,
                                            updatedQuestions[index].options
                                          );
                                        }}
                                      >
                                        Add New Option
                                      </button>
                                    </div>
                                    <h1 className="text-[#2C3131] font-bold items-center mt-6">
                                      Jawaban Benar
                                    </h1>
                                    <div className="mt-4">
                                      <fieldset className="">
                                        <label className="font-medium text-sm after:content-['*'] after:font-medium after:text-rose-400 after:ml-1">
                                          Pilih Jawaban Yang benar
                                        </label>
                                        <CustomDropdown
                                          options={question.options.map(
                                            (option) => {
                                              const mathOption: MathOption = {
                                                value: option.option,
                                                label: option.option,
                                              };
                                              return mathOption;
                                            }
                                          )}
                                          value={getValidTrueAnswer(question)}
                                          onChange={handleChangeOptions}
                                          question_id={index}
                                          name={`questions.${index}.answer`}
                                          setFieldValue={setFieldValue}
                                        />
                                        <div
                                          style={{ marginTop: "20px" }}
                                        ></div>
                                        {getIn(
                                          errors,
                                          `questions.${index}.answer`
                                        ) && (
                                          <p className="text-[#F04438] mt-0.5">
                                            {getIn(
                                              errors,
                                              `questions.${index}.answer`
                                            )}
                                          </p>
                                        )}
                                      </fieldset>
                                    </div>
                                    <div className="flex gap-x-2 items-start">
                                      <div className="w-[calc(100%-6rem)]">
                                        <TextArea
                                          isRichText
                                          required
                                          label="Pembahasan"
                                          value={
                                            values.questions[index].explanation
                                          }
                                          onChange={(val) =>
                                            setFieldValue(
                                              `questions.${index}.explanation`,
                                              val
                                            )
                                          }
                                          placeholder="Tulis deskripsi..."
                                        />
                                        {getIn(
                                          errors,
                                          `questions.${index}.explanation`
                                        ) && (
                                          <p className="text-[#F04438] mt-0.5">
                                            Explanation is required
                                          </p>
                                        )}
                                      </div>
                                      {values.questions[index]
                                        .explanationAssetUrl ? (
                                        <div className="relative rounded-sm overflow-hidden group text-center flex flex-col items-center justify-center">
                                          {values.questions[
                                            index
                                          ].explanationAssetFile.includes(
                                            "image"
                                          ) ? (
                                            <img
                                              src={
                                                values.questions[index]
                                                  .explanationAssetUrl
                                              }
                                              alt="explanation-media"
                                              className="w-24 h-24 object-cover mt-8"
                                            />
                                          ) : (
                                            <div className="rounded-sm bg-gray-300 px-2 py-7 w-24 h-24 flex items-center justify-center mt-10">
                                              <FontAwesomeIcon
                                                icon={icon({
                                                  name: "photo-film",
                                                })}
                                                size="xl"
                                              />
                                            </div>
                                          )}
                                          <div className="mt-1 flex flex-col items-center justify-center gap-y-2">
                                            <div className="absolute w-24 h-24 top-0 flex items-center justify-center bg-gray-900 opacity-75 hidden group-hover:flex gap-x-2 mt-10">
                                              <FontAwesomeIcon
                                                icon={icon({
                                                  name: "trash-can",
                                                  style: "regular",
                                                })}
                                                color="white"
                                                size="lg"
                                                className="cursor-pointer"
                                                onClick={() => {
                                                  setFieldValue(
                                                    `questions.${index}.explanationAssetFile`,
                                                    ""
                                                  );
                                                  setFieldValue(
                                                    `questions.${index}.explanationAssetUrl`,
                                                    ""
                                                  );
                                                  setFieldValue(
                                                    `questions.${index}.explanation_asset_id`,
                                                    null
                                                  );
                                                  setFieldValue(
                                                    `questions.${index}.explanationAssetName`,
                                                    null
                                                  );
                                                }}
                                              />
                                            </div>
                                            <span
                                              className="label label-warning text-sm font-bold w-full cursor-pointer bg-yellow-200 p-1 rounded"
                                              title={
                                                values.questions[index]
                                                  .explanationAssetName
                                              }
                                              onClick={() =>
                                                window.open(
                                                  values.questions[index]
                                                    .explanationAssetUrl,
                                                  "_blank"
                                                )
                                              }
                                            >
                                              Lihat
                                            </span>
                                          </div>
                                        </div>
                                      ) : (
                                        <label
                                          htmlFor="asset-explanation"
                                          className="px-2 py-7 w-24 h-24 rounded border-dashed border cursor-pointer mt-10 text-center"
                                        >
                                          <input
                                            type="file"
                                            accept="image/*,audio/*,video/*"
                                            id="asset-explanation"
                                            hidden
                                            disabled={isUpload}
                                            onChange={async (e) => {
                                              const currentFile =
                                                e.target.files?.[0];
                                              if (currentFile) {
                                                setIsUpload(true);
                                                toast.promise(
                                                  upload({
                                                    file: currentFile,
                                                    directory_id:
                                                      currentDirectory
                                                        ? currentDirectory
                                                        : 0,
                                                  }),
                                                  {
                                                    loading: "...",
                                                    success: (response) => {
                                                      if (response) {
                                                        setFieldValue(
                                                          `questions.${index}.explanationAssetFile`,
                                                          response.mime
                                                        );
                                                        setFieldValue(
                                                          `questions.${index}.explanationAssetUrl`,
                                                          response.full_asset
                                                        );
                                                        setFieldValue(
                                                          `questions.${index}.explanation_asset_id`,
                                                          response.id
                                                        );
                                                        setFieldValue(
                                                          `questions.${index}.explanationAssetName`,
                                                          response.name
                                                        );
                                                      }
                                                      setIsUpload(false);

                                                      return `Uploaded ${currentFile?.name} successfully!`;
                                                    },
                                                    error: () => {
                                                      setIsUpload(false);
                                                      return "Gagal upload.";
                                                    },
                                                  }
                                                );
                                              }
                                            }}
                                          />
                                          <span>Tambah media</span>
                                        </label>
                                      )}
                                    </div>
                                  </>
                                ) : null}
                              </div>
                              {index === values.questions?.length - 1 && (
                                <div className="border-t p-6">
                                  <button
                                    onClick={() => {
                                      push({
                                        question: "",
                                        is_essay: 0,
                                        options: [
                                          {
                                            option: "",
                                            optionImageFile: null,
                                            optionImageUrl: "",
                                            asset_id: null,
                                            is_answer: false,
                                            asset: null,
                                          },
                                          {
                                            option: "",
                                            optionImageFile: null,
                                            optionImageUrl: "",
                                            asset_id: null,
                                            asset: null,
                                            is_answer: false,
                                          },
                                        ],
                                        answer: "",
                                        explanation: "",
                                        true_score: "",
                                        false_score: "",
                                        questionAssetFile: "",
                                        questionAssetUrl: "",
                                      });
                                    }}
                                    type="button"
                                    className="bg-[#FFF200] text-[#38393A] font-medium text-sm px-5 py-2 rounded-xl flex items-center justify-center gap-2"
                                  >
                                    <Icon.Plus className="w-4 h-4" />
                                    Tambah Soal
                                  </button>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )
                  }
                </FieldArray>{" "}
                <SubmitButtons isEdit={isEdit} />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}

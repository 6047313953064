import { useCallback, useState } from "react";
import { Button, Spinner } from "components";
import useBundle from "services/useBundle";
import Filter from "./fragment/Filter";
import Item from "./fragment/Item";
import { DEFAULT_PAGINATION } from "constant";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Footer from "./fragment/Footer";

export default function Bundling() {
  const navigate = useNavigate();
  const { getList, updateStatus, updatePriority, deleteBundle } = useBundle();

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<BundleItem[]>([]);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  const getData = useCallback(async (params?: BundleListParam) => {
    setLoading(true);
    const response = await getList(params);
    const { data, ...restResponse } = response;

    setList(data);
    setPagination(restResponse);
    setLoading(false);
  }, []);

  const handleUpdate = async (item: BundleItem) => {
    const { id, status } = item;
    const newStatus = status === "1" ? "0" : "1";

    toast.promise(updateStatus(id, newStatus), {
      loading: `Memperbaharui ${item.title}...`,
      success: () => {
        getData();
        return `Berhasil memperbaharui ${item.title}`;
      },
      error: `Gagal memperbaharui ${item.title}`,
    });
  };

  const handleDelete = (item: BundleItem) => {
    toast.promise(deleteBundle(item.id), {
      loading: `Menghapus ${item.title}...`,
      success: () => {
        getData();
        return `Berhasil menghapus ${item.title}`;
      },
      error: `Gagal menghapus ${item.title}`,
    });
  };

  const handleUpdatePriority = async (item: BundleItem) => {
    const { id, view_priority } = item;

    toast.promise(updatePriority(id, view_priority!), {
      loading: `Memperbaharui ${item.title}...`,
      success: () => {
        getData();
        return `Berhasil memperbaharui ${item.title}`;
      },
      error: `Gagal memperbaharui ${item.title}`,
    });
  };

  const navigateAdd = () => {
    navigate("tambah", {
      relative: "path",
    });
  };

  return (
    <>
      <div className="flex items-center justify-between mb-5">
        <div>
          <p className="font-bold">{`Total ${pagination.total} program bundling`}</p>
          <p className="text-xs text-gray-600">Seluruh bundle yang dibuat</p>
        </div>
        <Button.Create text="Tambah bundle" onButtonClick={navigateAdd} />
      </div>
      <Filter onGetData={getData} />
      {loading ? (
        <Spinner />
      ) : list.length ? (
        <div className="space-y-4">
          {list.map((val) => (
            <Item key={val.id} data={val} onUpdate={handleUpdate} onUpdatePriority={handleUpdatePriority} onDelete={handleDelete} />
          ))}
        </div>
      ) : (
        <p className="italic text-center">Tidak ada data</p>
      )}

      <Footer pagination={pagination} onGetData={getData} />
    </>
  );
}

import moment, { unitOfTime } from "moment";

export const today = moment().format("YYYY-MM-DD");

export const lastMonth = (value: number) => {
  return moment().subtract(value, "M").format("YYYY-MM-DD");
};

export const getFormatDate = (date: Date | string, format?: string) => {
  const dateFormat = format || "DD/MM/YYYY";
  let result = "-";

  if (moment(date).isValid()) {
    result = moment(date).format(dateFormat);
  }

  return result;
};

export const getDuration = (
  start: string,
  end: string,
  unit: unitOfTime.Base = "minutes"
) => {
  const duration = moment.duration(moment(end).diff(moment(start)));
  return Number(duration.as(unit).toFixed(2));
};

export const currencyConverter = (amount: number, withoutRp?: boolean) => {
  const _amount = amount < 0 ? 0 : amount;

  return `${withoutRp ? "" : "Rp "}${String(_amount).replace(
    /\B(?=(\d{3})+(?!\d))/g,
    "."
  )}`;
};

export const priceByDiscount = (price: number, discount: number) => {
  if (discount === 0) {
    return currencyConverter(price);
  }

  let cutPrice = price * (discount / 100);

  if (isNaN(cutPrice)) {
    return "";
  }
  cutPrice = Number(cutPrice.toFixed(0));

  const finalPrice = price - cutPrice;
  return currencyConverter(finalPrice);
};

export const timeToMinutes = (time: any) => {
  const [hours, minutes, seconds] = time.split(":").map(Number);
  return hours * 60 + minutes + seconds / 60;
};

export const calculateTotalMinutes = (hour: string, minute: string) => {
  const totalMinutes = parseInt(hour, 10) * 60 + parseInt(minute, 10);
  return totalMinutes;
};

export const addMinutesToTime = (
  time: string,
  minutesToAdd: number
): string => {
  const [hours, minutes, seconds] = time.split(":").map(Number);

  let totalMinutes = hours * 60 + minutes + seconds / 60;
  totalMinutes += minutesToAdd;

  const newHours = Math.floor(totalMinutes / 60);
  const newMinutes = Math.floor(totalMinutes % 60);
  const newSeconds = Math.floor((totalMinutes * 60) % 60);

  return `${String(newHours).padStart(2, "0")}:${String(newMinutes).padStart(
    2,
    "0"
  )}:${String(newSeconds).padStart(2, "0")}`;
};

export const convertMinutesToTimeString = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const paddedHours = hours.toString().padStart(2, "0");
  const paddedMinutes = remainingMinutes.toString().padStart(2, "0");

  return `${paddedHours}:${paddedMinutes}:00`;
};

export const getProgramColor = (type: ProgramType) => {
  switch (type) {
    case "learn":
      return "purple-light";
    case "tryout":
      return "warning-light";
    default:
      return "blue-light";
  }
};

export const validateLowercase = (text: string) => {
  const lowerCaseLetters = /[a-z]/g;

  return lowerCaseLetters.test(text);
};

export const validateUppercase = (text: string) => {
  const upperCaseLetters = /[A-Z]/g;

  return upperCaseLetters.test(text);
};

export const validateSpecialChar = (text: string) => {
  const specialChar = /\W/g;

  return specialChar.test(text);
};

export const validateNumber = (text: string) => {
  const numbers = /[0-9]/g;

  return numbers.test(text);
};

export const validateLength = (text: string, length: number) => {
  return text.length >= length;
};

export const validateEmail = (email: string) => {
  const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
  return !email || regexEmail.test(String(email).toLowerCase());
};

export * from "./useListCache";
export * from "./useText";
export { default as useDebounce } from "./useDebounce";

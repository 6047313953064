import { Button } from 'components';
import {FC, memo} from 'react';
import {  useFormContext } from 'react-hook-form';
import { FormSyllabus } from 'views/Silabus/types';
import ExamList from './ExamList';
import { ExamKind } from 'views/Silabus/Tambah';

type Props = {
  onCancel: () => void
  index: ExamKind
  onSubmit: () => void
}


const Exam: FC<Props> = ({onCancel, index, onSubmit}) => {
  const { watch} = useFormContext<FormSyllabus>();
  const directories = watch(`exams.${index}.directories`);
  const handleSubmit = () => {
    onSubmit();
  };

  const handleCancel = () => {
    onCancel();
  };

  const isDisabled = () => {
    let checkDir = false;
    if (directories?.length) {
      checkDir = directories.every((dir) => {
        if (!dir?.questions?.length) {
          return false;
        }
        return true;
      });
    }
    return !checkDir;
  };

  const stickyFooterClass = 'flex fixed py-5 px-10 justify-end gap-x-3 right-0 bottom-0 mt-5 bg-white w-full'
  const jenisUjian:any = {
    uh: 'Ulangan Harian',
    pts1: "Penilaian Tengah Semester I",
    pts2: "Penilaian Tengah Semester II",
    pas: "Penilaian Akhir Semester",
    pts: "Penilaian Tengah Semester"
  }
  return (
    <>
      <div className="mb-5 flex items-center gap-x-10">
        <p className="font-bold text-xl">Tambah Soal Ujian - {jenisUjian[index]}</p>
      </div>
        <ExamList packageIndex={index} />
      <div className={stickyFooterClass}>
        <Button.Cancel onButtonClick={handleCancel} />
        <Button
          color='primary'
          isDisabled={isDisabled()}
          onButtonClick={handleSubmit}
        >
          Simpan Ujian
        </Button>
      </div>
    </>
  );
};

export default memo(Exam);

import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, memo, useEffect, useState } from "react";
import Edit from "../Modal/Edit";

type Props = {
  data: SchoolStaffData | null;
};

const Information: FC<Props> = ({ data }) => {
  const [show, setShow] = useState(false);
  const [detail, setDetail] = useState(data);

  useEffect(() => {
    setDetail(data);
  }, [data]);

  const toggleEdit = () => setShow(!show);

  const handleEdit = (value: SchoolStaffData) => {
    setDetail({
      ...detail,
      ...value,
    });
    toggleEdit();
  };

  return (
    <>
      <div className="w-[45%] bg-white rounded-xl">
        <div className="px-5 py-4 border-b flex items-center justify-between">
          <p className="font-bold">Informasi Staff Sekolah</p>
          <FontAwesomeIcon
            icon={icon({ name: "pen-to-square", style: "regular" })}
            className="text-yellow-500 cursor-pointer"
            onClick={toggleEdit}
          />
        </div>
        <div className="p-6 space-y-4">
          <div className="flex items-center gap-x-2">
            <div className="w-16 h-16 rounded-full bg-gray-100 grid place-items-center">
              {detail?.avatar_path ? (
                <img
                  className="w-16 h-16 object-cover rounded-full bg-yellow-300"
                  src={detail.avatar_path ?? "/assets/student.png"}
                  alt={`thumbnail-${detail.name}`}
                  loading="lazy"
                />
              ) : (
                <FontAwesomeIcon
                  icon={icon({ name: "user", style: "regular" })}
                  size="xl"
                />
              )}
            </div>
            <div>
              <p className="font-bold">{detail?.name}</p>
            </div>
          </div>
          <p className="font-medium text-sm text-gray-500 mt-4">NPSN</p>
          <p className="font-medium text-sm">{detail?.school_nis ?? "-"}</p>
          <p className="font-medium text-sm text-gray-500 mt-4">Sekolah</p>
          <p className="font-medium text-sm">{detail?.school_name ?? "-"}</p>
          <p className="font-medium text-sm text-gray-500 mt-4">Email</p>
          <p className="font-medium text-sm">{detail?.email}</p>
          <p className="font-medium text-sm text-gray-500 mt-4">
            No. handphone
          </p>
          <p className="font-medium text-sm">
            {detail?.phone_number ? `+62${detail?.phone_number}` : "-"}
          </p>
        </div>
      </div>
      {detail && (
        <Edit
          show={show}
          onClose={toggleEdit}
          onSuccess={handleEdit}
          data={detail}
        />
      )}
    </>
  );
};

export default memo(Information);

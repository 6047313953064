// React
import { useId } from "react";

// Components
import { Label } from "components/texts";

// Cleave.js
import Cleave from "cleave.js/react";
import { CleaveOptions } from "cleave.js/options";
import { ChangeEventHandler } from "cleave.js/react/props";
import "cleave.js/dist/addons/cleave-phone.id";

type Type = {
  errors?: string | undefined;
  label: string;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  options: CleaveOptions;
  placeholder?: string | undefined;
  value?: string | number | readonly string[] | undefined;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  min?: number;
};

export function InputNumber(props: Type): JSX.Element {
  // Hooks
  const id = useId();

  return (
    <div className="w-full">
      {Boolean(props.label) && (
        <Label id={id} required={props.required}>
          {props.label}
        </Label>
      )}
      <Cleave
        id={id}
        className="bg-transparent outline-none border-b border-[#9DA4B3] w-full placeholder:text-[#9DA4B3] mt-3 pb-2"
        {...props}
      />
      {props.errors && (
        <small className="text-error capitalize font-bold">
          {props.errors}
        </small>
      )}
    </div>
  );
}

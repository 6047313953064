import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components";
import { memo, useState, useEffect } from "react";
import BrowseQuestion from "./fragment/Modal/BrowseQuestion";
import { useFormContext } from "react-hook-form";
import useProgram from "services/useProgram";
import { DatePicker } from "components/datepicker";
import MeetingsComposition from "./fragment/MeetingsComposition";
import BrowseDirectory from "./fragment/Modal/Directory";
import { TimePicker } from "components/timepicker";
import toast from "react-hot-toast";
import MeetingModuleList from "./fragment/ListModule";
import { calculateTotalMinutes } from "utilities";
import MeetingQuestionList from "./fragment/ListQuestion";
import { Test } from "views/Silabus/types";

const Meetings = ({ triggerAddMeeting }: any) => {
  const { addLiveClassMeet, updateLiveClassMeet, deleteLiveClassMeet } =
    useProgram();
  const [showQuestion, setShowQuestion] = useState(false);
  const [showModule, setShowModule] = useState(false);
  const [Meetings, setMeetings] = useState<any[]>([]);

  const { setValue, watch, getValues } = useFormContext<LiveclassPayload>();
  const program_id = watch("program_id");
  const program_liveclass_id = watch("program_liveclass_id");

  useEffect(() => {
    const formData = getValues();
    const updatedMeetings = formData.meetings;
    setMeetings(updatedMeetings);
  }, [getValues]);

  useEffect(() => {
    if (triggerAddMeeting) {
      if (Meetings.length > 0) {
        if (program_id !== null && program_liveclass_id !== null) {
          toast.promise(syncUnsavedMeeting(), {
            loading: "Loading...",
            success: (response: any) => {
              return "";
            },
            error: "Terjadi kesalahan",
          });
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerAddMeeting]);

  const [indexEdit, setIndexEdit] = useState<number | null>(null);
  const toggleQuestion = () => setShowQuestion(!showQuestion);
  const toggleModule = () => setShowModule(!showModule);

  const saveNewMeeting = async (
    program_id: string,
    program_liveclass_id: string,
    meeting: any,
    index: number
  ) => {
    try {
      const saveMeet = await addLiveClassMeet(
        parseInt(program_id, 10),
        parseInt(program_liveclass_id, 10),
        meeting
      );
      if (saveMeet) {
        setMeetingId(index, saveMeet.id);
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  const syncUnsavedMeeting = async () => {
    const processingMeet = Meetings || null;
    if (
      processingMeet.length > 0 &&
      program_id !== null &&
      program_liveclass_id !== null
    ) {
      let addMeeting = true;
      await processingMeet.forEach(async (meeting, index) => {
        if (meeting.id === "0") {
          const saveMeet = await saveNewMeeting(
            program_id,
            program_liveclass_id,
            meeting,
            index
          );
          if (!saveMeet) {
            addMeeting = false;
            return "Terjadi kesalahan";
          }
        }
      });
      return addMeeting;
    }
    return false;
  };
  const addNewMeeting = async (data: any) => {
    const newMeeting = {
      id: "0",
      materi: [],
      clock: "",
      question: [],
      module_ids: [],
      date: "",
      date_start: "2023-05-01 00:00:00",
      date_end: "2023-05-01 00:00:00",
      durasi: {
        jam: "",
        menit: "",
        total: "",
      },
      duration: 0,
      question_package: [],
      onEdit: true,
    };

    let addMeeting = true;

    if (
      data.length > 0 &&
      program_id !== null &&
      program_liveclass_id !== null
    ) {
      try {
        addMeeting = await syncUnsavedMeeting();
        toast.promise(syncUnsavedMeeting(), {
          loading: "Loading...",
          success: "Pertemuan yang dibuat berhasil disimpan",
          error: "Terjadi kesalahan",
        });
      } catch (error) {
        addMeeting = false;
      }
    }
    const readyMeeting = Meetings || [];
    if (addMeeting) {
      setMeetings([...readyMeeting, newMeeting]);
      setIndexEdit(readyMeeting.length);
    }
  };

  const setMeetingId = (index: number, meetingId: number) => {
    if (Meetings) {
      const updatedMeetings = [...Meetings];
      updatedMeetings[index].id = meetingId;
      setMeetings(updatedMeetings);
    }
  };

  const handleMeetingsChange = (index: number, fieldName: any, value: any) => {
    let updatedMeetings = [...Meetings];

    if (fieldName === "materi") {
      updatedMeetings[index] = {
        ...updatedMeetings[index],
        [fieldName]: [value],
      };
      const moduleIdsArray = value.map((id: any) => id.id);

      updatedMeetings[index] = {
        ...updatedMeetings[index],
        module_ids: moduleIdsArray,
      };
      setMeetings(updatedMeetings);
      setValue("meetings", updatedMeetings);
    } else if (fieldName === "question") {
      value.forEach((val: any) => {
        const currentQuestion = updatedMeetings[index].question;
        if(currentQuestion.filter((q: Test) => q.question_package_id === val.id ).length > 0 || !val.id || val.id === undefined) return
        const question: Test = {
          name: val.name,
          type: "exam",
          grade_name: val.grade_name,
          n_essay: val.n_essay,
          n_multiple_choice: val.n_multiple_choice,
          question_package_id: val.id,
          difficulty: val.difficulty,
          test_count: "all",
        };
        const questionPackage = {
          question_package_id: val.id,
          n_essay: String(val.n_essay),
          n_multiple_choice: String(val.n_multiple_choice),
        };

        updatedMeetings[index] = {
          ...updatedMeetings[index],
          question_package: [
            ...updatedMeetings[index].question_package,
            questionPackage,
          ],
          question: [...updatedMeetings[index].question, question],
        };
      });
      setMeetings(updatedMeetings);
      setValue("meetings", updatedMeetings);
    } else if (
      fieldName === "jam" ||
      fieldName === "menit" ||
      fieldName === "total"
    ) {
      let updatedDurasi = { ...updatedMeetings[index].durasi };
      let hours = updatedDurasi.jam;
      let minutes = updatedDurasi.menit;

      if (fieldName === "jam") {
        hours = value;
      } else if (fieldName === "menit") {
        minutes = value;
      }

      const totalMinutes = calculateTotalMinutes(hours, minutes);

      updatedMeetings[index] = {
        ...updatedMeetings[index],
        durasi: {
          ...updatedMeetings[index].durasi,
          [fieldName]: value,
          total: totalMinutes.toString(),
        },
        duration: totalMinutes,
      };
      setMeetings(updatedMeetings);
      setValue("meetings", updatedMeetings);
    } else if (fieldName === "module_ids") {
      const moduleIdsArray = value.map((id: any) => id.id);

      updatedMeetings[index] = {
        ...updatedMeetings[index],
        [fieldName]: moduleIdsArray,
      };
      setMeetings(updatedMeetings);
      setValue("meetings", updatedMeetings);
    } else if (fieldName === "question_package") {
      const moduleIdsArray = {
        question_package_id: value.id,
        n_essay: String(value.n_essay),
        n_multiple_choice: String(value.n_multiple_choice),
      };

      updatedMeetings[index] = {
        ...updatedMeetings[index],
        [fieldName]: [moduleIdsArray],
      };
      setMeetings(updatedMeetings);
      setValue("meetings", updatedMeetings);
    } else if (fieldName === "clock" || fieldName === "date") {
      updatedMeetings[index] = {
        ...updatedMeetings[index],
        [fieldName]: value,
      };
      const { date, clock } = updatedMeetings[index];
      if (date && clock) {
        updatedMeetings[index] = {
          ...updatedMeetings[index],
          date_start: `${date} ${clock}`,
          date_end: `${date} ${clock}`,
        };
      }
      setMeetings(updatedMeetings);
      setValue("meetings", updatedMeetings);
    } else {
      updatedMeetings = updatedMeetings.map((meeting, idx) => {
        if (index === idx) {
          return {
            ...meeting,
            [fieldName]: value,
          };
        }
        return meeting;
      });
      setMeetings(updatedMeetings);
      setValue("meetings", updatedMeetings);
    }
  };

  const handleRemove = async (indexToRemove: number) => {
    alert("Apakah anda yakin ingin menghapus pertemuan ini?");
    if (
      Meetings[indexToRemove].id !== undefined &&
      Meetings[indexToRemove].id !== 0 &&
      program_liveclass_id !== null
    ) {
      await deleteLiveClassMeet(
        Number(program_liveclass_id),
        Meetings[indexToRemove].id
      );
    }
    setMeetings((prevMeetings) => {
      return prevMeetings.filter((_, index) => index !== indexToRemove);
    });
  };

  const handleRemoveModule = (meetingIndex: number, moduleIndex: number) => {
    setMeetings((prevMeetings) => {
      const updatedMeetings = [...prevMeetings];
      const updatedMateri = [...updatedMeetings[meetingIndex].materi[0]];

      updatedMateri.splice(moduleIndex, 1);
      updatedMeetings[meetingIndex].materi[0] = updatedMateri;

      return updatedMeetings;
    });
  };

  const handleRemoveQuestion = (
    meetingIndex: number,
    questionIndex: number
  ) => {
    setMeetings((prevMeetings) => {
      const updatedMeetings = [...prevMeetings];
      const updatedQuestion = [...updatedMeetings[meetingIndex].question];
      const updatedQuestionPackage = [
        ...updatedMeetings[meetingIndex].question_package,
      ];

      updatedQuestion.splice(questionIndex, 1);
      updatedQuestionPackage.splice(questionIndex, 1);

      updatedMeetings[meetingIndex] = {
        ...updatedMeetings[meetingIndex],
        question: updatedQuestion,
        question_package: updatedQuestionPackage,
      };

      return updatedMeetings;
    });
  };

  const handleSaveMeetingItem = async (meetingIndex: number) => {
    const meetingUpdate = { ...Meetings[meetingIndex] };
    if (
      meetingUpdate.id === "0" &&
      program_id !== null &&
      program_liveclass_id !== null
    ) {
      await saveNewMeeting(
        program_id,
        program_liveclass_id,
        meetingUpdate,
        meetingIndex
      );
    } else {
      await updateLiveClassMeet(
        Number(program_id),
        meetingUpdate.id,
        meetingUpdate
      );
    }
  };

  const convertMapQuestionToBrowseItem = (mapQuestion: any[]) => {
    return mapQuestion.map((question) => ({
      id: question.question_package_id,
      name: question.question_package_name,
      type: "question" as "directory" | "module" | "question",
      module_id: 0,
      grade_name: '',
      n_essay: question.n_essay,
      n_multiple_choice: question.n_multiple_choice,
      difficulty: question.difficulty,
      duration: 0,
      created_at: '',
      updated_at: '',
      path: '',
      subject_id: 0,
      subject_name: '',
      grade_id: '',
      question_package_id: question.question_package_id
    }))
  }

  return (
    <>
      <section className="grid gap-y-5">
        {Meetings.length ? (
          Meetings.map((Meetings, index) => {
            return index === indexEdit ? (
              <div className="bg-white rounded-xl h-fit ">
                <div className="flex px-5 py-3 items-center relative justify-between flex-wrap border-b">
                  <p className="font-bold">Pertemuan {index + 1}</p>
                  <button
                    type="button"
                    title="Delete"
                    onClick={() => handleRemove(index)}
                    className="w-[40px] aspect-square bg-[#F04438] rounded-xl flex justify-center items-center ml-3"
                  >
                    <Button.Icon action="delete" />
                  </button>
                </div>
                <div className="grid grid-flow-col gap-x-5 px-5 py-4">
                  <div>
                    <p>Materi</p>
                    <label
                      htmlFor={`materi-${index}`}
                      className={
                        "items-center cursor-pointer flex flex-1 rounded-lg bg-white flex-row divide-x divide-[#D0D5DD] border border-[#D0D5DD]"
                      }
                    >
                      <label htmlFor="materi" className={"ml-4 text-gray-500"}>
                        Pilih materi
                      </label>
                      <input
                        id={`materi-${index}`}
                        className="invisible flex rounded-lg flex-1"
                        onClick={toggleModule}
                      />
                      <span className="p-2">Browse</span>
                    </label>
                    <MeetingModuleList
                      modules={Meetings.materi}
                      onDelete={(moduleIndex) =>
                        handleRemoveModule(index, moduleIndex)
                      }
                    />
                  </div>
                  <div>
                    <p>Latihan soal</p>
                    <label
                      htmlFor={`latihan-question-${index}`}
                      className={
                        "items-center cursor-pointer flex flex-1 rounded-lg bg-white flex-row divide-x divide-[#D0D5DD] border border-[#D0D5DD]"
                      }
                    >
                      <label
                        htmlFor="Latihan question"
                        className={"ml-4 text-gray-500"}
                      >
                        Pilih Latihan soal
                      </label>
                      <input
                        id={`latihan-question-${index}`}
                        className="invisible flex rounded-lg flex-1"
                        onClick={toggleQuestion}
                      />
                      <span className="p-2">Browse</span>
                    </label>
                    <MeetingQuestionList
                      modules={Meetings.question}
                      onDelete={(questionIndex) =>
                        handleRemoveQuestion(index, questionIndex)
                      }
                    />
                  </div>
                </div>
                <div className="px-5 py-4">
                  <div className="flex gap-x-5">
                    <DatePicker
                      placeholder="Pilih jadwal"
                      label="date"
                      required
                      value={Meetings.date[index]}
                      onChange={(val) => {
                        handleMeetingsChange(index, "date", val);
                      }}
                    />
                    <TimePicker
                      label="Jam Pertemuan"
                      value=""
                      onChange={(val) => {
                        handleMeetingsChange(index, "clock", val);
                      }}
                    />
                    <div>
                      <p
                        className={`font-medium text-sm text-gray-700 mb-1.5 after:text-red-500`}
                      >
                        Durasi
                      </p>
                      <div className="flex flex-row">
                        <label
                          htmlFor={"jam"}
                          className={
                            "items-center cursor-pointer flex flex-1 rounded-lg bg-white flex-row divide-x divide-[#D0D5DD] border border-[#D0D5DD] mt-0.5"
                          }
                        >
                          <span className="p-2.5">Jam</span>
                          <input
                            placeholder="1"
                            id={"jam"}
                            className="pl-4 w-20"
                            onChange={(e) => {
                              handleMeetingsChange(
                                index,
                                "jam",
                                e.target.value
                              );
                            }}
                          />
                        </label>
                        <label
                          htmlFor={"menit"}
                          className={
                            "items-center cursor-pointer flex flex-1 rounded-lg bg-white flex-row divide-x divide-[#D0D5DD] border border-[#D0D5DD] mt-0.5 ml-2"
                          }
                        >
                          <span className="p-2.5">Menit</span>
                          <input
                            placeholder="30"
                            id={"menit"}
                            className="pl-4 w-20"
                            onChange={(e) => {
                              handleMeetingsChange(
                                index,
                                "menit",
                                e.target.value
                              );
                            }}
                          />
                        </label>

                        <Button
                          color="outline-gray"
                          className="items-center cursor-pointer h-11 flex flex-1 rounded-lg bg-white flex-row divide-x divide-[#D0D5DD] border border-[#D0D5DD] mt-0.5 ml-2 bg-yellow-200 hover:text-gray-800"
                          onButtonClick={() => {
                            handleSaveMeetingItem(index);
                            setIndexEdit(null);
                          }}
                        >
                          <span className="ml-1.5">Simpan</span>
                        </Button>
                      </div>
                    </div>
                    <div>
                      <p></p>
                    </div>
                  </div>
                </div>
                <BrowseDirectory
                  show={showModule}
                  onClose={toggleModule}
                  onSubmit={(val) => {
                    handleMeetingsChange(index, "materi", val);
                  }}
                  selectedQuestions={Meetings.materi[index]}
                />
                <BrowseQuestion
                  show={showQuestion}
                  onClose={toggleQuestion}
                  onSubmit={(val) => {
                    handleMeetingsChange(index, "question", val);
                    toggleQuestion();
                  }}
                  selectedQuestion={convertMapQuestionToBrowseItem(Meetings.question)}
                  title={""}
                  directories={[]}
                />
              </div>
            ) : (
              <div className="bg-white rounded-xl h-fit ">
                <div className="flex px-5 py-3 items-center relative justify-between flex-wrap border-b">
                  <p className="font-bold">Pertemuan {index + 1}</p>
                  <button
                    type="button"
                    title="Delete"
                    onClick={() => handleRemove(index)}
                    className="w-[40px] aspect-square bg-[#F04438] rounded-xl flex justify-center items-center ml-3"
                  >
                    <Button.Icon action="delete" />
                  </button>
                </div>
                <div className="flex flex-1 space-x-4 my-3 px-7 py-4 rounded-md">
                  <span className="self-center rounded-full border-gray-200 border text-gray-700 bg-gray-100 mr-4 w-10 h-10 text-center align-middle items-center leading-10">
                    {index + 1}
                  </span>

                  <div className="flex flex-1 justify-between">
                    <div className="flex flex-col space-y-2 flex-1">
                      <span className="font-medium text-xs text-gray-500">
                        Materi
                      </span>
                      {Meetings.materi.length > 0 &&
                        Meetings.materi[0].map((module: any) => (
                          <div key={module.module_id}>
                            <FontAwesomeIcon
                              size="1x"
                              color="#666666"
                              icon={icon({ name: "file-alt" })}
                            />
                            <span className="font-light text-base text-slate-800 ml-2">
                              {module.name}
                            </span>
                          </div>
                        ))}
                    </div>

                    <div className="flex flex-col space-y-2 flex-1">
                      <span className="font-medium text-xs text-gray-500">
                        Soal
                      </span>
                      {Meetings.question.length > 0 &&
                        Meetings.question.map((question: any) => (
                          <div key={question.id}>
                            <FontAwesomeIcon
                              size="1x"
                              color="#666666"
                              icon={icon({ name: "file-alt" })}
                            />
                            <span className="font-light text-base text-slate-800 ml-2">
                              {question.name}
                            </span>
                          </div>
                        ))}
                    </div>

                    <div className="flex flex-col flex-start mr-8">
                      <span className="font-medium text-xs text-gray-500">
                        Durasi
                      </span>
                      <span className="font-light text-base text-slate-800">
                        {Meetings.hours} jam {Meetings.minutes} menit
                      </span>
                    </div>

                    <div className="flex flex-col mr-8">
                      <span className="font-medium text-xs text-gray-500">
                        Jadwal
                      </span>
                      <span className="font-light text-base text-slate-800">
                        {Meetings.date_start}
                      </span>
                    </div>

                    <FontAwesomeIcon
                      onClick={() => setIndexEdit(index)}
                      className="cursor-pointer"
                      color="#B5A300"
                      icon={icon({ name: "edit" })}
                    />
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p className="my-6 px-5 text-gray-400 text-left">
            Anda belum menambahkan pertemuan apapun.
          </p>
        )}
        <div className={`flex p-5 justify-between gap-x-12`}>
          <MeetingsComposition meetings={Meetings} />
          <Button
            color="outline-gray"
            className="bg-yellow-100 hover:bg-yellow-200 hover:text-gray-800"
            onButtonClick={() => {
              addNewMeeting(Meetings);
            }}
          >
            <FontAwesomeIcon icon={icon({ name: "user-plus" })} />
            <span className="ml-1.5">Tambah Pertemuan</span>
          </Button>
        </div>
      </section>
    </>
  );
};

export default memo(Meetings);

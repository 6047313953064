import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputNumber } from "components";
import Table from "components/Table";
import { QUESTION_DIFFICULTY } from "constant";
import { FC, memo } from "react";

type Props = {
  isEdit?: boolean;
  data: TryoutPayload["question_package"];
  removeQuestion?: (item: TryoutPayload["question_package"][0]) => void;
  onChange?: (value: number, index: number, view_priority?: number) => void;
};

const columns = [
  {
    title: "Soal",
    isSort: true,
    key: "name",
  },
  {
    title: "Mapel",
    isSort: true,
    key: "grade",
  },
  {
    title: "Pilihan ganda",
    isSort: true,
  },
  {
    title: "Essay",
    isSort: true,
    key: "updated_at",
  },
  {
    title: "Tingkatan",
    isSort: true,
  },
  {
    title: "Jumlah soal",
    isLeft: true,
  },
  {
    title: "Urutan",
    isLeft: true,
  },
  {
    title: "",
  },
];

const ListQuestion: FC<Props> = (props) => {
  const { isEdit = false, data, removeQuestion, onChange } = props;

  if (!data.length)
    return (
      <p className="my-6 px-5 text-gray-400 text-left">
        Anda belum menambahkan soal apapun.
      </p>
    );

  return (
    <Table
      data={data}
      columns={isEdit ? columns : [...columns.slice(0, 7)]}
      showFooter={false}
    >
      {data.map((val, index) => (
        <tr key={val.question_package_id || val.id} className="border-b">
          <td className="px-4 py-2.5">
            <div>
              <div className="flex items-center gap-x-2">
                <FontAwesomeIcon icon={icon({ name: "file-alt" })} width={14} />
                <p>{val.name}</p>
              </div>
              <div className="flex items-center gap-x-2">
                <FontAwesomeIcon icon={icon({ name: "house" })} width={14} />
                <p className="text-xs text-gray-500">{val?.path || "-"}</p>
              </div>
            </div>
          </td>
          <td className="px-4 py-2.5">
            <p className="text-sm text-gray-600">{val.subject_name || "-"}</p>
          </td>
          <td className="px-4 py-2.5">
            <p>{val.n_multiple_choice || "-"}</p>
          </td>
          <td className="px-4 py-2.5">
            <p>{val.n_essay || "-"}</p>
          </td>
          <td className="px-4 py-2.5">
            <p>{QUESTION_DIFFICULTY[val.difficulty]}</p>
          </td>
          <td className="px-4 py-2.5">
            {isEdit ? (
              <InputNumber
                label=""
                options={{
                  numeral: true,
                  numeralDecimalMark: ",",
                  delimiter: ".",
                }}
                className="border border-gray-300 px-3 py-2 rounded-lg w-28"
                value={val.n_question_value}
                onChange={(e) => {
                  const value = Number(e.target.rawValue);
                  const maxValue = val.n_essay + val.n_multiple_choice;
                  const validValue = value > maxValue ? maxValue : value;
                  onChange?.(validValue, index);
                }}
              />
            ) : (
              <span>{val.n_question_value}</span>
            )}
          </td>
          <td className="px-4 py-2.5">
            {isEdit ? (
              <InputNumber
                label=""
                options={{
                  numeral: true,
                  numeralDecimalMark: ",",
                  delimiter: ".",
                }}
                min={0}
                className="border border-gray-300 px-3 py-2 rounded-lg w-28"
                value={val.view_priority}
                onChange={(e) => {
                  onChange?.(0, index,e.target.rawValue && Number(e.target.rawValue) > 0 ? Number(e.target.rawValue) : 0);
                }}
              />
            ) : (
              <span>{val.view_priority ?? "-"}</span>
            )}
          </td>
          {isEdit && (
            <td className="px-4 py-2.5">
              <FontAwesomeIcon
                icon={icon({ name: "trash-alt" })}
                className="cursor-pointer"
                onClick={() => removeQuestion?.(val)}
              />
            </td>
          )}
        </tr>
      ))}
    </Table>
  );
};

export default memo(ListQuestion);

import * as yup from "yup";
import { testValidation } from "./testValidation";
import { Module, Test } from "../types";

// Validation schema
export const validationSchema = yup.object().shape({
  grade_id: yup.number().label("Tingkatan").nullable().required(),
  subject_id: yup.number().nullable().defined(),
  major_id: yup.number().nullable().defined(),
  name: yup.string().label("Judul").required(),
  description: yup.string().label("Deskripsi").required(),
  packages: yup
    .array()
    .label("Pertemuan")
    .of(
      yup.object().shape({
        content_type: yup.string().required(),
        title: yup.string().label("Judul Pertemuan").required(),
        description: yup.string().label("Deskripsi").required(),
        directories: yup
          .array()
          .label("Bab")
          .of(
            yup.object().shape(
              {
                directoryId: yup.number().required(),
                crumbs: yup.array().of(yup.string()).min(1).required(),
                modules: yup
                  .array()
                  .label("Modul")
                  .of(
                    yup.object().shape({
                      module_id: yup.number().required(),
                      name: yup.string().required(),
                    })
                  )
                  .when("questions", {
                    is: (val: Test[]) => val?.length === 0,
                    then: (schema) => schema.min(1),
                    otherwise: (schema) => schema,
                  })
                  .required(),
                questions: yup
                  .array()
                  .label("Soal")
                  .of(testValidation)
                  .when("modules", {
                    is: (val: Module[]) => val?.length === 0,
                    then: (schema) => schema.min(1),
                    otherwise: (schema) => schema,
                  })
                  .required(),
              },
              [["modules", "questions"]]
            )
          )
          .min(1)
          .required(),
      })
    )
    .min(1)
    .required(),
  exams: yup.object().shape({
    uh: yup
      .object()
      .shape({
        directories: yup
          .array()
          .of(
            yup.object().shape({
              directoryId: yup.number().required(),
              questions: yup.array().of(
                yup.object().shape({
                  type: yup.string().required(),
                  grade_name: yup.string().required(),
                  n_essay: yup.number().required(),
                  n_multiple_choice: yup.number().required(),
                  question_package_id: yup.number().required(),
                  test_count: yup.mixed().oneOf(["all", "part"]).required(),
                  difficulty: yup.number().nullable().defined(),
                })
              ),
            })
          )
          .min(0),
      })
      .required(),
    pat: yup
      .object()
      .shape({
        directories: yup
          .array()
          .of(
            yup.object().shape({
              directoryId: yup.number().required(),
              questions: yup.array().of(
                yup.object().shape({
                  type: yup.string().required(),
                  grade_name: yup.string().required(),
                  n_essay: yup.number().required(),
                  n_multiple_choice: yup.number().required(),
                  question_package_id: yup.number().required(),
                  test_count: yup.mixed().oneOf(["all", "part"]).required(),
                  difficulty: yup.number().nullable().defined(),
                })
              ),
            })
          )
          .min(0),
      })
      .required(),
    pas: yup
      .object()
      .shape({
        directories: yup
          .array()
          .of(
            yup.object().shape({
              directoryId: yup.number().required(),
              questions: yup.array().of(
                yup.object().shape({
                  type: yup.string().required(),
                  grade_name: yup.string().required(),
                  n_essay: yup.number().required(),
                  n_multiple_choice: yup.number().required(),
                  question_package_id: yup.number().required(),
                  test_count: yup.mixed().oneOf(["all", "part"]).required(),
                  difficulty: yup.number().nullable().defined(),
                })
              ),
            })
          )
          .min(0),
      })
      .required(),
    pts1: yup
      .object()
      .shape({
        directories: yup
          .array()
          .of(
            yup.object().shape({
              directoryId: yup.number().required(),
              questions: yup.array().of(
                yup.object().shape({
                  type: yup.string().required(),
                  grade_name: yup.string().required(),
                  n_essay: yup.number().required(),
                  n_multiple_choice: yup.number().required(),
                  question_package_id: yup.number().required(),
                  test_count: yup.mixed().oneOf(["all", "part"]).required(),
                  difficulty: yup.number().nullable().defined(),
                })
              ),
            })
          )
          .min(0),
      })
      .required(),
    pts2: yup
      .object()
      .shape({
        directories: yup
          .array()
          .of(
            yup.object().shape({
              directoryId: yup.number().required(),
              questions: yup.array().of(
                yup.object().shape({
                  type: yup.string().required(),
                  grade_name: yup.string().required(),
                  n_essay: yup.number().required(),
                  n_multiple_choice: yup.number().required(),
                  question_package_id: yup.number().required(),
                  test_count: yup.mixed().oneOf(["all", "part"]).required(),
                  difficulty: yup.number().nullable().defined(),
                })
              ),
            })
          )
          .min(0),
      })
      .required(),
  }),
});

import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox } from "@headlessui/react";
import {
  FC,
  memo,
  useState,
  useEffect,
  Fragment,
} from "react";
import { useDebounce } from "utilities";

type Props = {
  onGetData: (params: TransactionParams) => Promise<void>;
  params: TransactionParams;
};

type SORT = TransactionParams & {
  id: number;
  name: string;
};

const TRANSACTION_STATUS_LIST: SORT[] = [
  {
    id: 0,
    name: "Semua",
    transaction_status: 'all',

  },
  {
    id: 1,
    name: "Berhasil",
    transaction_status: 'berhasil',
  },
  {
    id: 3,
    name: "Pending",
    transaction_status: 'pending',
  },
  {
    id: 2,
    name: "Kadaluarsa",
    transaction_status: 'expired',
  },
  {
    id: 5,
    name: "Gagal",
    transaction_status: 'failed',
  },
];

const TRANSACTION_TYPE_LIST: SORT[] = [
  {
    id: 0,
    name: "Semua",
    purchase_type: 'all',

  },
  {
    id: 1,
    name: "E-Learning",
    purchase_type: 'learn',
  },
  {
    id: 4,
    name: "Live Class",
    purchase_type: 'class',
  },
  {
    id: 3,
    name: "Tryout",
    purchase_type: 'tryout',
  },
  {
    id: 4,
    name: "Tanya PR",
    purchase_type: 'qna',
  },
  {
    id: 5,
    name: "Wallet",
    purchase_type: 'wallet',
  },
];

const Filter: FC<Props> = ({ onGetData, params}) => {
  const [search, setSearch] = useState("");
  const [filterStatus, setFilterStatus] = useState(TRANSACTION_STATUS_LIST[0]);
  const [filterType, setFilterType] = useState(TRANSACTION_TYPE_LIST[0]);
  const debounceSearch = useDebounce(search);
  const [currentParams, setCurrentParams] = useState<TransactionParams>({
    ...params
  })

  const clearSearch =async () => {
    setSearch('');
  };

  const handleFilterStatus = async (value: SORT) => {
      setFilterStatus(value);
      const newParams = {
        ...currentParams,
        transaction_status: value.transaction_status,
      }
      await onGetData(newParams);
      setCurrentParams(newParams);
  };

  const handlefilterType = async (value: SORT) => {
      setFilterType(value);
      const newParams = {
        ...currentParams,
        purchase_type: value.purchase_type,
      }
      await onGetData(newParams);
      setCurrentParams(newParams);
  };

  useEffect(() => {
    if (debounceSearch.length >= 3) {
      const newParams = {
        ...currentParams,
        keyword: debounceSearch
      }
      void onGetData(newParams);
      setCurrentParams(newParams);
    } else {
      const newParams = {
        ...currentParams,
        keyword: '',
      }
      void onGetData(newParams);
      setCurrentParams(newParams);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearch]);

  return (
    <div>
      <div className=" flex px-5 pt-4 gap-5">
        <div className='flex-grow bg-white px-4 py-[0.625rem] rounded-xl flex gap-x-2 items-center border border-gray-300 shadow-xs text-sm'>
          <FontAwesomeIcon icon={icon({name: 'search'})} />
          <input
            className='w-full'
            placeholder='Cari invoice atau nama paket...'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {Boolean(search.length) && (
            <FontAwesomeIcon
              icon={icon({name: 'close'})}
              onClick={clearSearch}
              className='cursor-pointer'
            />
          )}
        </div>
        <div className="relative flex-1 min-w-[150px] max-w-[200px]">
          <Listbox value={filterStatus} onChange={handleFilterStatus}>
            <Listbox.Button className="relative flex w-full cursor-default items-center justify-between gap-x-1 rounded-lg border bg-white px-3.5 py-2.5 text-left text-sm">
              <span className="block truncate">{filterStatus.name}</span>
              <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
            </Listbox.Button>
            <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {TRANSACTION_STATUS_LIST.map((val, classIdx) => (
                <Listbox.Option
                  key={`${classIdx}-${classIdx}`}
                  as={Fragment}
                  value={val}
                >
                  {({ active }) => (
                    <li
                      className={`relative flex items-center justify-between cursor-default select-none px-3.5 py-2.5 ${
                        active ? "bg-gray-50" : "text-gray-900"
                      }`}
                    >
                      <span className="block truncate">{val.name}</span>
                      {val.id === filterStatus.id && (
                        <FontAwesomeIcon
                          icon={icon({ name: "check" })}
                          color="#7F56D9"
                        />
                      )}
                    </li>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>
        </div>
        <div className="relative flex-1 min-w-[150px] max-w-[200px]">
          <Listbox value={filterType} onChange={handlefilterType}>
            <Listbox.Button className="relative flex w-full cursor-default items-center justify-between gap-x-1 rounded-lg border bg-white px-3.5 py-2.5 text-left text-sm">
              <span className="block truncate">{filterType.name}</span>
              <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
            </Listbox.Button>
            <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {TRANSACTION_TYPE_LIST.map((val, classIdx) => (
                <Listbox.Option
                  key={`${classIdx}-${classIdx}`}
                  as={Fragment}
                  value={val}
                >
                  {({ active }) => (
                    <li
                      className={`relative flex items-center justify-between cursor-default select-none px-3.5 py-2.5 ${
                        active ? "bg-gray-50" : "text-gray-900"
                      }`}
                    >
                      <span className="block truncate">{val.name}</span>
                      {val.id === filterType.id && (
                        <FontAwesomeIcon
                          icon={icon({ name: "check" })}
                          color="#7F56D9"
                        />
                      )}
                    </li>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>
        </div>
      </div>
    </div>
  );
};

export default memo(Filter);

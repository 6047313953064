import { FC, useCallback, useEffect, useState } from "react";
import Modal from "components/Modal";
import useOrder from "services/useOrder";
import { itemStatus } from "pages/Order";

type Props = {
  show: boolean;
  onClose: () => void;
  id: string;
};

const productTypes: any = {
  qna: "Tanya PR",
  learn: "E-Learning",
  class: "Live Class",
  tryout: "Tryout Online",
};

const statusBG: any = {
  pending: "border-yellow-500 text-yellow-500",
  expired: "border-red-500 text-red-500",
  berhasil: "border-green-700 text-green-700",
  failed: "border-red-500 text-red-500",
};

const DetailOrderModal: FC<Props> = ({ show, id, onClose }) => {
  const { getOrderDetail } = useOrder();
  const [data, setData] = useState<TrasactionDetail>();

  const getData = useCallback(async () => {
    const response: TrasactionDetail = await getOrderDetail(id);
    setData(response);
  }, [getOrderDetail, id]);

  useEffect(() => {
    void getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatCurrency = (number: number) => {
    const formatted = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(number);

    return formatted;
  };

  return (
    <Modal
      show={show}
      title={
        !data ? `Loading...` : `ID: ${data.id} - Order No: ${data.details.no}`
      }
      onClose={onClose}
      customTitle={"text-[#8C7E00] font-bold text-sm md:text-base"}
    >
      {data ? (
        <>
          <p className="font-bold py-4 border-b">Detail order</p>
          <div className="flex">
            <div className="w-1/2 py-4">
              <table className="w-fit">
                <tbody>
                  <tr>
                    <td className="py-1 text-gray-500  pr-5 text-[14px]">
                      Nama Pembeli
                    </td>
                    <td className="py-1 text-[14px]">
                      <b>{data.user_name} </b>
                      {`(${data.user_role})`}
                    </td>
                  </tr>

                  <tr>
                    <td className="py-1 text-gray-500  pr-5 text-[14px]">
                      Hp.
                    </td>
                    <td className="py-1 text-[14px]">{data.phone_number}</td>
                  </tr>
                  <tr>
                    <td className="py-1 text-gray-500  pr-5 text-[14px]">
                      Email
                    </td>
                    <td className="py-1 text-[14px]">{data.email}</td>
                  </tr>
                  {data.student_name && (
                    <>
                      <tr>
                        <td className="py-1 text-gray-500  pr-5 text-[14px]">
                          Siswa
                        </td>
                        <td className="py-1 text-[14px]">
                          {data.student_name}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-1 text-gray-500  pr-5 text-[14px]">
                          Strata
                        </td>
                        <td className="py-1 text-[14px]">
                          {data.student_grade}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>

            <div className="w-1/2 py-4">
              <table className="w-fit">
                <tbody>
                  <tr>
                    <td className="py-1 text-gray-500  pr-5 text-[14px]">
                      Metode Pembayaran
                    </td>
                    <td className="py-1 text-[14px]">
                      {data.payment_method_name}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-1 text-gray-500  pr-5 text-[14px]">
                      Tanggal Dibuat
                    </td>
                    <td className="py-1 text-[14px]">{data.created_at}</td>
                  </tr>
                  <tr>
                    <td className="py-1 text-gray-500  pr-5 text-[14px]">
                      Tanggal Terupdate
                    </td>
                    <td className="py-1 text-[14px]">{data.updated_at}</td>
                  </tr>
                  <tr>
                    <td className="py-1 text-gray-500  pr-5 text-[14px]">
                      Status
                    </td>
                    <td className="py-1 text-[14px]">
                      <span
                        className={`${
                          statusBG[data.is_paid ? "berhasil" : data.status]
                        } border px-2 py-[2px] rounded-full`}
                      >
                        {itemStatus[data.is_paid ? "berhasil" : data.status]}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-full bg-[#F2F4F7] rounded-lg p-5">
            <p className="font-bold ">Rincian Penjualan</p>
            <div className="w-full border-b border-dashed py-3">
              <div className="flex justify-between text-[14px] py-1">
                <p className="text-gray-500">Nama Produk</p>
                <p>{data.details.title}</p>
              </div>
              <div className="flex justify-between text-[14px] py-1">
                <p className="text-gray-500">Tipe Produk</p>
                <p>{productTypes[data.details.type]}</p>
              </div>
              <div className="flex justify-between text-[14px] py-1">
                <p className="text-gray-500">Harga paket</p>
                <p>{formatCurrency(data.details.price)}</p>
              </div>
              <div className="flex justify-between text-[14px] py-1">
                <p className="text-gray-500">Diskon </p>
                <p>{formatCurrency(data.details.discount)}</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div></div>
      )}
    </Modal>
  );
};

export default DetailOrderModal;

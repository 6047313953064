import { useCallback, useRef } from "react";
import { services } from "services";
import { API_URL } from "constant";
import { AxiosError } from "axios";

const defaultParams: ProgramParams = {
  page: 1,
  per_page: 10,
  sort_by: "id",
  order_by: "desc",
};

type Response = PaginationResponse & {
  data: ProgramItem[];
};

type VarietyPayload = {
  total_students?: number;
  total_questions: number;
};

const useProgram = () => {
  const lastPayload = useRef(defaultParams);

  const getList = async (params?: ProgramParams): Promise<Response> => {
    try {
      const payload: ProgramParams = {
        ...lastPayload.current,
        ...params,
      };

      lastPayload.current = payload;
      const response = await services.get(API_URL.program, {
        params: payload,
      });

      return response.data?.data;
    } catch (error) {
      return {
        current_page: 0,
        data: [],
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0,
      };
    }
  };

  const getDetail = useCallback(async (id: number) => {
    try {
      const response = await services.get(`${API_URL.program}/${id}`);

      return response.data;
    } catch (error) {
      const err = error as AxiosError;

      throw err.response?.data;
    }
  }, []);

  const deleteProgram = async (id: number) => {
    try {
      await services.delete(`${API_URL.program}/${id}`);
    } catch (error) {
      throw error;
    }
  };

  const addProgram = async (params: ProgramPayload) => {
    try {
      await services.post(API_URL.program, params);

      return true;
    } catch (error) {
      throw error;
    }
  };

  const updateProgram = async (id: number | string, isDraft?: boolean) => {
    try {
      await services.patch(`${API_URL.program}/${id}/publish`, {
        is_draft: Boolean(isDraft),
      });
    } catch (error) {
      throw error;
    }
  };

  const getVariationList = async (
    payload: VarietyPayload
  ): Promise<Variety[]> => {
    try {
      const { total_students, total_questions = 0 } = payload;
      let params: VarietyPayload = {
        total_questions,
      };
      if (total_students) {
        params = {
          ...params,
          total_students,
        };
      }
      const response = await services.get(`${API_URL.program}/tryout/variety`, {
        params,
      });

      return response.data?.data;
    } catch (error) {
      return [];
    }
  };

  const addTryout = async (params: TryoutPayload) => {
    try {
      const { variety, question_package, ...restParams } = params;
      const questionPackage = question_package.map((val) => ({
        n_question_value: val.n_question_value,
        question_package_id: val.question_package_id,
        id: val.id,
        view_priority: val.view_priority
      }));

      const payload = {
        ...restParams,
        question_package: questionPackage
      };
      const response = await services.post(API_URL.tryout, payload);

      return response.data?.data?.program_id;
    } catch (error) {
      throw error;
    }
  };

  const detailTryout = async (id: number): Promise<TryoutPayload> => {
    try {
      const response = await services.get(`${API_URL.tryout}/${id}`);

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const addLiveclass = async (params: LiveclassPayload) => {
    try {
      const { question_package, ...restParams } = params;
      const payload = {
        ...restParams,
        question_package: question_package.map((val) => ({
          id: val.id,
          n_question_value: val.n_question_value,
          question_package_id: val.id,
        })),
      };
      const response = await services.post(API_URL.class, payload);

      return response.data?.data?.program_class;
    } catch (error) {
      throw error;
    }
  }

  const updateLiveclass = async (id: number, params: LiveclassPayload) => {
    try {
      const response = await services.put(`${API_URL.program}/${id}/class`, params);
      return response.data?.data?.program_class?.program_id;
    } catch (error) {
      throw error;
    }
  }

  const addLiveClassMeet = async (id: number, classId:number, params: LiveClassMeetPayload) => {
    try {
      const response = await services.post(`${API_URL.program}/${id}/class/${classId}/meets`, params);

      return response.data?.data;
    } catch (error) {
      throw error;
    }
  };

  const updateLiveClassMeet = async (id: number, meetId:number, params: updateLiveClassMeet) => {
    try {
      const response = await services.put(`${API_URL.program}/${id}/class/meets/${meetId}`, params);

      return response.data?.data;
    } catch (error) {
      throw error;
    }
  }

  const deleteLiveClassMeet = async (id: number, meetId:number) => {
    try {
      const response = await services.delete(`${API_URL.program}/${id}/class/meets/${meetId}`);

      return response.data?.data;
    } catch (error) {
      throw error;
    }
  }

  

  const getProgramStudents = async (
    params?: ProgramParams
  ): Promise<ProgramStudentsResponse> => {
    try {
      const payload: ProgramParams = {
        ...lastPayload.current,
        ...params,
        grade_id: params?.grade_id || null,
      };
      const { id, ...restPayload } = payload;

      lastPayload.current = payload;
      const response = await services.get(`${API_URL.program}/students/${id}`, {
        params: restPayload,
      });

      return response.data?.data;
    } catch (error) {
      return {
        current_page: 0,
        data: [],
        first_page_url: "",
        from: 0,
        last_page_url: "",
        last_page: 0,
        next_page_url: "",
        path: "",
        per_page: 0,
        prev_page_url: "",
        to: 0,
        total: 0,
      };
    }
  };

  const updateProgramPriority = async (id: number, params: {priority: number}) => {
    try {
      const response = await services.put(`${API_URL.program}/${id}/priority`, params);
      return response.data?.data;
    } catch (error) {
      throw error;
    }
  }

  return {
    getList,
    getDetail,
    deleteProgram,
    addProgram,
    updateProgram,
    getVariationList,
    addTryout,
    detailTryout,
    addLiveclass,
    getProgramStudents,
    updateLiveclass,
    addLiveClassMeet,
    updateLiveClassMeet,
    deleteLiveClassMeet,
    updateProgramPriority
  };
};

export default useProgram;

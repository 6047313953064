import React, { FC, useEffect, useState } from 'react';
import Modal from 'components/Modal';
import { Button } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useHomework from "services/useAskHomework";
import toast from 'react-hot-toast';
import { ErrorFetch } from 'types';

type Props = {
  show: boolean;
  onClose: () => void;
  data?: PackageData;
  tambah: boolean;
};

const DetailSettingModal: FC<Props> = ({ show, onClose, data, tambah }) => {
  const { addPackage, editPackage } = useHomework();
  const [activeButton, setActiveButton] = useState('chat');
  const [formData, setFormData] = useState<PackageData>({
    id : 0,
    title : "",
    description : "",
    session_type : "",
    price : "",
    duration_minute : undefined,
    is_active : 0,
    created_at : "",
    updated_at : "",
    commission_percentage: undefined,
  });

  useEffect(() => {
    if(data){
      setFormData(data)
      setActiveButton(data.session_type)
    }
  }, [data])

  const handleButtonClick = (button: string) => {
    setActiveButton(button);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSessionChange = (value: string) => {
    setFormData({
      ...formData,
      session_type: value,
    });
  };

  const isFormDataValid = () => {
    return (
      formData.title.trim() !== '' &&
      formData.description.trim() !== '' &&
      formData.duration_minute !== undefined &&
      formData.duration_minute > 0 &&
      formData.price.trim() !== '' &&
      formData.commission_percentage !== undefined
    );
  };

  const handleAddPackage = () => {
    const payload: addPackageParams = {
      title: formData.title,
      description: formData.description,
      session_type: formData.session_type,
      price: parseInt(formData.price),
      duration_minute: formData.duration_minute,
      commission_percentage: formData.commission_percentage,
      view_priority: formData.view_priority
    };
    tambah ? (
      toast.promise(
        addPackage(payload),
        {
          loading: 'Loading...',
          success: () => {
            onClose()
            return ''
          },
          error: (err: ErrorFetch) => {
            const msg = err?.response?.data?.message || 'Gagal menambahkan.';
            return msg;
          }
        }
      )
    ) : (
      toast.promise(
        editPackage({
          id: formData.id.toString(),
          payload: payload
        }),
        {
          loading: 'Loading...',
          success: () => {
            onClose()
            return ''
          },
          error: (err: ErrorFetch) => {
            const msg = err?.response?.data?.message || 'Gagal menambahkan.';
            return msg;
          }
        }
      )
    )
  }

  return (
    <Modal 
      show={show} 
      title={tambah ? "Tambah paket" : "Edit paket"} 
      onClose={onClose}
      customFooter={
        <div className="mt-10 justify-end flex flex-row">
          <button
            className="py-[0.55rem] px-5 mr-3 rounded-xl border border-gray-400"
            onClick={onClose}
          >
            Batal
          </button>
          <button
            disabled={!isFormDataValid()}
            className={`py-[0.625rem] px-5 rounded-xl ${isFormDataValid() ? "bg-primary" : "bg-gray-200"}`}
            onClick={() => {
              handleAddPackage()
            }}
          >
            {tambah? "Tambah paket" : "Simpan"}
          </button>
        </div>
      }
    >
      <div>
        <p className="py-1 text-[14px]">Jenis paket</p>
        <div className='flex gap-2'>
          <Button
            onButtonClick={() => {
              handleButtonClick('chat');
              handleSessionChange('chat')
            }}
              color={activeButton === 'chat' ? 'primary' : 'outline-gray'
            } 
          >
            <FontAwesomeIcon
              icon={icon({ name: "message" })}
              className='mr-2'
            />
            Chat
          </Button>
          <Button
            onButtonClick={() => {
              handleButtonClick('video');
              handleSessionChange('video')
            }} 
              color={activeButton === 'video' ? 'primary' : 'outline-gray'} 
            >
            <FontAwesomeIcon
              icon={icon({ name: "video" })}
              className='mr-2'
            />
            Video
          </Button>
        </div>
      </div>

      <div className='py-2 mt-2'>
        <p className="py-1 text-[14px]">Nama paket</p>
        <input 
          type="text" 
          placeholder="Masukkan nama paket" 
          name="title"
          className="w-full border-gray-300 border rounded-lg p-2" 
          value={formData.title}  
          onChange={handleInputChange}
        />
      </div>
      <div className='py-2'>
        <p className="py-1 text-[14px]">Deskripsi</p>
        <textarea 
          placeholder="Masukkan deskripsi paket" 
          className="w-full border-gray-300 border rounded-lg p-2" 
          name="description"
          value={formData.description}  
          onChange={handleInputChange}
        />
      </div>
      <div className='py-2'>
        <p className="py-1 text-[14px]">Urutan Tampilan</p>
        <input 
          type="number" 
          placeholder="Masukkan durasi" 
          className="w-1/3 border-gray-300 border rounded-lg p-2" 
          name='view_priority'
          value={formData.view_priority ?? 0}  
          onChange={handleInputChange}
        />
      </div>
      <div className='py-2'>
        <p className="py-1 text-[14px]">Durasi (menit)</p>
        <input 
          type="number" 
          placeholder="Masukkan durasi" 
          className="w-1/3 border-gray-300 border rounded-lg p-2" 
          name='duration_minute'
          value={formData.duration_minute}  
          onChange={handleInputChange}
        />
      </div>
      <div className='py-2'>
        <p className="py-1 text-[14px]">Harga paket</p>
        <input 
          type="number" 
          placeholder="Masukkan harga paket" 
          className="w-full border-gray-300 border rounded-lg p-2" 
          name='price'
          value={parseInt(formData.price)}  
          onChange={handleInputChange}/>
      </div>
      <div className='py-2'>
        <p className="py-1 text-[14px]">Komisi pengajar (%)</p>
        <input 
          type="number" 
          placeholder="Masukkan komisi" 
          className="w-1/3 border-gray-300 border rounded-lg p-2" 
          name='commission_percentage'
          value={formData.commission_percentage}  
          onChange={handleInputChange}
        />
      </div>
    </Modal>
  );
};

export default DetailSettingModal;

import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button } from 'components';
import {FC, memo, useState} from 'react';
import BrowseQuestion from 'pages/Syllabus/fragment/Modal/BrowseQuestion';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormSyllabus, Test } from 'views/Silabus/types';
import { QUESTION_DIFFICULTY } from 'constant';
import { ExamKind } from 'views/Silabus/Tambah';

type Props = {
  directoryId: number
  directoryIndex: number
  packageIndex: ExamKind
  isPreview: boolean,
  directory?: ExamItems
}

const Questions: FC<Props> = (props) => {
  const {
    directoryId,
    directoryIndex,
    packageIndex,
    isPreview,
    directory
  } = props;

  const {control, getValues} = useFormContext<FormSyllabus>();
  const {replace, remove, fields} = useFieldArray({
    control,
    name: `exams.${packageIndex}.directories.${directoryIndex}.questions`
  });
  const directories = getValues(`exams.${packageIndex}.directories.${directoryIndex}.crumbs`);

  const [show, setShow] = useState(false);

  const toggle = () => setShow(!show);

  const handleSubmit = (item: BrowseItem[]) => {
    const questions = item.map((val) => {
      const question: Test = {
        name: val.name,
        type: 'exam',
        grade_name: val.grade_name,
        n_essay: val.n_essay,
        n_multiple_choice: val.n_multiple_choice,
        question_package_id: val.id,
        difficulty: val.difficulty,
        test_count: 'all',
        id: val.id
      }

      return question;
    });

    replace(questions);
    toggle();
  };

  return (
    <>
      <section className='border rounded-xl overflow-hidden'>
        <div className='px-5 py-4 flex justify-between items-center border-b bg-slate-100'>
          <p className='font-bold text-xl'>Soal bab</p>
          {!isPreview && (
            <Button.Create
              text='Tambah soal'
              onButtonClick={toggle}
            />
          )}
        </div>
        {fields.length ? (
          <div className='px-5 py-4 grid gap-y-[0.625rem]'>
            {fields.map((question, index) => {
              return (
                <div key={`question-${question.id}`} className='flex w-full justify-between items-center'>
                  <div className='flex items-center gap-x-2'>
                    <FontAwesomeIcon icon={icon({name: 'file-lines'})} />
                    <p>{question.name}</p>
                  </div>
                  <div className='flex items-center gap-x-4'>
                    <Badge color='blue'>
                      {`${question.n_multiple_choice} PG`}
                    </Badge>
                    <Badge color='success'>
                      {`${question.n_essay} essay`}
                    </Badge>
                    <Badge color='gray'>
                      {`${QUESTION_DIFFICULTY[Number(question.difficulty)]}`}
                    </Badge>
                    {!isPreview && (
                      <FontAwesomeIcon
                        icon={icon({name: 'trash-can'})}
                        className='cursor-pointer'
                        onClick={() => remove(index)}
                      />
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <div className='px-5 py-4'>
            <p className='my-6'>Anda belum menambahkan soal apapun.</p>
          </div>
        )}
      </section>
      {!isPreview && (
        <BrowseQuestion
          show={show}
          onClose={toggle}
          onSubmit={(items) => handleSubmit(items)}
          title='Pilih soal'
          directoryId={directoryId}
          selectedQuestion={fields}
          directories={['Soal', ...directories]}
          directory={directory}
        />
      )}
    </>
  );
};

export default memo(Questions);

// React
import { AxiosError } from "axios"
import { API_URL } from "constant"
import { useEffect, useState } from "react"

// API
import { services } from "services"

// Types
import { Module, Test } from "views/Silabus/types"

export type Single = {
  description: string
  major?: null
  grade_id: number
  name: string
  packages: {
    id: number
    content_type: string
    title: string
    description: string
    directories: {
      questions: Test[]
      modules: Module[]
    }[]
  }[]
  subject?: null,
  exams?: {
    uh: {
      directories: ExamItems[];
    };
    pts1: {
      directories: ExamItems[];
    },
    pts2: {
      directories: ExamItems[];
    },
    pas: {
      directories: ExamItems[];
    },
    pat: {
      directories: ExamItems[];
    }
  }
}

export function useSingle(id: string) {
  // Variables
  const defaultValue = {
    id: 0,
    grade_id: 0,
    subject: null,
    major: null,
    name: "Silabus 1",
    description: "Silabus 1 SD",
    packages: []
  }

  // Hooks
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState<Single>(defaultValue)
  const [error, setError] = useState<AxiosError | null>(null)

  useEffect(() => {
    setLoading(true);

    services.get(`${API_URL.syllabus}/${id}`).then((res) => {
      // Variables
      const data: SyllabusDetail = res.data?.data;

      const newData = {
        ...data,
        packages: data.packages.map((_package) => {
          return {
            content_type: _package.content_type,
            description: _package.description,
            id: _package.id,
            title: _package.title,
            directories: _package.directories.map((dir) => ({
              directoryId: dir.id,
              crumbs: dir?.path?.split('/') || [],
              modules: dir?.modules?.map((module) => ({
                ...module,
                module_id: module.id,
                name: module.name
              })),
              questions: dir?.questions?.map((question) => ({
                name: question.name,
                type: question.type || 'exam',
                grade_name: question.grade_name,
                n_essay: question.n_essay,
                n_multiple_choice: question.n_multiple_choice,
                question_package_id: question.id,
                test_count: question?.test_count || 'all',
                difficulty: question.difficulty
            })),
            })),

          }
        }),
        exams: {
          uh: {
            directories: data.exams?.uh?.directories.map((uh) => ({ 
              ...uh,
              directoryId: uh.id,
              crumbs: uh.path?.split('/') || []
            }
            )),
          },
          pts1: {
            directories: data.exams?.pts1?.directories.map((pts1) => ({
              ...pts1,
              directoryId: pts1.id,
              crumbs: pts1.path?.split('/') || []
            }))
          },
          pts2: {
            directories: data.exams?.pts2?.directories.map((pts2) => ({
              ...pts2,
              directoryId: pts2.id,
              crumbs: pts2.path?.split('/') || []
            }))
          },
          pas: {
            directories: data.exams?.pas?.directories.map((pas) => ({
              ...pas,
              directoryId: pas.id,
              crumbs: pas.path?.split('/') || []
            }))
          },
          pat: {
            directories: data.exams?.pat?.directories.map((pat) => ({
              ...pat,
              directoryId: pat.id,
              crumbs: pat.path?.split('/') || []
            }))
          }
        }
      };

      setData(newData);
    }).catch((err: AxiosError) => {
      setError(err);
    }).finally(() => {
      setLoading(false);
    })
  
    return () => {
      setData(defaultValue)
      setLoading(false)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading, error }
}
import { Field } from "formik";

function ScoreFields() {
  return (
    <div className="flex gap-4 mt-4 w-full">
      <fieldset className="w-full">
        <label className="font-medium text-sm">
          Skor Jawaban Jika Benar (Opsional)
        </label>
        <Field
          name="true_score"
          className="outline-none border-b border-[#9DA4B3] placeholder:text-[#9DA4B3] mt-3 pb-2 w-full mb-4"
          placeholder="Masukan skor"
        />
        <span className="block mt-2 text-[#737C8D] text-sm">Contoh: 10</span>
      </fieldset>

      <fieldset className="w-full">
        <label className="font-medium text-sm">
          Skor Jawaban Jika Salah (Opsional)
        </label>
        <Field
          name="false_score"
          className="outline-none border-b border-[#9DA4B3] placeholder:text-[#9DA4B3] mt-3 pb-2 w-full mb-4"
          placeholder="Masukan skor"
        />
        <span className="block mt-2 text-[#737C8D] text-sm">Contoh: -10</span>
      </fieldset>
    </div>
  );
}

export default ScoreFields;

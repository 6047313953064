import { memo, FC, useState, useEffect, useCallback } from "react";
import Modal from "components/Modal";
import { Button } from "components";
import List from "./List";

import useBrowse from "services/useBrowse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useDebounce } from "utilities";
import { SyllabusModule } from "../../Material";
import ListModule from "./ListModule";

type Props = {
  show: boolean;
  title: string;
  onClose: () => void;
  onSubmit: (data: SyllabusModule[]) => void;
  directories?: number[];
  packageIndex: number;
};

const BrowseDirectory: FC<Props> = (props) => {
  const { show, title, onClose, onSubmit, directories, packageIndex } = props;
  const { getList, getListModule } = useBrowse();

  const [data, setData] = useState<BrowseItem[]>([]);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState<SyllabusModule[]>([]);

  const [useDirectory, setUseDirectory] = useState(true);
  const debounceSearch = useDebounce(search);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1); // Track current page
  const [hasMore, setHasMore] = useState(true); // To check if more data is available

  useEffect(() => {
    setSelected([]);
  }, [show]);

  const onModuleSelected = (module: SyllabusModule) => {
    const isDirectoryExist = selected.some(
      (sel) => sel.directoryId === module.directoryId
    );
    if (isDirectoryExist) {
      let temp = selected;
      const directoryIndex = selected.findIndex(
        (sel) => sel.directoryId === module.directoryId
      );
      temp[directoryIndex] = {
        ...temp[directoryIndex],
        modules: module.modules,
      };
      if (module.modules.length) setSelected(temp);
    } else {
      if (module.modules.length) setSelected((prev) => [...prev, module]);
    }
  };
  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchData(nextPage);
  };

  const fetchData = useCallback(
    (currentPage: number) => {
      setLoading(true);
      const params = {
        keyword: debounceSearch,
        per_page: 50,
        page: currentPage,
      };
      const fn = useDirectory ? getList : getListModule;
      fn(params)
        .then((response) => {
          const newData = response?.data || [];
          setData((prevData) =>
            currentPage === 1 ? newData : [...prevData, ...newData]
          ); // Append data
          setHasMore(newData.length > 0); // Check if there are more results
        })
        .catch((err) => console.log("Err", err))
        .finally(() => setLoading(false));
    },
    [debounceSearch, getList, getListModule, useDirectory]
  );

  useEffect(() => {
    setPage(1); // Reset page when search changes or directory/module toggles
    setData([]); // Clear previous data when switching search or type
    fetchData(1);
  }, [debounceSearch, fetchData, useDirectory]);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    if (selected) {
      onSubmit(selected);
    }
  };

  return (
    <Modal
      show={show}
      title={title}
      onClose={handleClose}
      customFooter={
        <div className="float-right flex gap-x-2">
          <Button.Cancel onButtonClick={handleClose} />
          <Button color="primary" onButtonClick={handleSubmit}>
            {title}
          </Button>
        </div>
      }
    >
      <div className="bg-white px-4 py-[0.625rem] rounded-xl flex gap-x-2 items-center border w-full mb-4">
        <FontAwesomeIcon icon={icon({ name: "search" })} />
        <input
          className="w-full"
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {Boolean(search.length) && (
          <FontAwesomeIcon
            icon={icon({ name: "close" })}
            onClick={() => setSearch("")}
            className="cursor-pointer"
          />
        )}

        <button
          onClick={() => setUseDirectory(true)}
          className={`px-[16px] py-[10px] border-none flex justify-center items-center text-black rounded-[12px] font-semibold ${
            useDirectory ? "bg-[#FFF200]" : "bg-gray-500 text-white"
          }`}
        >
          Directory
        </button>
        <button
          onClick={() => setUseDirectory(false)}
          className={`px-[16px] py-[10px] border-none flex justify-center items-center text-black rounded-[12px] font-semibold ${
            !useDirectory ? "bg-[#FFF200]" : "bg-gray-500 text-white"
          }`}
        >
          Modul
        </button>
      </div>
      <p className="text-sm font-bold">
        {useDirectory ? "Directory" : "Modul"}
      </p>
      {loading && page === 1 ? (
        <div className="w-full h-[300px] flex justify-center items-center">
          <FontAwesomeIcon icon={icon({ name: "spinner" })} size="3x" spin />
        </div>
      ) : (
        <>
          {useDirectory ? (
            <List
              initialData={data}
              onSelect={(item) => onModuleSelected(item)}
              selected={selected}
              disabledIds={directories}
              packageIndex={packageIndex}
              isLastPage={hasMore}
            />
          ) : (
            <ListModule
              initialData={data}
              onSelect={(item) => onModuleSelected(item)}
              selected={selected}
              packageIndex={packageIndex}
            />
          )}

          {!loading && !useDirectory && hasMore && (
            <div className="flex justify-center my-4">
              <Button color="primary" onButtonClick={loadMore}>
                Lihat Lebih Banyak
              </Button>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default memo(BrowseDirectory);

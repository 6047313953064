const numberToAlphabet = (num: number): string => {
  let result = "";
  while (num > 0) {
    num--; // Adjust for zero-indexing
    const remainder = num % 26;
    result = String.fromCharCode(65 + remainder) + result;
    num = Math.floor(num / 26);
  }
  return result;
};

export default numberToAlphabet;

import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputNumber } from "components";
import ToggleSwitch from "components/ToggleSwitch";
import { FC, memo, useEffect, useMemo, useState } from "react";
import { set } from "react-hook-form";
import { currencyConverter } from "utilities";

type ScoreDifficulty = keyof TryoutPayload["score"] | "general";

type Props = {
  canEdit?: boolean;
  data: Partial<TryoutPayload>;
  onSwitch?: (isFlat: boolean) => void;
  onChange?: (difficulty: ScoreDifficulty, value: number, maxScore?: boolean) => void;
  isEdit?: boolean;
  onSave?: (data: Partial<TryoutPayload>) => void;
};

const Score: FC<Props> = (props) => {
  const {
    canEdit = false,
    data,
    onSwitch,
    onChange,
    isEdit: isEditMode = false,
    onSave,
  } = props;

  const [currentData, setData] = useState(data);
  const [isEdit, setIsEdit] = useState(isEditMode);

  const {
    is_flat_score,
    score,
    question_package = [],
    question_each_student = 0,
    max_score
  } = currentData;

  const easyQuestion = question_package.filter((val) => val.difficulty === 1);
  const mediumQuestion = question_package.filter((val) => val.difficulty === 2);
  const hardQuestion = question_package.filter((val) => val.difficulty === 3);

  const totalScore = useMemo(() => {
    const totalEasy = easyQuestion.reduce((prev, current) => {
      return prev + current.n_question_value;
    }, 0);
    const totalMedium = mediumQuestion.reduce((prev, current) => {
      return prev + current.n_question_value;
    }, 0);
    const totalHard = hardQuestion.reduce((prev, current) => {
      return prev + current.n_question_value;
    }, 0);
    const easyScore = score?.easy || 0;
    const mediumScore = score?.medium || 0;
    const hardScore = score?.hard || 0;

    if (is_flat_score) {
      return easyScore * question_each_student;
    } else {
      return (
        totalEasy * easyScore +
        totalMedium * mediumScore +
        totalHard * hardScore
      );
    }
  }, [
    is_flat_score,
    score,
    question_each_student,
    easyQuestion,
    mediumQuestion,
    hardQuestion,
  ]);

  useEffect(() => {
    setData(data);
  }, [data]);

  const toggleEdit = () => setIsEdit(!isEdit);

  const handleCancel = () => {
    setData(data);
    toggleEdit();
  };

  const handleSave = () => {
    if (onSave) {
      onSave(currentData);
    }
    toggleEdit();
  };

  const handleSwitch = (value: boolean) => {
    if (onSwitch) {
      onSwitch(value);
    }
    setData({
      ...currentData,
      is_flat_score: value,
      score: {
        easy: 0,
        medium: 0,
        hard: 0,
      },
    });
    setRandomScore(generateRandomScore(totalScore ?? 0));
  };

  const handleGeneralScore = (value: number) => {
    if (onChange) {
      onChange("general", value);
    }
    setData({
      ...currentData,
      score: {
        easy: value,
        medium: value,
        hard: value,
      },
    });
    setRandomScore(generateRandomScore(totalScore));
  };

  const handleScore = (difficulty: ScoreDifficulty, value: number) => {
    const score = {
      easy: currentData.score?.easy || 0,
      medium: currentData.score?.medium || 0,
      hard: currentData.score?.hard || 0,
    };
    if (onChange) {
      onChange(difficulty, value);
    }
    setData({
      ...currentData,
      score: {
        ...score,
        [difficulty]: value,
      },
    });
    setRandomScore(generateRandomScore(totalScore));
  };

  const handleMaxScore = (value: number) => {
    if (onChange) {
      onChange("general", value, true);
    }
    setData({
      ...currentData,
      max_score: value,
    });
    setRandomScore(generateRandomScore(totalScore));
  };

  const generateRandomScore = (max: number, min: number = 0) => {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }
  const [randomScore, setRandomScore] = useState(generateRandomScore(totalScore));
  function formatNumber( num: number ) {
    return ( Math.floor(num * 1000)/1000 )  // slice decimal digits after the 2nd one
    .toFixed(2)  // format with two decimal places
    .substr(0,4) // get the leading four characters
    .replace(/\.$/,''); // remove trailing decimal place separator
}
  return (
    <div className="flex-1 w-full bg-white rounded-xl">
      <div className="flex h-fit items-center justify-between px-5 py-4 border-b">
        <p className="font-bold">Pengaturan skor</p>
        {canEdit &&
          (isEdit ? (
            <div className="flex items-center gap-x-2">
              <FontAwesomeIcon
                color="#F04438"
                icon={icon({ name: "close" })}
                className="cursor-pointer"
                onClick={handleCancel}
              />
              <FontAwesomeIcon
                color="#17B26A"
                icon={icon({ name: "floppy-disk", style: "regular" })}
                className="cursor-pointer"
                onClick={handleSave}
              />
            </div>
          ) : (
            <FontAwesomeIcon
              color="#B5A300"
              icon={icon({ name: "edit" })}
              className="cursor-pointer"
              onClick={toggleEdit}
            />
          ))}
      </div>
      <div className={`${isEdit ? "gap-y-5" : "gap-y-3"} p-5 grid`}>
        {isEdit ? (
          <>
            <div>
              <ToggleSwitch
                label="Skor general (per soal)"
                isChecked={currentData.is_flat_score}
                onChange={(val) => handleSwitch(val)}
              />
              <InputNumber
                label=""
                placeholder="Skor general"
                options={{
                  numeral: true,
                  delimiter: ".",
                  numeralDecimalMark: ",",
                }}
                disabled={!currentData.is_flat_score}
                className="w-full mt-1.5 border border-gray-300 px-3 py-2 rounded-lg"
                value={
                  currentData.is_flat_score ? currentData.score?.easy || "" : ""
                }
                onChange={(e) => handleGeneralScore(Number(e.target.rawValue))}
              />
            </div>
            <div className="flex items-center gap-x-5">
              <InputNumber
                label="Soal mudah"
                placeholder="Soal mudah"
                options={{
                  numeral: true,
                  delimiter: ".",
                  numeralDecimalMark: ",",
                }}
                disabled={currentData.is_flat_score || !easyQuestion.length}
                className="w-full border border-gray-300 px-3 py-2 rounded-lg"
                value={
                  currentData.is_flat_score ? "" : currentData.score?.easy || ""
                }
                onChange={(e) => handleScore("easy", Number(e.target.rawValue))}
              />
              <InputNumber
                label="Soal sedang"
                placeholder="Soal sedang"
                options={{
                  numeral: true,
                  delimiter: ".",
                  numeralDecimalMark: ",",
                }}
                disabled={currentData.is_flat_score || !mediumQuestion.length}
                className="w-full border border-gray-300 px-3 py-2 rounded-lg"
                value={
                  currentData.is_flat_score
                    ? ""
                    : currentData.score?.medium || ""
                }
                onChange={(e) =>
                  handleScore("medium", Number(e.target.rawValue))
                }
              />
              <InputNumber
                label="Soal sulit"
                placeholder="Soal sulit"
                options={{
                  numeral: true,
                  delimiter: ".",
                  numeralDecimalMark: ",",
                }}
                disabled={currentData.is_flat_score || !hardQuestion.length}
                className="w-full border border-gray-300 px-3 py-2 rounded-lg"
                value={
                  currentData.is_flat_score ? "" : currentData.score?.hard || ""
                }
                onChange={(e) => handleScore("hard", Number(e.target.rawValue))}
              />
            </div>
          </>
        ) : (
          <>
            <div className="flex gap-x-2">
              <p className="font-medium text-gray-500 w-32">Skor general</p>
              <p className="font-bold text-lg">
                {currentData.score?.easy && currentData.is_flat_score
                  ? currencyConverter(currentData.score?.easy, true)
                  : "-"}
              </p>
            </div>
            <div className="flex gap-x-2">
              <p className="font-medium text-gray-500 w-32">Skor mudah</p>
              <p className="font-bold text-lg">
                {currentData.score?.easy && !currentData.is_flat_score
                  ? currencyConverter(currentData.score?.easy, true)
                  : "-"}
              </p>
            </div>
            <div className="flex gap-x-2">
              <p className="font-medium text-gray-500 w-32">Skor sedang</p>
              <p className="font-bold text-lg">
                {currentData.score?.medium && !currentData.is_flat_score
                  ? currencyConverter(currentData.score?.medium, true)
                  : "-"}
              </p>
            </div>
            <div className="flex gap-x-2">
              <p className="font-medium text-gray-500 w-32">Skor sulit</p>
              <p className="font-bold text-lg">
                {currentData.score?.hard && !currentData.is_flat_score
                  ? currencyConverter(currentData.score?.hard, true)
                  : "-"}
              </p>
            </div>
          </>
        )}
        <div className="grid gap-y-1.5">
          <p className="font-medium">Total skor jika semua jawaban benar</p>
          <p className="font-bold text-xl">
            {currencyConverter(totalScore, true)}
          </p>
        </div>
        {isEdit ? (
          <InputNumber
            label="Skor Maksimal"
            placeholder="Skor Maksimal"
            options={{
              numeral: true,
              delimiter: ".",
              numeralDecimalMark: ",",
            }}
            className="w-full border border-gray-300 px-3 py-2 rounded-lg"
            value={
              currentData.max_score ?? 0
            }
            onChange={(e) => handleMaxScore(Number(e.target.rawValue))}
        />
        ) : (
          <div className="grid gap-y-1.5">
            <p className="font-medium">Skor Maksimal</p>
            <p className="font-bold text-xl">
              {currencyConverter(max_score ?? 0, true)}
            </p>
          </div>
          
        )}
        <div className="grid gap-y-1.5">
          <p className="font-medium">Simulasi Perolehan Skor</p>
          <div className="flex gap-x-2">
          <p className="font-medium text-gray-500 w-52">Skor Benar</p>
          <p className="font-bold text-xl">
            {currencyConverter(randomScore, true)}
          </p>
          </div>
          <div className="flex gap-x-2">
          <p className="font-medium text-gray-500 w-52">Skor yang diperoleh</p>
          <p className="font-bold text-xl">
            {formatNumber((randomScore / totalScore)  * (max_score ?? 0))}
          </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Score);

import { Button, Input, InputNumber, Label, Spinner } from "components";
import Modal from "components/Modal";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import useSchoolStaff from "services/useSchoolStaff";
import { Combobox } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import useSchool from "services/useSchool";
import { InView } from "react-intersection-observer";
import { useDebounce, validateEmail } from "utilities";

type Params = {
  name: string;
  page: number;
};

type Props = {
  show: boolean;
  onClose: () => void;
  onSuccess: (data: SchoolStaffData) => void;
  data: SchoolStaffData;
};

const defaultPagination: PaginationResponse = {
  current_page: 1,
  per_page: 10,
  last_page: 1,
  from: 1,
  to: 1,
  total: 1,
};

const Edit: FC<Props> = ({ show, onClose, data, onSuccess }) => {
  const { edit } = useSchoolStaff();
  const { getList } = useSchool();
  const { control, setValue, watch, handleSubmit } =
    useForm<SchoolStaffPayload>({
      defaultValues: {
        name: data.name,
        email: data.email,
        phone_number: data.phone_number,
        password: "",
        phone_code: "62",
      },
    });
  const { pathname } = useLocation();
  const id = pathname.split("/school-staff/")[1];
  const { schools } = watch();
  const [schoolList, setSchoolList] = useState<Common[]>([]);
  const [selectedSchool, setSelectedSchool] = useState<Common | null>(null);
  const [pagination, setPagination] = useState(defaultPagination);
  const [search, setSearch] = useState("");
  const debounceSearch = useDebounce(search);

  const { name, email, phone_number } = watch();

  const canSubmit = useMemo(() => {
    const validEmail = validateEmail(String(email));

    return Boolean(name && validEmail && phone_number);
  }, [name, email, phone_number]);

  const handleClose = () => {
    onClose();
    setValue("name", data.name);
    setValue("email", data.email);
    setValue("phone_number", data.phone_number);
  };

  const handleEdit = (params: SchoolStaffPayload) => {
    toast.promise(edit(id, params), {
      loading: "...",
      success: () => {
        const newData = {
          name: params.name,
          email: params.email,
          phone_number: params.phone_number,
        };
        onSuccess(newData as SchoolStaffData);
        return "Data berhasil diperbarui";
      },
      error: (err) => {
        return err;
      },
    });
  };

  const getSchool = useCallback(
    async (params?: Params) => {
      const { page = 1, name = "" } = params ?? {};
      const response = await getList({
        name,
        page,
      });
      const { data, ...restResponse } = response;

      let list = [];
      if (page === 1) {
        list = data;
      } else {
        list = [...schoolList, ...data];
      }

      list.push({
        id: 0,
        name: "lainnya",
      });

      setSchoolList(list);
      setPagination(restResponse);
    },
    // eslint-disable-next-line
    [schoolList]
  );

  useEffect(() => {
    setSelectedSchool({
      id: Number(schools?.id),
      name: schools?.name ?? "",
    });
  }, [schools]);

  const loadMore = () => {
    getSchool({
      name: search,
      page: pagination?.current_page + 1,
    });
  };

  const handleSchool = (value: Common) => {
    const isOther = value.name.toLowerCase() === "lainnya";
    setSearch("");
    setSelectedSchool(value);
    if (!isOther) {
      setValue("schools.id", String(value.id));
    }
    setValue("schools.name", isOther ? "" : value.name);
    setValue("schools.isOther", isOther);
  };

  useEffect(() => {
    if (debounceSearch.length >= 3) {
      getSchool({
        page: 1,
        name: debounceSearch,
      });
    }

    if (!debounceSearch.length) {
      getSchool();
    }
    // eslint-disable-next-line
  }, [debounceSearch]);

  return (
    <Modal
      title="Edit Staff Sekolah"
      show={show}
      onClose={onClose}
      customFooter={
        <div className="float-right space-x-2">
          <Button.Cancel onButtonClick={handleClose} />
          <Button
            color="primary"
            isDisabled={!canSubmit}
            onButtonClick={handleSubmit((data) => handleEdit(data))}
          >
            Simpan
          </Button>
        </div>
      }
    >
      <div className="grid grid-cols-2 gap-x-[3.75rem]">
        <div className="space-y-5">
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <Input
                label="Nama"
                placeholder="Nama siswa"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <Input.Email
                label="Email"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="phone_number"
            render={({ field }) => (
              <div>
                <Label>No. handphone</Label>
                <div className="`w-full flex items-center gap-x-2 mt-2 border rounded-lg px-3.5 py-2.5 bg-white">
                  <span>+62</span>
                  <InputNumber
                    label=""
                    placeholder="Masukkan nomor handphone"
                    value={field.value}
                    onChange={field.onChange}
                    className="w-full"
                    options={{
                      phone: true,
                      phoneRegionCode: "ID",
                    }}
                  />
                </div>
              </div>
            )}
          />
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <Input.Password
                label="Password"
                requirement="Minimal 8 karakter terdiri dari huruf kapital, huruf kecil, angka dan
            karakter spesial"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </div>
        <div className="p-5 space-y-5">
          <div>
            <Label>Nama sekolah</Label>
            <Combobox value={selectedSchool} onChange={handleSchool}>
              <div className="relative">
                <Combobox.Button
                  as="div"
                  className={`flex border rounded-lg px-3.5 py-2.5 items-center gap-x-2 ${"bg-white"}`}
                >
                  <Combobox.Input
                    className="w-full"
                    displayValue={() => selectedSchool?.name || search}
                    placeholder="Masukkan nama sekolah"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
                </Combobox.Button>
                <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 z-10">
                  {schoolList.length === 0 ? (
                    <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                      Data tidak ditemukan.
                    </div>
                  ) : (
                    schoolList.map((school, index) => (
                      <Combobox.Option
                        key={`${school.id}-${index}`}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 text-gray-900 ${
                            active ? "bg-primary" : ""
                          }`
                        }
                        value={school}
                      >
                        {() => {
                          const selected = selectedSchool?.id === school.id;

                          return (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {school.name}
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                  <FontAwesomeIcon
                                    icon={icon({ name: "check" })}
                                  />
                                </span>
                              ) : null}
                            </>
                          );
                        }}
                      </Combobox.Option>
                    ))
                  )}
                  {pagination.current_page < pagination.last_page && (
                    <InView
                      as="div"
                      onChange={(inView) => {
                        if (inView) {
                          loadMore();
                        }
                      }}
                    >
                      <Spinner />
                    </InView>
                  )}
                </Combobox.Options>
              </div>
            </Combobox>
          </div>
          {schools?.isOther && (
            <>
              <Controller
                control={control}
                name="schools.name"
                render={({ field }) => (
                  <Input
                    label="Nama sekolah"
                    placeholder="Masukkan nama sekolah"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <Controller
                control={control}
                name="schools.nis"
                render={({ field }) => (
                  <Input
                    label="NIS"
                    placeholder="Masukkan NIS"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default memo(Edit);

import { useState, useCallback, useMemo } from "react";
// API
import { services } from "services";

// Components
import { FormSection } from "./components";
import Breadcrumbs from "components/Breadcrumbs";
import Meeting from "pages/Syllabus/fragment/Meeting";

// Form
import {
  FormProvider,
  Resolver,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// Third-Party Libraries
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// Types
import { ErrorFetch, SuccessFetch } from "types";
import { FormSyllabus, Form as FormType } from "./types";

// Utilities
import { baseData, validationSchema } from "../utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { API_URL } from "constant";
import Exam from "pages/Syllabus/fragment/Exam";

export type Form = FormType;
export type ExamKind = "uh" | "pat" | "pas" | "pts1" | "pts2";

export default function Tambah(): JSX.Element {
  const navigate = useNavigate();

  const [state, setState] = useState({
    isMeeting: false,
    packageIndex: 0,
    isEdit: false,
    isExam: false,
    packageExamKind: "uh",
  });

  const defaultCrumbs: Crumb[] = useMemo(
    () => [
      { label: "Silabus", id: 0 },
      { label: "Tambah Silabus baru", id: 1 },
    ],
    []
  );

  const methods = useForm<FormSyllabus>({
    defaultValues: {
      grade_id: null,
      subject_id: null,
      major_id: null,
      name: "", // Required string field
      description: "", // Required string field
      packages: [], // Empty mutable array for form packages
      exams: {
        uh: { directories: [] }, // Empty mutable array for directories
        pat: { directories: [] },
        pas: { directories: [] },
        pts1: { directories: [] },
        pts2: { directories: [] },
      },
    }, // No need for 'as const' here to avoid readonly types
    resolver: yupResolver(
      validationSchema
    ) as unknown as Resolver<FormSyllabus>, // Casting to unknown first, then to Resolver<FormSyllabus>
  });

  const { control } = methods;
  const { insert, remove, fields } = useFieldArray({
    control,
    name: "packages",
  });

  const onSubmit = async (values: FormSyllabus) => {
    try {
      const payload: AddSyllabusParams = {
        ...values,
        packages: values.packages.map((packageItem) => ({
          content_type: packageItem.content_type,
          description: packageItem.description,
          title: packageItem.title,
          directories: packageItem.directories.map((dir) => ({
            id: dir.directoryId,
            module_ids: dir.modules.map((module) => module.module_id),
            questions: dir.questions.map((question) => ({
              n_essay: question.n_essay,
              n_multiple_choice: question.n_multiple_choice,
              question_package_id: question.question_package_id,
              type: String(question.type),
            })),
          })),
        })),
        exams: {
          uh: {
            directories: values.exams.uh.directories.map((dir) => ({
              directoryId: dir.directoryId,
              questions: dir.questions.map((question) => ({
                n_essay: question.n_essay,
                n_multiple_choice: question.n_multiple_choice,
                question_package_id: question.question_package_id,
                type: "exam",
                test_count: "all"
              })),
            })),
          },
          pat: {
            directories: values.exams.pat.directories.map((dir) => ({
              directoryId: dir.directoryId,
              questions: dir.questions.map((question) => ({
                n_essay: question.n_essay,
                n_multiple_choice: question.n_multiple_choice,
                question_package_id: question.question_package_id,
                type: "exam",
                test_count: "all"
              })),
            })),
          },
          pas: {
            directories: values.exams.pas.directories.map((dir) => ({
              directoryId: dir.directoryId,
              questions: dir.questions.map((question) => ({
                n_essay: question.n_essay,
                n_multiple_choice: question.n_multiple_choice,
                question_package_id: question.question_package_id,
                type: "exam",
                test_count: "all"
              })),
            })),
          },
          pts1: {
            directories: values.exams.pts1.directories.map((dir) => ({
              directoryId: dir.directoryId,
              questions: dir.questions.map((question) => ({
                n_essay: question.n_essay,
                n_multiple_choice: question.n_multiple_choice,
                question_package_id: question.question_package_id,
                type: "exam",
                test_count: "all"
              })),
            })),
          },
          pts2: {
            directories: values.exams.pts2.directories.map((dir) => ({
              directoryId: dir.directoryId,
              questions: dir.questions.map((question) => ({
                n_essay: question.n_essay,
                n_multiple_choice: question.n_multiple_choice,
                question_package_id: question.question_package_id,
                type: "exam",
                test_count: "all"
              })),
            })),
          },
        },
      };

      await toast.promise(services.post(API_URL.syllabus, payload), {
        loading: "Loading...",
        success: (res: SuccessFetch<{ message: string }>) => res.data.message,
        error: (err: ErrorFetch) => err?.response?.data?.message || "Error",
      });

      navigate(baseData.url, { replace: true, state: { useCache: true } });
    } catch (error) {
      console.error("Submission failed:", error);
    }
  };

  const handleCrumb = useCallback(
    (item: Crumb) => {
      if (item.id === 0) navigate("/silabus");
    },
    [navigate]
  );

  const handleMeeting = useCallback(
    (id?: number) => {
      if (id !== undefined || typeof id === "number") {
        if (!state.isEdit) {
          remove(id);
        }
        setState((prev) => ({ ...prev, isEdit: false }));
      } else {
        const packageLength = fields.length;

        insert(packageLength, {
          content_type: "mixed",
          description: "",
          directories: [],
          title: "",
        });
        setState((prev) => ({
          ...prev,
          isEdit: false,
          isMeeting: true,
          isExam: false,
          packageIndex: packageLength,
        }));
      }
    },
    [fields.length, insert, remove, state]
  );

  const editMeeting = useCallback((index: number) => {
    setState((prev) => ({
      ...prev,
      isEdit: true,
      packageIndex: index,
      isMeeting: true,
    }));
  }, []);

  const editExam = useCallback((index: ExamKind) => {
    setState((prev) => ({
      ...prev,
      isEdit: true,
      packageIndex: 0,
      isMeeting: false,
      isExam: true,
      packageExamKind: index,
    }));
  }, []);

  return (
    <>
      {!state.isMeeting && (
        <Breadcrumbs crumbs={defaultCrumbs} onClick={handleCrumb} />
      )}
      <FormProvider {...methods}>
        {state.isMeeting ? (
          <Meeting
            onCancel={() => handleMeeting(state.packageIndex)}
            onSubmit={() => setState((prev) => ({ ...prev, isMeeting: false }))}
            index={state.packageIndex}
          />
        ) : state.isExam && state.packageExamKind !== "" ? (
          <Exam
            onCancel={() => handleMeeting(state.packageIndex)}
            onSubmit={() => setState((prev) => ({ ...prev, isExam: false }))}
            index={state.packageExamKind as ExamKind}
          /> // Handle Exam UI here
        ) : (
          <>
            <FormSection
              onSetMeeting={handleMeeting}
              onEditMeeting={editMeeting}
              onEditExam={editExam}
            />
            <section className="flex py-5 gap-x-3 justify-end">
              <button
                onClick={() => navigate("/silabus")}
                className="px-4 py-[0.625rem] rounded-xl border border-primary bg-white hover:bg-primary"
              >
                Batal
              </button>
              <button
                onClick={methods.handleSubmit(onSubmit, (err) =>
                  console.log(err)
                )}
                className="px-4 py-[0.625rem] rounded-xl bg-primary"
              >
                <FontAwesomeIcon
                  icon={icon({ name: "floppy-disk", style: "regular" })}
                  className="mr-[0.625rem]"
                />
                Simpan
              </button>
            </section>
          </>
        )}
      </FormProvider>
    </>
  );
}

import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, memo, useMemo, useState } from "react";
import { FormSyllabus, Module } from "../../../../../views/Silabus/types";
import { SyllabusModule } from "../../Material";
import { useFormContext, useWatch } from "react-hook-form";

type Props = {
  directoryId?: number;
  directories?: string[];
  initialData?: BrowseItem[];
  indent?: number;
  onSelect: (item: SyllabusModule) => void;
  selected?: SyllabusModule[];
  packageIndex: number;
};

const ListModule: FC<Props> = (props) => {
  const { initialData = [], selected } = props;
  return <RenderItem data={initialData} selected={selected} {...props} />;
};

const RenderItem: FC<Props & { data: BrowseItem[] }> = memo((props) => {
  const { data, indent = 1, onSelect, selected, packageIndex } = props;

  const { control } = useFormContext<FormSyllabus>();
  const watchDirectories = useWatch({
    name: `packages.${packageIndex}.directories`,
    control,
  });
  const watchModules = useMemo(() => {
    const modules: Module[] = [];
    watchDirectories?.forEach((dir) => {
      modules.push(...dir.modules);
    });
    return modules;
  }, [watchDirectories]);
  watchDirectories?.forEach((dir) => [...watchModules, dir.modules]);
  const [selectedModuleInDirectory, setSelectedModuleInDirectory] =
    useState<Module[]>(watchModules);
  const getDirectoryPathArray = (syllabusModule: Module) => {
    const dirArr = syllabusModule.directory_path?.split("/") || [];
    const lastDirName = dirArr[dirArr.length - 1];
    const result = dirArr.slice(0, -1).reverse();
    result.push(lastDirName);
    return result;
  };

  const onClickModule = (item: BrowseItem) => {
    const isExist = selectedModuleInDirectory?.find(
      (val) => String(item.id) === String(val.module_id)
    );
    // detecting unchecked
    if (isExist !== undefined) {
      setSelectedModuleInDirectory(
        selectedModuleInDirectory?.filter(
          (val) => String(item.id) !== String(val.module_id)
        )
      );
      const selecting = selected?.find(
        (val) => String(val.directoryId) === String(item.directory_id)
      );
      if (selecting) {
        selecting.modules = selecting.modules.filter(
          (val) => String(val.module_id) !== String(item.id)
        );
        onSelect(selecting);
      }
    } else {
      setSelectedModuleInDirectory([
        ...selectedModuleInDirectory,
        {
          module_id: item.id,
          name: item.name,
          directory_id: item.directory_id,
        },
      ]);
      const selecting = selected?.find(
        (val) => String(val.directoryId) === String(item.directory_id)
      );
      if (selecting) {
        selecting.modules = [
          ...selecting.modules,
          { module_id: item.id, name: item.name },
        ];
        onSelect(selecting);
      } else {
        const newSelecting: SyllabusModule = {
          directories: getDirectoryPathArray({
            name: item.name,
            module_id: item.id,
            directory_path: item.directory_path,
          }),
          directoryId: item.directory_id!,
          modules: [
            {
              name: item.name,
              module_id: item.id,
            },
          ],
        };
        onSelect(newSelecting);
      }
    }
  };

  return data.length ? (
    <>
      {data.map((item) => {
        let paddingLeft = 32 * (indent - 1);
        paddingLeft = paddingLeft ? paddingLeft : 4;
        const isSelected = Boolean(
          selectedModuleInDirectory.find((val) => val.module_id === item.id)
        );

        return (
          <>
            <div
              className={`
                        flex 
                        w-full 
                        items-center 
                        gap-x-4 
                        pr-4 
                        py-2 
                        text-left 
                        font-medium
                        hover:bg-yellow-100 
                        border-b
                        ${isSelected ? "bg-yellow-100" : "bg-white"}
                      `}
              style={{
                paddingLeft,
              }}
            >
              <input
                type="checkbox"
                id={`module-${item.id}`}
                className="checkbox checked:bg-yellow-400 checked:border-primary text-gray-500"
                onChange={() => onClickModule(item)}
                checked={isSelected}
                disabled={isSelected}
              />
              <div className="flex items-center gap-x-3 w-full">
                {item.type === "module" ? (
                  <FontAwesomeIcon icon={icon({ name: "file-lines" })} />
                ) : (
                  <FontAwesomeIcon icon={icon({ name: "folder" })} />
                )}
                <span>{item.name}</span>
              </div>
            </div>
          </>
        );
      })}
    </>
  ) : (
    <p className="text-center" />
  );
});

export default memo(ListModule);

import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox } from "@headlessui/react";
import {
  FC,
  memo,
  useState,
  useEffect,
  Fragment,
} from "react";
import { orderParams } from "services/useAskHomework";
import { useDebounce } from "utilities";

type Props = {
  onGetData: (params?: orderParams) => Promise<void>;
};

type SORT = orderParams & {
  id: number;
  name: string;
};

const SORT_LIST: SORT[] = [
  {
    id: 0,
    name: "Terbaru",
    sort_by: "created_at",
    order_by: "desc",
  },
  {
    id: 1,
    name: "Terlama",
    sort_by: "created_at",
    order_by: "asc",
  },
];

const SESSION_LIST: SORT[] = [
  {
    id: 0,
    name: "Semua Tipe",
    session_type: null,
  },
  {
    id: 1,
    name: "video",
    session_type: "video",
  },
  {
    id: 2,
    name: "chat",
    session_type: "chat",
  },
];

const Filter: FC<Props> = ({ onGetData }) => {
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(SORT_LIST[0]);
  const [sortSession, setSortSession] = useState(SESSION_LIST[0]);

  const debounceSearch = useDebounce(search);

  const clearSearch =async () => {
    setSearch('');
  };

  const handleSort = async (value: SORT) => {
    if (value.id !== sort.id) {
      setSort(value);
      await onGetData({
        page: 1,
        sort_by: "created_at",
        order_by: value.order_by,
        session_type: sortSession.session_type,
      });
    }
  };

  const handleSortSession = async (value: SORT) => {
    if (value.id !== sortSession.id) {
      setSortSession(value);
      await onGetData({
        page: 1,
        session_type: value.session_type,
        sort_by: "created_at",
        order_by: sort.order_by,
      });
    }
  };

  useEffect(() => {
    if (debounceSearch.length >= 3) {
      onGetData({
        keyword: debounceSearch,
        page: 1,
      });
    }

    if (!debounceSearch.length) {
      onGetData();
    }
    // eslint-disable-next-line
  }, [debounceSearch]);

  return (
    <div>
      <div className=" flex px-5 pt-4 gap-5">
        <div className='flex-grow bg-white px-4 py-[0.625rem] rounded-xl flex gap-x-2 items-center border border-gray-300 shadow-xs text-sm'>
          <FontAwesomeIcon icon={icon({name: 'search'})} />
          <input
            className='w-full'
            placeholder='Cari invoice atau nama paket...'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {Boolean(search.length) && (
            <FontAwesomeIcon
              icon={icon({name: 'close'})}
              onClick={clearSearch}
              className='cursor-pointer'
            />
          )}
        </div>
        <div className="relative flex-1 min-w-[150px] max-w-[200px]">
          <Listbox value={sortSession} onChange={handleSortSession}>
            <Listbox.Button className="relative flex w-full cursor-default items-center justify-between gap-x-1 rounded-lg border bg-white px-3.5 py-2.5 text-left text-sm">
              <span className="block truncate">{sortSession.name}</span>
              <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
            </Listbox.Button>
            <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {SESSION_LIST.map((val, classIdx) => (
                <Listbox.Option
                  key={`${classIdx}-${classIdx}`}
                  as={Fragment}
                  value={val}
                >
                  {({ active }) => (
                    <li
                      className={`relative flex items-center justify-between cursor-default select-none px-3.5 py-2.5 ${
                        active ? "bg-gray-50" : "text-gray-900"
                      }`}
                    >
                      <span className="block truncate">{val.name}</span>
                      {val.id === sortSession.id && (
                        <FontAwesomeIcon
                          icon={icon({ name: "check" })}
                          color="#7F56D9"
                        />
                      )}
                    </li>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>
        </div>
        <div className="relative flex-1 min-w-[100px] max-w-[150px]">
          <Listbox value={sort} onChange={handleSort}>
            <Listbox.Button className="relative flex w-full cursor-default items-center justify-between gap-x-1 rounded-lg border bg-white px-3.5 py-2.5 text-left text-sm">
              <span className="block truncate">{sort.name}</span>
              <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
            </Listbox.Button>
            <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {SORT_LIST.map((val, classIdx) => (
                <Listbox.Option
                  key={`${classIdx}-${classIdx}`}
                  as={Fragment}
                  value={val}
                >
                  {({ active }) => (
                    <li
                      className={`relative flex items-center justify-between cursor-default select-none px-3.5 py-2.5 ${
                        active ? "bg-gray-50" : "text-gray-900"
                      }`}
                    >
                      <span className="block truncate">{val.name}</span>
                      {val.id === sort.id && (
                        <FontAwesomeIcon
                          icon={icon({ name: "check" })}
                          color="#7F56D9"
                        />
                      )}
                    </li>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>
        </div>
      </div>
    </div>
  );
};

export default memo(Filter);

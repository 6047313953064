import {useRef} from 'react';
import {services} from 'services';
import {API_URL} from 'constant';

const defaultParams: SyllabusParams = {
  page: 1,
  per_page: 10,
  sort_by: 'name',
  order_by: 'asc'
};


const useSyllabus = () => {
  const lastPayload = useRef(defaultParams);

  const getList = async (params?: SyllabusParams) => {
    try {
      let payload = {
        ...lastPayload.current,
        ...params,
      };

      lastPayload.current = payload;
      const response = await services.get(API_URL.syllabus, {
        params: payload
      });
      
      return response.data;
    } catch (error) {
      return [];
    }
  };

  const getDetail = async (id: number): Promise<SyllabusDetail | null> => {
    try {
      const response = await services.get(`${API_URL.syllabus}/${id}`);

      return response.data?.data;
    } catch {
      return null;
    }
  };

  const deleteSyllabus = async (id: number) => {
    try {
      const response = await services.delete(`${API_URL.syllabus}/${id}`);
      if(response.status === 200) {
        return {error: false, message: response.data.data};
      } else {
        return {error: true, message: response.data.message};
      }

    } catch (error : any) {
      return {error: true, message: error.response.data.message};
    }
  }

  return {
    getList,
    getDetail,
    deleteSyllabus
  }
};

export default useSyllabus;

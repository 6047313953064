import { memo, FC, useState, useEffect } from "react";
import Modal from "components/Modal";
import { Button } from "components";

import useBrowse from "services/useBrowse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useDebounce } from "utilities";
import { SyllabusModule } from "../../ExamList";
import ListExam from "./ListExam";
import { ExamKind } from "views/Silabus/Tambah";

type Props = {
  show: boolean;
  title: string;
  onClose: () => void;
  onSubmit: (data: SyllabusModule[]) => void;
  directories?: number[];
  packageIndex: ExamKind;
};

const BrowseDirectoryQuestion: FC<Props> = (props) => {
  const { show, title, onClose, onSubmit, directories, packageIndex } = props;
  const { getList } = useBrowse();

  const [data, setData] = useState<BrowseItem[]>([]);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState<SyllabusModule[]>([]);

  const debounceSearch = useDebounce(search);

  useEffect(() => {
    setSelected([]);
  }, [show]);

  const onModuleSelected = (question: SyllabusModule) => {
    const isDirectoryExist = selected.some(
      (sel) => sel.directoryId === question.directoryId
    );
    if (isDirectoryExist) {
      let temp = selected;
      const directoryIndex = selected.findIndex(
        (sel) => sel.directoryId === question.directoryId
      );
      temp[directoryIndex] = {
        ...temp[directoryIndex],
        questions: question.questions,
      };
      if (question.questions.length) setSelected(temp);
    } else {
      if (question.questions.length) setSelected((prev) => [...prev, question]);
    }
  };

  useEffect(() => {
    if (debounceSearch.length >= 3 || !debounceSearch.length) {
      getList({
        keyword: debounceSearch,
        per_page: 50,
      })
        .then((response) => setData(response?.data || []))
        .catch((err) => console.log("Err", err));
    }
  }, [debounceSearch, getList]);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    if (selected) {
      onSubmit(selected);
    }
  };

  return (
    <Modal
      show={show}
      title={title}
      onClose={handleClose}
      customFooter={
        <div className="float-right flex gap-x-2">
          <Button.Cancel onButtonClick={handleClose} />
          <Button color="primary" onButtonClick={handleSubmit}>
            {title}
          </Button>
        </div>
      }
    >
      <div className="bg-white px-4 py-[0.625rem] rounded-xl flex gap-x-2 items-center border w-full mb-4">
        <FontAwesomeIcon icon={icon({ name: "search" })} />
        <input
          className="w-full"
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {Boolean(search.length) && (
          <FontAwesomeIcon
            icon={icon({ name: "close" })}
            onClick={() => setSearch("")}
            className="cursor-pointer"
          />
        )}
      </div>
      <p className="text-sm font-bold">Direktori</p>
      <ListExam
        initialData={data}
        onSelect={(item) => onModuleSelected(item)}
        selected={selected}
        disabledIds={directories}
        packageIndex={packageIndex}
      />
    </Modal>
  );
};

export default memo(BrowseDirectoryQuestion);

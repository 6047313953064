// React
import { DetailedHTMLProps, TextareaHTMLAttributes, useId } from "react";
import ReactQuill from "react-quill";

// Components

interface Type
  extends DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  label?: string | undefined;
  errors?: string | undefined;
  required?: boolean;
  value?: string;
  onChange?: (...event: any[]) => void;
  isRichText?: boolean;
}

const Toolbar = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ align: [] }],
  ["bold", "italic", "underline", "strike"],
  [{ list: "bullet" }, { list: "ordered" }],
  ["link", "image"],
  ["code-block", "blockquote"],
];

export function TextArea(props: Type): JSX.Element {
  // Hooks
  const id = useId();

  return (
    <div className="mt-4">
      <label className="font-bold text-sm after:content-['*'] after:font-medium after:text-rose-400 after:ml-1">
        {props.label}
      </label>
      {props.isRichText ? (
        <ReactQuill
          theme="snow"
          value={props.value}
          onChange={(val) => {
            if (props.onChange) {
              const editorValue = val.replace(/<(.|\n)*?>/g, "").trim();
              const changeValue = editorValue ? val : "";
              props.onChange(changeValue);
            }
          }}
          modules={{ toolbar: Toolbar }}
          className="rounded-xl border"
        />
      ) : (
        <textarea
          id={id}
          cols={1}
          className="bg-transparent outline-none border-b w-full placeholder:text-[#9DA4B3] py-2 resize-none"
          {...props}
        />
      )}
      {props.errors && (
        <small className="text-error capitalize font-bold">
          {props.errors}
        </small>
      )}
    </div>
  );
}

import { memo, FC, useState, useEffect, useCallback } from "react";
import Modal from "components/Modal";
import { Button } from "components";
import List from "./List";

import useBrowse from "services/useBrowse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useDebounce } from "utilities";

type Props = {
  show: boolean;
  title: string;
  onClose: () => void;
  onSubmit: (data: BrowseItem[]) => void;
  directoryId?: number;
  directories: string[];
  selectedQuestion: BrowseItem[];
};

const BrowseQuestion: FC<Props> = (props) => {
  const { show, title, onClose, onSubmit, selectedQuestion } = props;
  const { getList, getListQuestion } = useBrowse("question");

  const [data, setData] = useState<BrowseItem[]>([]);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState<BrowseItem[]>(
    selectedQuestion ?? []
  );
  const [useDirectory, setUseDirectory] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1); // Page state for pagination
  const [hasMore, setHasMore] = useState(true); // Check if more data is available

  useEffect(() => {
    setSelected(selectedQuestion);
  }, [selectedQuestion]);

  const debounceSearch = useDebounce(search);

  const fetchData = useCallback(
    (currentPage: number) => {
      setLoading(true);
      const params = { keyword: debounceSearch, per_page: 50, page: currentPage };
      const fn = useDirectory ? getList : getListQuestion;

      fn(params)
        .then((response) => {
          const newData = response?.data || [];
          setData((prevData) =>
            currentPage === 1 ? newData : [...prevData, ...newData]
          ); // Append data
          setHasMore(newData.length > 0); // If there's data, more pages are available
        })
        .catch((err) => console.log("Err", err))
        .finally(() => setLoading(false));
    },
    [debounceSearch, getList, getListQuestion, useDirectory]
  );
  useEffect(() => {
    setPage(1); // Reset page when search changes or directory/module toggles
    setData([]); // Clear previous data when switching search or type
    fetchData(1); // Fetch first page of data
  }, [debounceSearch, fetchData, useDirectory]);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    if (selected) {
      onSubmit(selected);
      onClose();
    }
  };

  const handleSelect = (item: BrowseItem) => {
    const newSelected = [...selected, item];
    setSelected(newSelected);
  };

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchData(nextPage);
  };

  return (
    <Modal
      show={show}
      title={title}
      onClose={handleClose}
      customFooter={
        <div className="float-right flex gap-x-2">
          <Button.Cancel onButtonClick={handleClose} />
          <Button color="primary" onButtonClick={handleSubmit}>
            Pilih soal
          </Button>
        </div>
      }
    >
      <>
        <div className="bg-white px-4 py-[0.625rem] rounded-xl flex gap-x-2 items-center border w-full mb-4">
          <FontAwesomeIcon icon={icon({ name: "search" })} />
          <input
            className="w-full"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button
            onClick={() => setUseDirectory(true)}
            className={`px-[16px] py-[10px] border-none flex justify-center items-center text-black rounded-[12px] font-semibold ${
              useDirectory ? "bg-[#FFF200]" : "bg-gray-500 text-white"
            }`}
          >
            Directory
          </button>
          <button
            onClick={() => setUseDirectory(false)}
            className={`px-[16px] py-[10px] border-none flex justify-center items-center text-black rounded-[12px] font-semibold ${
              !useDirectory ? "bg-[#FFF200]" : "bg-gray-500 text-white"
            }`}
          >
            Soal
          </button>
        </div>

        <p className="text-sm font-bold">
          {useDirectory ? "Directory" : "Soal"}
        </p>
        {loading && page === 1 ? (
          <div className="w-full h-[300px] flex justify-center items-center">
            <FontAwesomeIcon icon={icon({ name: "spinner" })} size="3x" spin />
          </div>
        ) : (
          <>
            <List
              initialData={data}
              onSelect={(item) => handleSelect(item)}
              selected={selected}
              useDirectory={useDirectory}
              isLastPage={hasMore}
            />
            {!loading && !useDirectory && hasMore && (
              <div className="flex justify-center mt-4">
                <Button color="primary" onButtonClick={loadMore}>
                  Lihat Lebih Banyak
                </Button>
              </div>
            )}
          </>
        )}
      </>
    </Modal>
  );
};

export default memo(BrowseQuestion);

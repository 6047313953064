import Pusher, { Channel } from "pusher-js";

let askHomeworkChannel: Channel = {} as Channel;

// Only initialize Pusher if the environment variables are present
if (
  process.env.REACT_APP_PUSHER_API_KEY &&
  process.env.REACT_APP_PUSHER_CLUSTER
) {
  const pusherClient = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  });

  askHomeworkChannel = pusherClient.subscribe("ask_homework_channel");
}

export { askHomeworkChannel };
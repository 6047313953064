import { memo, FC, useState, useEffect, useCallback } from "react";
import Modal from "components/Modal";
import { Button } from "components";
import List from "./List";

import useBrowse from "services/useBrowse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useDebounce } from "utilities";

type Props = {
  show: boolean;
  onClose: () => void;
  onSubmit: (data: BrowseItem[]) => void;
  directoryId?: number;
  selectedQuestions: BrowseItem[] | any;
};

const BrowseModule: FC<Props> = (props) => {
  const { show, onClose, onSubmit, selectedQuestions } = props;
  const { getList, getListModule } = useBrowse();

  const [data, setData] = useState<BrowseItem[]>([]);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState<BrowseItem[]>(selectedQuestions);
  const [useDirectory, setUseDirectory] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1); // Track current page
  const [hasMore, setHasMore] = useState(true); // To check if more data is available

  const debounceSearch = useDebounce(search);

  useEffect(() => {
    setSelected(selectedQuestions);
  }, [selectedQuestions]);
  const fetchData = useCallback(
    (currentPage: number) => {
      setLoading(true);
      const params = {
        keyword: debounceSearch,
        per_page: 50,
        page: currentPage,
      };
      const fn = useDirectory ? getList : getListModule;
      fn(params)
        .then((response) => {
          const newData = response?.data || [];
          setData((prevData) =>
            currentPage === 1 ? newData : [...prevData, ...newData]
          ); // Append data
          setHasMore(newData.length > 0); // Check if there are more results
        })
        .catch((err) => console.log("Err", err))
        .finally(() => setLoading(false));
    },
    [debounceSearch, getList, getListModule, useDirectory]
  );

  useEffect(() => {
    setPage(1); // Reset page when search changes or directory/module toggles
    setData([]); // Clear previous data when switching search or type
    fetchData(1); // Fetch first page of data
  }, [debounceSearch, fetchData, useDirectory]);

  const handleSubmit = () => {
    if (selected) {
      onSubmit(selected);
    }
    onClose();
  };

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchData(nextPage);
  };

  return (
    <Modal
      show={show}
      title={"Pilih materi"}
      onClose={onClose}
      customFooter={
        <div className="float-right flex gap-x-2">
          <Button.Cancel onButtonClick={onClose} />
          <Button color="primary" onButtonClick={handleSubmit}>
            Pilih materi
          </Button>
        </div>
      }
    >
      <div className="bg-white px-4 py-[0.625rem] rounded-xl flex gap-x-2 items-center border w-full mb-4">
        <FontAwesomeIcon icon={icon({ name: "search" })} />
        <input
          className="w-full"
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {Boolean(search.length) && (
          <FontAwesomeIcon
            icon={icon({ name: "close" })}
            onClick={() => setSearch("")}
            className="cursor-pointer"
          />
        )}
        <button
          onClick={() => setUseDirectory(true)}
          className={`px-[16px] py-[10px] border-none flex justify-center items-center text-black rounded-[12px] font-semibold ${
            useDirectory ? "bg-[#FFF200]" : "bg-gray-500 text-white"
          }`}
        >
          Directory
        </button>
        <button
          onClick={() => setUseDirectory(false)}
          className={`px-[16px] py-[10px] border-none flex justify-center items-center text-black rounded-[12px] font-semibold ${
            !useDirectory ? "bg-[#FFF200]" : "bg-gray-500 text-white"
          }`}
        >
          Modul
        </button>
      </div>

      <p className="text-sm font-bold">
        {useDirectory ? "Directory" : "Modul"}
      </p>
      {loading && page === 1 ? (
        <div className="w-full h-[300px] flex justify-center items-center">
          <FontAwesomeIcon icon={icon({ name: "spinner" })} size="3x" spin />
        </div>
      ) : (
        <>
          <List
            initialData={data}
            onSelect={(item) => setSelected(item)}
            selected={selected}
            useDirectory={useDirectory}
            isLastPage={hasMore}
          />
          {!loading && !useDirectory && hasMore && (
            <div className="flex justify-center my-4">
              <Button color="primary" onButtonClick={loadMore}>
                Lihat Lebih Banyak
              </Button>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default memo(BrowseModule);

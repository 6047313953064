// React
import { useEffect, useState } from "react"

// API
import { services } from "services"

// Types
import { ErrorFetch, SuccessFetch } from "types"

type Type = {
  value: number
  label: string
}

export function useGrade() {
  // Hooks
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<Type[]>([])

  useEffect(() => {
    setLoading(true)

    services.get("admin/dropdown/grade").then((res: SuccessFetch<{
      data: {
        id: number
        name: string
      }[]
    }>) => {
      setData(res.data.data.map(item => {
        return {
          label: item.name,
          value: item.id
        }
      }))
    }).catch((err: ErrorFetch) => {
      console.log(err.response.data.message)
    }).finally(() => {
      setLoading(false)
    })
  
    return () => {
      setData([])
      setLoading(false)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}
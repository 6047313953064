import { API_URL } from "constant";
import { services } from "./services";

type Params = {
  start_date: string;
  end_date: string;
};

const useDashboard = () => {
  const getRecapStudent = async (): Promise<RecapStudent> => {
    try {
      const resp = await services.get(`${API_URL.dashboard}/recap/student`);

      return resp.data?.data;
    } catch (error) {
      return {
        ask_homework: 0,
        count_all: 0,
        program: {
          class: 0,
          learn: 0,
          tryout: 0,
        },
      };
    }
  };

  const getRecapEngagement = async (): Promise<RecapEngagement> => {
    try {
      const resp = await services.get(`${API_URL.dashboard}/recap/engagement`);

      return resp.data?.data;
    } catch (error) {
      return {
        all: 0,
        having_order: {
          paid: 0,
          unpaid: 0,
        },
        not_having_order: 0,
      };
    }
  };

  const getRecapTurnover = async (params: Params): Promise<RecapTurnover> => {
    try {
      const resp = await services.get(`${API_URL.dashboard}/recap/turnover`, {
        params,
      });

      return resp.data?.data;
    } catch (error) {
      return {
        ask_homework: 0,
        class: 0,
        learn: 0,
        tryout: 0,
        bundle: 0,
      };
    }
  };

  const getBuyer = async (params: Params): Promise<GraphBuyer> => {
    try {
      const resp = await services.get(`${API_URL.dashboard}/graph/buyer`, {
        params,
      });

      return resp.data?.data;
    } catch (error) {
      return {
        "": {
          all: 0,
          ask_homework: 0,
          class: 0,
          learn: 0,
          tryout: 0,
        },
      };
    }
  };

  const getTurnover = async (params: Params): Promise<GraphTurnover[]> => {
    try {
      const resp = await services.get(`${API_URL.dashboard}/graph/turnover`, {
        params,
      });

      return resp.data?.data;
    } catch (error) {
      return [];
    }
  };

  const getStudentOverview = async (): Promise<StudentOverview> => {
    try {
      const resp = await services.get(`${API_URL.dashboard}/student-overview`);

      return resp.data?.data;
    } catch (error) {
      return {
        this_month: 0,
        this_week: 0,
        this_year: 0,
        today: 0,
        total: 0
      };
    }
  };

  return {
    getRecapStudent,
    getRecapEngagement,
    getBuyer,
    getTurnover,
    getRecapTurnover,
    getStudentOverview
  };
};

export default useDashboard;

import {toast} from 'react-hot-toast';
import {services} from 'services';
import {API_URL} from 'constant';
import { AxiosError, AxiosProgressEvent } from 'axios';

type Payload = {
  grade_id?: number
  major_id?: number
  subject_id?: number
  directory_id?: Number
  storage?: string
  file: File
  onUploadProgress?: (progress: AxiosProgressEvent) => void,
  isUrl?: boolean,
  type?: string
}

const useAsset = () => {
  const upload = async (params: Payload) => {
    const {onUploadProgress, ...restParams} = params;
    try {
      const data = new FormData();
      data.append('storage', restParams?.storage || 's3');
      data.append('file', restParams.file);
      data.append('type', restParams?.type || 'asset');
      
      if (restParams.directory_id) {
        data.append('directory_id', String(restParams.directory_id));
      }

      if (restParams.isUrl) {
        data.append("url", URL.createObjectURL(restParams.file))
      }

      const response = await services.post(API_URL.upload, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress(progressEvent) {
          if (onUploadProgress) {
            onUploadProgress(progressEvent);
          }
        },
      });
      const responseData: UploadData = response.data?.data;

      return responseData || null;
    } catch (error) {
      const err = error as AxiosError;
      
      console.error(API_URL.upload, err);
      toast.error('Gagal upload file!');

      return null;
    }
  };

  return {
    upload
  }
};

export default useAsset;

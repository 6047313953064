import { API_URL, DEFAULT_PAGINATION } from "constant";
import { useCallback, useRef } from "react";
import { services } from "./services";

type Response = PaginationResponse & {
  data: BundleItem[];
};

type ResponsePrograms = PaginationResponse & {
  data: ProgramItem[];
};

type ResponseStudents = PaginationResponse & {
  data: BundleStudents[];
};

const defaultParams: BundleListParam = {
  search: "",
  page: 1,
  grade_id: "",
  order: "desc",
  sort_by: "created_at",
  per_page: 10,
};

const useBundle = () => {
  const lastPayload = useRef(defaultParams);

  const getList = useCallback(
    async (params?: BundleListParam): Promise<Response> => {
      try {
        let payload = {
          ...lastPayload.current,
          ...params,
        };

        lastPayload.current = payload;
        const response = await services.get(API_URL.bundle, {
          params: payload,
        });

        return response.data?.data;
      } catch (error) {
        return {
          ...DEFAULT_PAGINATION,
          data: [],
        };
      }
    },
    []
  );

  const getListProgram = useCallback(
    async (params: Partial<BundleListParam>): Promise<ResponsePrograms> => {
      try {
        const response = await services.get(`${API_URL.bundle}/program`, {
          params,
        });

        return response.data?.data;
      } catch (error) {
        return {
          ...DEFAULT_PAGINATION,
          data: [],
        };
      }
    },
    []
  );

  const getListHomework = useCallback(
    async (params: Partial<BundleListParam>): Promise<ResponsePrograms> => {
      try {
        const response = await services.get(
          `${API_URL.bundle}/askhomework_packet`,
          {
            params: {
              ...params,
              order: params.order ?? "desc",
            },
          }
        );

        return response.data?.data;
      } catch (error) {
        return {
          ...DEFAULT_PAGINATION,
          data: [],
        };
      }
    },
    []
  );

  const getListStudent = useCallback(
    async (
      id: string,
      params: Partial<BundleListParam>
    ): Promise<ResponseStudents> => {
      try {
        const response = await services.get(
          `${API_URL.bundle}/${id}/students`,
          {
            params,
          }
        );

        return response.data?.data;
      } catch (error) {
        return {
          ...DEFAULT_PAGINATION,
          data: [],
        };
      }
    },
    []
  );

  const getDetail = async (id: string): Promise<BundleItem | null> => {
    try {
      const response = await services.get(`${API_URL.bundle}/${id}`);

      return response.data?.data;
    } catch (error) {
      return null;
    }
  };

  const addBundle = async (params: BundleParams) => {
    try {
      await services.post(API_URL.bundle, params);
    } catch (error) {
      throw error;
    }
  };

  const updateBundle = async (id: string, params: BundleItem) => {
    try {
      const {
        title,
        description,
        image,
        total_price,
        bundle_price,
        grade_id,
        start_date,
        expired_date,
        detail,
        status,
        view_priority,
        detail_urutan,
      } = params;
      const { programs, ask_homework_packets } = detail;
      const programIds = programs?.map((val) => val.id);
      const homeworks = ask_homework_packets?.map((val) => ({
        id: val.id,
        qty: val.qty,
      }));

      const payload = {
        title,
        image,
        description,
        total_price,
        bundle_price,
        grade_id,
        status,
        start_date,
        expired_date,
        program_id: programIds ?? [],
        ask_homeworks: homeworks ?? [],
        view_priority,
        detail_urutan,
      };

      await services.put(`${API_URL.bundle}/${id}`, payload);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const updateStatus = async (id: number, status: "1" | "0") => {
    try {
      await services.patch(`${API_URL.bundle}/${id}`, {
        status,
      });

      return true;
    } catch (error) {
      return false;
    }
  };

  const updatePriority = async (id: number, view_priority: number) => {
    try {
      await services.patch(`${API_URL.bundle}/${id}/priority`, {
        view_priority,
      });

      return true;
    } catch (error) {
      return false;
    }
  };

  const deleteBundle = async (id: number) => {
    try {
      await services.delete(`${API_URL.bundle}/${id}`);

      return true;
    } catch (error) {
      return false;
    }
  };

  return {
    addBundle,
    getDetail,
    getList,
    updateStatus,
    getListProgram,
    updateBundle,
    getListStudent,
    getListHomework,
    updatePriority,
    deleteBundle,
  };
};

export default useBundle;

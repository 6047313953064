import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, memo } from "react";
import { currencyConverter } from "utilities";

type Props = {
  studentOverview?: StudentOverview;
};

const Overview: FC<Props> = ({ studentOverview }) => {
  return (
    <div className="bg-white rounded-xl px-5 py-4 flex flex-col gap-x-10 overflow-x-auto">
      <span className="font-bold text-3xl mb-5">
      <h2>Siswa Terdaftar</h2>
      </span>
      <div className="flex flex-row gap-x-10 justify-between">
        <div className="space-y-5">
          <div className="bg-yellow-100 rounded-xl px-5 py-4 space-y-2">
            <div className="flex items-center gap-x-2">
              <FontAwesomeIcon
                icon={icon({ name: "user-group" })}
                className="bg-primary rounded-full p-1.5 w-3.5 h-3.5"
              />
              <span className="text-sm font-medium">Hari ini</span>
            </div>
            <span className="font-bold text-5xl">
              {currencyConverter(studentOverview?.today ?? 0, true)}
            </span>
          </div>
        </div>
        <div className="space-y-5">
          <div className="bg-purple-100 rounded-xl px-5 py-4 space-y-2">
            <div className="flex items-center gap-x-2">
              <FontAwesomeIcon
                icon={icon({ name: "user-group" })}
                className="bg-purple-500 rounded-full p-1.5 w-3.5 h-3.5"
              />
              <span className="text-sm font-medium">Minggu ini</span>
            </div>
            <span className="font-bold text-5xl">
              {currencyConverter(studentOverview?.this_week ?? 0, true)}
            </span>
          </div>
        </div>
        <div className="space-y-5">
          <div className="bg-red-100 rounded-xl px-5 py-4 space-y-2">
            <div className="flex items-center gap-x-2">
              <FontAwesomeIcon
                icon={icon({ name: "user-group" })}
                className="bg-red-500 rounded-full p-1.5 w-3.5 h-3.5"
              />
              <span className="text-sm font-medium">Bulan ini</span>
            </div>
            <span className="font-bold text-5xl">
              {currencyConverter(studentOverview?.this_month ?? 0, true)}
            </span>
          </div>
        </div>

        <div className="space-y-5">
          <div className="bg-blue-100 rounded-xl px-5 py-4 space-y-2">
            <div className="flex items-center gap-x-2">
              <FontAwesomeIcon
                icon={icon({ name: "user-group" })}
                className="bg-blue-500 rounded-full p-1.5 w-3.5 h-3.5"
              />
              <span className="text-sm font-medium">Tahun ini</span>
            </div>
            <span className="font-bold text-5xl">
              {currencyConverter(studentOverview?.this_year ?? 0, true)}
            </span>
          </div>
        </div>
        <div className="space-y-5">
          <div className="bg-green-100 rounded-xl px-5 py-4 space-y-2">
            <div className="flex items-center gap-x-2">
              <FontAwesomeIcon
                icon={icon({ name: "user-group" })}
                className="bg-green-500 rounded-full p-1.5 w-3.5 h-3.5"
              />
              <span className="text-sm font-medium">Total</span>
            </div>
            <span className="font-bold text-5xl">
              {currencyConverter(studentOverview?.total ?? 0, true)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Overview);

import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components";
import Table from "components/Table";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useStudent from "services/useStudent";
import { useDebounce } from "utilities";
import DeletePrompt from "./fragment/Modal/DeletePrompt";
import toast from "react-hot-toast";
import Breadcrumbs from "components/Breadcrumbs";
import { services } from "services";
import { Listbox } from "@headlessui/react";

const columns = [
  {
    title: "Nama",
    isSort: true,
    key: "name",
  },
  {
    title: "Strata",
    isSort: true,
    key: "grade",
  },
  {
    title: "Sekolah",
    isSort: true,
    key: "school_name",
  },
  {
    title: "Email",
    isSort: true,
    key: "email",
  },
  {
    title: "Nomor HP",
    isSort: true,
    key: "phone_number",
  },
  {
    title: "Aksi",
  },
];

const defaultPagination: PaginationResponse = {
  current_page: 1,
  per_page: 10,
  last_page: 1,
  from: 1,
  to: 1,
  total: 1,
};

type RequestParams = {
  per_page: number;
  search: string;
  sort_by: "name" | "phone" | "email" | "grade";
  order: "asc" | "desc";
  page: number;
  grade_id?: number;
};
const allGrade = {
  id: 0,
  name: "Semua strata",
};

type SortItem = {
  id: number;
  name: string;
  sort_by: string;
  order_by: "asc" | "desc";
};

const SORT_LIST: SortItem[] = [
  {
    id: 0,
    name: "Terbaru",
    sort_by: "created_at",
    order_by: "desc",
  },
  {
    id: 1,
    name: "Nama A-Z",
    sort_by: "name",
    order_by: "asc",
  },
  {
    id: 2,
    name: "Nama Z-A",
    sort_by: "name",
    order_by: "desc",
  },
  {
    id: 3,
    name: "Sekolah A-Z",
    sort_by: "school_name",
    order_by: "asc",
  },
  {
    id: 4,
    name: "Sekolah Z-A",
    sort_by: "school_name",
    order_by: "desc",
  },
  {
    id: 5,
    name: "Email A-Z",
    sort_by: "email",
    order_by: "asc",
  },
  {
    id: 6,
    name: "Email Z-A",
    sort_by: "email",
    order_by: "desc",
  },
];

const Student = () => {
  const navigate = useNavigate();
  const { getList, deleteStudent } = useStudent();

  const [search, setSearch] = useState("");
  const [data, setData] = useState<StudentData[]>([]);
  const [pagination, setPagination] = useState(defaultPagination);
  const [sortBy, setSortBy] = useState<RequestParams["sort_by"]>("name");
  const [order, setOrder] = useState<RequestParams["order"]>("asc");
  const [selectedItem, setSelectedItem] = useState<StudentData | null>(null);
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState<GradeItem>(allGrade);
  const [sort, setSort] = useState(SORT_LIST[0]);
  const [grades, setGrades] = useState<GradeItem[]>([]);
  const debounceSearch = useDebounce(search);

  const toggleDelete = () => setShowDelete(!showDelete);

  const getData = useCallback(async (params?: RequestParams) => {
    setLoading(true);
    const response = await getList(params);
    const { data: respData, ...restResponse } = response;

    setData(respData);
    setPagination(restResponse);
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  async function GetGrade() {
    services
      .get("admin/dropdown/grade")
      .then((resp) => {
        const data = resp?.data?.data;
        setGrades(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    if (debounceSearch.length >= 3) {
      getData({
        search: debounceSearch,
        per_page: pagination.per_page,
        sort_by: sortBy,
        order,
        page: 1,
        grade_id: selectedGrade.id,
      });
    } else if (!debounceSearch.length) {
      getData({
        search,
        per_page: pagination.per_page,
        sort_by: sortBy,
        order,
        page: 1,
        grade_id: selectedGrade.id,
      });
    }
    // eslint-disable-next-line
  }, [debounceSearch]);

  const handleLimit = (limit: string) => {
    getData({
      per_page: Number(limit),
      page: 1,
      search,
      order,
      sort_by: sortBy,
    });
  };

  const handlePagination = (control: string) => {
    const isNext = control === "next";
    const page = isNext
      ? pagination.current_page + 1
      : pagination.current_page - 1;

    if (page > 0 && page <= pagination.last_page) {
      getData({
        per_page: pagination.per_page,
        page,
        search,
        order,
        sort_by: sortBy,
      });
    }
  };

  const handleSort = (param: string) => {
    let direction = order === "asc" ? "desc" : "asc";
    
    if (param !== sortBy) {
      direction = "asc";
    }

    setOrder(direction as RequestParams["order"]);
    setSortBy(param as RequestParams["sort_by"]);
    getData({
      page: 1,
      sort_by: param as RequestParams["sort_by"],
      order: direction as RequestParams["order"],
      search,
      per_page: pagination.per_page,
    });
  };

  const handleDelete = () => {
    if (selectedItem) {
      toast.promise(deleteStudent(String(selectedItem?.student_id)), {
        loading: `Menghapus ${selectedItem.name}`,
        success: () => {
          toggleDelete();
          setSearch("");
          getData();
          return `${selectedItem.name} berhasil dihapus`;
        },
        error: "Gagal menghapus",
      });
    }
  };
  useEffect(() => {
    if (!grades.length) {
      GetGrade();
    }
  }, [grades]);
  const handleGrade = async (value: typeof allGrade) => {
    if (value.id !== selectedGrade.id) {
      setSelectedGrade(value);
      getData({
        page: 1,
        sort_by: sortBy,
        order: order,
        search,
        per_page: pagination.per_page,
        grade_id: value.id,
      });
    }
  };
  const onChangeSortItem = (sortItem: SortItem) => {
    setSort(sortItem)
    setOrder(sortItem.order_by)
    handleSort(sortItem.sort_by)
  }

  return (
    <>
      <Breadcrumbs crumbs={["Pengguna", "Siswa"]} />
      <div className="my-5 flex flex-wrap items-center justify-between gap-x-5 gap-y-3">
        <div className="bg-white border rounded-lg px-4 py-2.5 flex items-center w-80">
          <FontAwesomeIcon icon={icon({ name: "search" })} />
          <input
            type="search"
            placeholder="Cari siswa..."
            className="ml-2 text-sm w-full"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="relative flex-1 w-36">
          <Listbox value={selectedGrade} onChange={handleGrade}>
            <Listbox.Button className="relative flex w-full cursor-default items-center justify-between gap-x-1 rounded-lg border bg-white px-3.5 py-2.5 text-left text-sm">
              <span className="block truncate">{selectedGrade.name}</span>
              <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
            </Listbox.Button>
            <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {[allGrade, ...grades].map((val, classIdx) => (
                <Listbox.Option
                  key={`${classIdx}-${val.id}`}
                  as={Fragment}
                  value={val}
                >
                  {({ active }) => (
                    <li
                      className={`relative flex items-center justify-between cursor-default select-none px-3.5 py-2.5 ${
                        active ? "bg-gray-50" : "text-gray-900"
                      }`}
                    >
                      <span className="block truncate">{val.name}</span>
                      {val.id === selectedGrade.id && (
                        <FontAwesomeIcon
                          icon={icon({ name: "check" })}
                          color="#7F56D9"
                        />
                      )}
                    </li>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>
        </div>
        <div className="relative flex-1 w-36">
          <Listbox value={sort} onChange={onChangeSortItem}>
            <Listbox.Button className="relative flex cursor-default w-full items-center justify-between gap-x-1 rounded-lg border bg-white px-3.5 py-3 text-left text-sm">
              <span className="block truncate">{sort.name}</span>
              <FontAwesomeIcon icon={icon({ name: "sort" })} />
            </Listbox.Button>
            <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {SORT_LIST.map((val, classIdx) => (
                <Listbox.Option key={`${classIdx}`} as={Fragment} value={val}>
                  {({ active }) => (
                    <li
                      className={`relative flex items-center justify-between cursor-default select-none px-3.5 py-2.5 ${
                        active ? "bg-gray-50" : "text-gray-900"
                      }`}
                    >
                      <span className="block truncate">{val.name}</span>
                      {val.id === sort.id && (
                        <FontAwesomeIcon
                          icon={icon({ name: "check" })}
                          color="#7F56D9"
                        />
                      )}
                    </li>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>
        </div>
        <Button.Create
          onButtonClick={() =>
            navigate("tambah", {
              relative: "path",
            })
          }
        />
      </div>
      <Table
        data={data}
        columns={columns}
        currentLimit={pagination.per_page}
        currentPage={pagination.current_page}
        total={pagination.total}
        handleLimit={handleLimit}
        handlePagination={handlePagination}
        handleSort={handleSort}
        loading={loading}
        handleFirstColumn="20rem"
      >
        {data.map((val) => (
          <tr
            key={val.student_id}
            className="border-b cursor-pointer hover:bg-gray-50"
            onClick={() =>
              navigate(String(val.student_id), {
                relative: "path",
              })
            }
          >
            <td className="p-4">{val.name}</td>
            <td className="p-4">{val.grade_name ?? "-"}</td>
            <td className="p-4">{val.school_name ?? "-"}</td>
            <td className="p-4 text-gray-500">{val.email}</td>
            <td className="p-4 text-gray-500">{`${val.phone_code}${val.phone_number}`}</td>
            <td className="p-4 flex justify-end">
              <div className="flex items-center gap-x-4">
                <FontAwesomeIcon
                  icon={icon({ name: "trash-alt" })}
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedItem(val);
                    toggleDelete();
                  }}
                />
              </div>
            </td>
          </tr>
        ))}
      </Table>
      <DeletePrompt
        show={showDelete}
        onClose={toggleDelete}
        onDelete={handleDelete}
      />
    </>
  );
};

export default Student;

import { Field } from "formik";

type TextFieldProps = {
    name: any;
    label: string;
    placeholder?: string;
    errors: import('formik').FormikErrors<string>;
  }
function TextField({ name, label, placeholder, errors }: TextFieldProps): JSX.Element {
  return (
    <div className="mt-4">
      <label className="font-bold text-sm after:content-['*'] after:font-medium after:text-rose-400 after:ml-1">
        {label}
      </label>
      <Field
        name={name}
        placeholder={placeholder}
        className={
          errors[name]
            ? "outline-none border-b border-rose-400 placeholder:text-rose-400 mt-3 pb-2 w-full"
            : "outline-none border-b border-[#9DA4B3] placeholder:text-[#9DA4B3] mt-3 pb-2 w-full"
        }
      />
      {errors[name] && <p className="text-[#F04438] mt-0.5">{errors[name]}</p>}
    </div>
  );
}

export default TextField;
import { Link } from "react-router-dom";

function SubmitButtons({ isEdit }: { isEdit: boolean }) {
  return (
    <div className="flex mt-6 gap-3">
      <button
        type="submit"
        className="bg-[#FFF200] text-sm font-medium text-[#38393A] rounded-xl w-[126px] px-2 h-[40px] flex items-center justify-center shrink-0"
      >
        {isEdit ? "Simpan Perubahan" : "Simpan"}
      </button>
      <Link to="/bank-soal">
        <div className="cursor-pointer bg-[#FFFBB0] text-sm font-medium text-[#8C8500] rounded-xl w-[74px] h-[40px] flex items-center justify-center shrink-0">
          Batal
        </div>
      </Link>
    </div>
  );
}

export default SubmitButtons;

import SalesSummaryWidget from "./fragment/SalesSummaryWidget";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Listbox} from "@headlessui/react";
import {Fragment, useCallback, useEffect, useState} from "react";
import useGrade from "../../../services/useGrade";
import Table from "../../../components/Table";
import ModalSessionDetail from "./fragment/ModalSessionDetail";
import useAskHomework from "../../../services/useAskHomework";
import moment from "moment";
import {useDebounce} from "../../../utilities";
import StatusBadge from "./fragment/StatusBadge";

const columns = [
  {
    title: 'Siswa',
    isSort: true,
    key: 'student_name',
  },
  {
    title: 'Tipe',
    isSort: true,
    key: 'type'
  },
  {
    title: 'Strata',
    isSort: true,
    key: 'grade'
    // isLeft: true
  },
  {
    title: 'Durasi',
    isSort: true,
    key: 'duration'
  },
  {
    title: 'Pengajar',
    isSort: true,
    key: 'teacher_name'
  },
  {
    title: 'Tanggal',
    isSort: true,
    key: 'created_at'
  },
  {
    title: 'Status',
    isSort: true,
    key: 'status'
  },
];

const GET_LIST_DEFAULT_PARAMS : AskHomeworkSessionParams = {
  per_page: 10,
  page: 1,
  order_by: 'asc'
}

const SessionList = () => {

  const {getList} = useAskHomework();
  const [modalDetailId, setModalDetailId] = useState<string|null>(null)
  const [sortFilterData, setSortFilterData] = useState<AskHomeworkSessionParams>(GET_LIST_DEFAULT_PARAMS)
  const [search, setSearch] = useState('');
  const debounceSearch = useDebounce(search);


  const [data, setData] = useState<AskHomeworkSessionResponse>();

  useEffect(()=>{
    getList(sortFilterData).then((resp)=>{
      setData(resp)
    })
  },[sortFilterData])

  const handleSort =async (sortParams: string) => {
    setSortFilterData((prev)=> ({...prev, sort_by: sortParams}))
  }

  const handleGrade = (gradeParams: GradeItem) => {
    if (gradeParams.id === 0){
      setSortFilterData((prev)=> ({...prev, grade_id: undefined}))
    }else{
      setSortFilterData((prev)=> ({...prev, grade_id: gradeParams.id}))
    }
  }

  const handleSortWidget = (sortItem : SortItem) => {
    setSortFilterData((prev)=> ({
      ...prev,
      sort_by: sortItem.sort_by,
      order_by: sortItem.order_by})
    )
  }

  const handleLimit = async (limit: string) => {
    setSortFilterData((prev)=> ({...prev, per_page: Number(limit)}))
  }

  useEffect(()=>{
    setSortFilterData((prev)=> ({...prev, keyword: debounceSearch}))
  },[debounceSearch])

  const handlePagination = async (ctrl: 'next' | 'prev') => {
    if (data){
      const currentPage = data.current_page
      const lastPage = data.last_page
      const isNext = ctrl === 'next';
      const page = isNext ? currentPage + 1 : currentPage - 1;

      if (page > 0 && page <= lastPage) {
        setSortFilterData((prev)=>({...prev, page: page}))
      }
    }
  }

  if (!data) return null
  return (
    <div className='flex flex-1 space-y-2 flex-col'>
      <SalesSummaryWidget/>
      <div className='bg-white rounded-lg py-4'>
        <span className='px-4 font-bold text-base	'>Daftar sesi</span>
        <div className="divider my-0" />
        <div className='px-4 mt-4'>
          <div className='flex flex-row space-x-4'>
            <SearchBox search={search} setSearch={setSearch}/>
            <StatusFilter
              selected={sortFilterData.status}
              onChange={(status)=>setSortFilterData((prev)=>({...prev, status}))}
            />
            <GradeFilter handleGrade={handleGrade}/>
            <SortWidget handleSort={handleSortWidget}/>
          </div>
        </div>
      </div>
      {/*  Table */}
      <Table
        data={data?.data ?? []}
        columns={columns}
        currentLimit={data.per_page}
        currentPage={data.current_page}
        total={data.total}
        handleLimit={handleLimit}
        handlePagination={handlePagination}
        handleSort={handleSort}
        handleFirstColumn={"max-w-fit"}
      >
        <>
          {data?.data.length ? data?.data?.map((item) => {
            const { id, student_name, type, grade_name, start_time, end_time, teacher_name, created_at, status } = item;
            let duration = '-'
            if (start_time && end_time){
              const startMoment = moment(start_time, 'YYYY-MM-DD HH:mm:ss');
              const endMoment = moment(end_time, 'YYYY-MM-DD HH:mm:ss');

              const durationInMillis = endMoment.diff(startMoment);
              // const duration = moment.duration(durationInMillis);
              duration = moment.utc(durationInMillis).format('HH:mm:ss');
            }
            return (
              <tr key={id} className='border-b cursor-pointer hover:bg-gray-100' onClick={()=>setModalDetailId(id)}>
                <td className='p-4'>{student_name}</td>
                <td className='p-4 text-[#7A7B7E] capitalize'>{type}</td>
                <td className='p-4 text-[#7A7B7E]'>{grade_name}</td>
                <td className='p-4 text-[#7A7B7E]'>{duration}</td>
                <td className='p-4'>{teacher_name}</td>
                <td className='p-4 text-[#7A7B7E]'>{moment(created_at).format('DD/MM/YYYY HH:mm')}</td>
                <td className='p-4 '><StatusBadge status={status}/></td>
              </tr>
            )
          }) : (
            <tr>
              <td
                colSpan={columns.length}
                className="italic opacity-40 text-center pt-3"
              >
                <span>Tidak ada data.</span>
              </td>
            </tr>
          )}
        </>
      </Table>
      <ModalSessionDetail id={modalDetailId} show={!!modalDetailId} onClose={()=>setModalDetailId(null)} />
    </div>
  )
};

const SearchBox = ({search = '', setSearch}:{search?:string, setSearch: (val: string)=>void}) => {
  return(
    <div className='bg-white px-4 py-[0.625rem] rounded-lg flex-1 flex gap-x-2 items-center border border-gray-300'>
      <FontAwesomeIcon icon={icon({name: 'search'})} className={'text-gray-500'}/>
      <input
        className='w-full'
        placeholder='Cari program...'
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {Boolean(search.length) && (
        <FontAwesomeIcon
          icon={icon({name: 'close'})}
          onClick={() => setSearch('')}
          className='cursor-pointer'
        />
      )}
    </div>
  )
}

const STATUS : {name: string; indicatorColor: string; key:AskHomeworkStatusType}[] = [
  {
    name: 'Semua',
    indicatorColor: 'bg-gray-300',
    key: ''
  },
  {
    name: 'Berjalan',
    indicatorColor: 'bg-blue-700',
    key: 'in_progress'
  },
  {
    name: 'Selesai',
    indicatorColor: 'bg-success',
    key: 'done'
  },
]

const StatusFilter = ({selected = '', onChange}:{selected?: AskHomeworkStatusType, onChange: (statusType: AskHomeworkStatusType) => void}) => {
  return(
    <div className='flex flex-row border-gray-300 rounded-lg border divide-x cursor-pointer'>
      {STATUS.map(({indicatorColor, name, key}, index)=>{
        const isFirstItem = index === 0
        const isLastItem = index === STATUS.length - 1
        let roundedStyle = ''
        if (isFirstItem) roundedStyle = 'rounded-s-lg'
        else if (isLastItem) roundedStyle = 'rounded-e-lg'
        return(
          <div key={key} className={`flex flex-row items-center space-x-2 p-2 ${selected === key ? 'bg-[#FFF7B0]' : 'bg-white'} ${roundedStyle}`} onClick={()=>onChange(key)}>
            <div className={`w-3 h-3 rounded-full ${indicatorColor}`}/>
            <span className='text-gray-800 text-sm font-medium'>{name}</span>
          </div>
        )
      })}
    </div>
  )
}

const allGrade = {
  id: 0,
  name: "Semua strata",
};

const GradeFilter = ({handleGrade}: {handleGrade: (grade: GradeItem) => void}) => {
  const [grades, setGrades] = useState<GradeItem[]>([]);
  const [selectedGrade, setSelectedGrade] = useState<GradeItem>(allGrade);

  const { getList } = useGrade();

  const getGrade = useCallback(async () => {
    const response = await getList();
    setGrades(response);
  }, [getList]);

  const onChange = (grade: GradeItem) => {
    handleGrade(grade)
    setSelectedGrade(grade)
  }

  useEffect(() => {
    getGrade();
  }, [getGrade]);

  return(
    <div className="relative flex-1 max-w-[176px]">
      <Listbox value={selectedGrade} onChange={onChange}>
        <Listbox.Button className="relative flex cursor-default w-full	 items-center justify-between gap-x-1 rounded-lg border bg-white px-3.5 py-3 text-left text-sm">
          <span className="block truncate">{selectedGrade.name}</span>
          <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
        </Listbox.Button>
        <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {[allGrade, ...grades].map((val, classIdx) => (
            <Listbox.Option
              key={`${classIdx}`}
              as={Fragment}
              value={val}
            >
              {({ active }) => (
                <li
                  className={`relative flex items-center justify-between cursor-default select-none px-3.5 py-2.5 ${
                    active ? "bg-gray-50" : "text-gray-900"
                  }`}
                >
                  <span className="block truncate">{val.name}</span>
                  {val.id === selectedGrade.id && (
                    <FontAwesomeIcon
                      icon={icon({ name: "check" })}
                      color="#7F56D9"
                    />
                  )}
                </li>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  )
}

const allSort = {
  id: 0,
  name: "Terbaru",
};

type SortItem = {
  id: number;
  name: string;
  sort_by: string;
  order_by: "asc" | "desc";
};

const SORT_LIST: SortItem[] = [
  {
    id: 0,
    name: "Terbaru",
    sort_by: "created_at",
    order_by: "desc",
  },
  {
    id: 1,
    name: "Nama A-Z",
    sort_by: "student_name",
    order_by: "asc",
  },
  {
    id: 2,
    name: "Nama Z-A",
    sort_by: "student_name",
    order_by: "desc",
  },
  {
    id: 3,
    name: "Tipe A-Z",
    sort_by: "type",
    order_by: "asc",
  },
  {
    id: 4,
    name: "Tipe Z-A",
    sort_by: "type",
    order_by: "desc",
  },
  {
    id: 5,
    name: "Strata A-Z",
    sort_by: "grade",
    order_by: "asc",
  },
  {
    id: 6,
    name: "Strata Z-A",
    sort_by: "grade",
    order_by: "desc",
  },
  {
    id: 7,
    name: "Pengajar A-Z",
    sort_by: "teacher_name",
    order_by: "asc",
  },
  {
    id: 8,
    name: "Pengajar Z-A",
    sort_by: "teacher_name",
    order_by: "desc",
  },
];

const SortWidget = ({handleSort}: {handleSort: (sort: SortItem) => void}) => {
  const [sort, setSort] = useState(SORT_LIST[0]);

  const onChange = (sortItem: SortItem) => {
    setSort(sortItem)
    handleSort(sortItem)
  }
  return(
    <div className="relative flex-1 max-w-[176px]">
      <Listbox value={sort} onChange={onChange}>
        <Listbox.Button className="relative flex cursor-default w-full items-center justify-between gap-x-1 rounded-lg border bg-white px-3.5 py-3 text-left text-sm">
          <span className="block truncate">{sort.name}</span>
          <FontAwesomeIcon icon={icon({ name: "sort" })} />
        </Listbox.Button>
        <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {SORT_LIST.map((val, classIdx) => (
            <Listbox.Option
              key={`${classIdx}`}
              as={Fragment}
              value={val}
            >
              {({ active }) => (
                <li
                  className={`relative flex items-center justify-between cursor-default select-none px-3.5 py-2.5 ${
                    active ? "bg-gray-50" : "text-gray-900"
                  }`}
                >
                  <span className="block truncate">{val.name}</span>
                  {val.id === sort.id && (
                    <FontAwesomeIcon
                      icon={icon({ name: "check" })}
                      color="#7F56D9"
                    />
                  )}
                </li>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  )
}

export default SessionList;

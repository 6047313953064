import {memo, FC, useState, useEffect, useCallback} from 'react';
import Modal from 'components/Modal';
import {Button, Input, Radio} from 'components';
import List from './List';

import useBrowse from 'services/useBrowse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useDebounce } from 'utilities';
import Breadcrumbs from 'components/Breadcrumbs';
import { Test } from 'views/Silabus/types';

type Props = {
  show: boolean
  title: string
  onClose: () => void
  onSubmit: (data: BrowseItem[]) => void
  directoryId: number
  directories: string[]
  selectedQuestion: Test[]
  directory?: ExamItems
}

type Question = BrowseItem & {
  totalEssay: number
  totalMultiple: number
}

type QuestionType = 'multiple' | 'essay';

const BrowseQuestion: FC<Props> = (props) => {
  const {show, title, onClose, onSubmit, directoryId, selectedQuestion, directories, directory} = props;
  const {getList} = useBrowse('question');

  const [data, setData] = useState<BrowseItem[]>([]);
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState<BrowseItem[]>([]);
  const [tab, setTab] = useState(1);
  const [question, setQuestion] = useState<Question | null>(null);
  const [testCount, setTestCount] = useState<'part' | 'all'>('all');

  const debounceSearch = useDebounce(search);

  useEffect(() => {
    if (show) {
      setTab(1);
    }
  }, [show]);

  useEffect(() => {
    const questions = directory?.questions.map((val) => ({
      id: val.id,
      name: val.name,
      grade_name: val.grade_name,
      n_essay: val.n_essay,
      n_multiple_choice: val.n_multiple_choice,
      question_package_id: val.id,
      difficulty: val.difficulty,
      test_count: 'all',
    }));

    setSelected(questions as unknown as BrowseItem[]);
  }, [directory?.questions, selectedQuestion]);

  useEffect(() => {
    if (debounceSearch.length >= 3 || !debounceSearch.length) {
      getList({
        directory_id: directoryId,
        keyword: debounceSearch,
        per_page: 50
      })
      .then((response) => setData(response?.data || []))
      .catch((err) => console.log('Err', err));
    }
  }, [debounceSearch, getList, directoryId]);

  const handleClose = () => {
    if (tab === 2) {
      setTab(1);
    } else {
      onClose();
    }
    setTestCount('all');
  };

  const handleSubmit = () => {
    if (selected) {
      onSubmit(selected);
    }
  };

  const handleSelect = useCallback((item: BrowseItem) => {
    const newQuestion: Question = {
      ...item,
      totalEssay: item.n_essay,
      totalMultiple: item.n_multiple_choice
    }

    const newSelected = [...selected, item];
    setQuestion(newQuestion);
    setSelected(newSelected);
    // setTab(2);
  }, [selected]);

  const updateTestCount = (type: QuestionType, value: string) => {
    const isEssay = type === 'essay';

    if (question) {
      let newQuestion = question;
      const {totalEssay, totalMultiple} = newQuestion

      if (isEssay) {
        newQuestion = {
          ...newQuestion,
          n_essay: Number(value) > totalEssay ? totalEssay : Number(value)
        }
      } else {
        newQuestion = {
          ...newQuestion,
          n_multiple_choice: Number(value) > totalMultiple ? totalMultiple : Number(value)
        }
      }

      setQuestion(newQuestion);
    }
  };

  return (
    <Modal
      show={show}
      title={tab === 1 ? title : 'Tentukan jumlah soal'}
      onClose={handleClose}
      customFooter={(
        <div className="float-right flex gap-x-2">
          <Button.Cancel onButtonClick={handleClose} />
          <Button color='primary' onButtonClick={handleSubmit}>
              Pilih soal
          </Button>
        </div>
      )}
    >
      {tab === 1 ? (
        <>
          <div className='bg-white px-4 py-[0.625rem] rounded-xl flex gap-x-2 items-center border w-full mb-4'>
            <FontAwesomeIcon icon={icon({name: 'search'})} />
            <input
              className='w-full'
              placeholder='Search...'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {Boolean(search.length) && (
              <FontAwesomeIcon
                icon={icon({name: 'close'})}
                onClick={() => setSearch('')}
                className='cursor-pointer'
              />
            )}
          </div>
          <p className="text-sm font-bold">Direktori</p>
          <Breadcrumbs crumbs={directories} />
          <List
            initialData={data}
            onSelect={(item) => handleSelect(item)}
            selected={selected}
            getList={getList}
          />
        </>
      ) : (
        <section>
          <p className="font-bold text-sm mb-2">Detail soal</p>
          <div className="grid grid-flow-col auto-cols-max gap-x-2 mb-8">
            <div className="grid gap-y-2 items-center">
              <p className="text-sm flex justify-between gap-x-2">
                Nama soal
                <span>:</span>
              </p>
              <p className="text-sm flex justify-between gap-x-2">
                Pilihan ganda
                <span>:</span>
              </p>
              <p className="text-sm flex justify-between gap-x-2">
                Essay
                <span>:</span>
              </p>
            </div>
            <div className="grid gap-y-2 items-center">
              <p className="font-bold text-sm">
                {question?.name}
              </p>
              <p className="font-bold text-sm">
                {`${question?.totalMultiple} soal`}
              </p>
              <p className="font-bold text-sm">
                {`${question?.totalEssay} soal`}
              </p>
            </div>
          </div>
          <p className='mb-3'>Masukkan jumlah soal yang ingin anda berikan kepada siswa.</p>
          <div className="flex flex-col gap-3">
            <Radio
              readOnly
              label="Pilih semua soal"
              checked={Boolean(testCount === "all")}
              onClick={() => setTestCount("all")}
            />

            <Radio
              readOnly
              label="Atur jumlah soal"
              checked={Boolean(testCount === "part")}
              onClick={() => setTestCount("part")}
            />
          </div>
          {testCount === 'part' && (
            <div className="grid grid-flow-col grid-cols-2 gap-x-8 mt-3 ml-6">
              <Input
                value={question?.n_multiple_choice || ''}
                onChange={(e) => updateTestCount('multiple', e.target.value)}
                type="number"
                label="Pilihan ganda"
                min={0}
                max={question?.totalMultiple}
                disabled={question?.totalMultiple === 0}
              />
              <Input
                value={question?.n_essay || ''}
                onChange={(e) => updateTestCount('essay', e.target.value)}
                type="number"
                label="Essay"
                min={0}
                max={question?.totalEssay}
                disabled={question?.totalEssay === 0}
              />
            </div>
          )}
            </section>
          )}
    </Modal>
  )
}

export default memo(BrowseQuestion);

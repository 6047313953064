import useDashboard from "services/useDashboard";
import Filter from "./fragment/Filter";
import Summary from "./fragment/Summary";
import TotalUser from "./fragment/TotalUser";
import Turnover from "./fragment/Turnover";
import { useCallback, useEffect, useState } from "react";
import { lastMonth, today } from "utilities";
import { DateValueType } from "react-tailwindcss-datepicker";
import Overview from "./fragment/Overview";

type Params = {
  start_date: string;
  end_date: string;
};

const Dashboard = () => {
  const {
    getBuyer,
    getRecapEngagement,
    getRecapStudent,
    getTurnover,
    getRecapTurnover,
    getStudentOverview
  } = useDashboard();

  const [recapStudent, setRecapStudent] = useState<RecapStudent | null>(null);
  const [recapEngagement, setRecapEngagement] =
    useState<RecapEngagement | null>(null);
  const [buyer, setBuyer] = useState<GraphBuyer | null>(null);
  const [turnover, setTurnover] = useState<GraphTurnover[]>([]);
  const [recapTurnover, setRecapTurnover] = useState<RecapTurnover | null>(
    null
  );
  const [studentOverview, setStudentOverview] = useState<StudentOverview | null>(null);
  const [dateRange, setDateRange] = useState<DateValueType>(null);

  const getRecap = useCallback(async () => {
    const respStudent = await getRecapStudent();

    setRecapStudent(respStudent);
    // eslint-disable-next-line
  }, []);

  const getEngagement = useCallback(async () => {
    const respEngagement = await getRecapEngagement();

    setRecapEngagement(respEngagement);
    // eslint-disable-next-line
  }, []);

  const getOverviewStudent = useCallback(async () => {
    const resp = await getStudentOverview();

    setStudentOverview(resp);
    // eslint-disable-next-line
  }, []);

  const getDataFilter = useCallback(async (params: Params) => {
    const respBuyer = await getBuyer(params);
    setBuyer(respBuyer);

    const respTurnover = await getTurnover(params);
    setTurnover(respTurnover);

    const respRecap = await getRecapTurnover(params);
    setRecapTurnover(respRecap);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const startDate = lastMonth(1);

    setDateRange({
      startDate,
      endDate: today,
    });

    getRecap();
    getEngagement();
    getDataFilter({
      start_date: startDate,
      end_date: today,
    });
    getOverviewStudent();
    // eslint-disable-next-line
  }, []);

  const handleRange = (value: DateValueType) => {
    if (value?.startDate && value.endDate) {
      getDataFilter({
        start_date: value.startDate.toString(),
        end_date: value.endDate.toString(),
      });
    }
  };

  return (
    <section className="space-y-5">
      <div className="flex items-center justify-between">
        <h3 className="text-2xl font-bold">Rangkuman</h3>
        <Filter dateRange={dateRange} onChange={handleRange} />
      </div>
      <Summary recapStudent={recapStudent} buyer={buyer} />
      <Overview studentOverview={studentOverview!} />
      <div className="grid grid-cols-[1fr,23rem] gap-x-5 overflow-x-auto">
        <Turnover turnover={recapTurnover} graphData={turnover} />
        <TotalUser data={recapEngagement} />
      </div>
    </section>
  );
};

export default Dashboard;

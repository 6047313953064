import { Fragment, useCallback, useEffect, useState } from "react";
import Table from "components/Table";
import useMassUpload from "services/useMassUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Badge, Spinner } from "components";
import FileDetail from "./fragment/Modal/Detail";
import UploadFile from "./fragment/Modal/Upload";
import { getFormatDate, useDebounce } from "utilities";
import {
  FILE_STATUS,
  FILE_TYPE,
  FileStatus,
  FileStatusOptions,
  FileType,
} from "constant";
import Breadcrumbs from "components/Breadcrumbs";
import { Listbox } from "@headlessui/react";

const columns = [
  {
    title: "Nama file",
    isSort: true,
    key: "file_name",
  },
  {
    title: "Tipe",
    isSort: true,
    key: "type",
  },
  {
    title: "Status",
    isSort: true,
    key: "status",
  },
  {
    title: "Data",
    isSort: true,
    key: "total_rows",
  },
  {
    title: "Data diproses",
    isSort: true,
    key: "processed_rows",
  },
  {
    title: "Waktu",
    isSort: true,
    key: "created_at",
  },
  {
    title: "",
  },
];

const crumbs = [
  {
    label: "Upload masal",
    id: 0,
  },
];

const defaultParams: MassUploadParams = {
  page: 1,
  sort_by: "created_at",
  sort_dir: "desc",
  keyword: "",
};
const fileTypeOption: FileType[] = [
  { label: "Semua - Tipe", value: "", description: "", sampleUrl: "" },
  ...FILE_TYPE,
];

const MassUpload = () => {
  const { getList } = useMassUpload();

  const [show, setShow] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [detail, setDetail] = useState<FileInfo | null>(null);
  const [data, setData] = useState<FileInfo[]>([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState<MassUploadParams["sort_by"]>("id");
  const [sortDir, setSortDir] = useState<MassUploadParams["sort_dir"]>("asc");
  const [total, setTotal] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState<FileType>(
    fileTypeOption[0]
  );
  const [selectedFileStatus, setSelectedFileStatus] = useState<FileStatus>(
    FileStatusOptions[0]
  );

  const debounceSearch = useDebounce(search);

  const getData = useCallback(async (params?: MassUploadParams) => {
    setLoading(true);
    const response = await getList(params);

    setCurrentPage(params?.page || currentPage);
    setLimit(params?.per_page || currentLimit);
    setTotal(response?.data?.total || 0);
    setLastPage(response?.data?.last_page || lastPage);
    setData(response?.data?.data || []);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (debounceSearch.length >= 3) {
      getData({
        page: 1,
        sort_by: "id",
        sort_dir: "asc",
        keyword: debounceSearch,
      });
    }

    if (!debounceSearch.length) {
      getData(defaultParams);
    }
  }, [debounceSearch]);

  const handleModal = (item: FileInfo) => {
    setDetail(item);
    setShow(true);
  };

  const clearSearch = async () => {
    setSearch("");
    await getData(defaultParams);
  };

  const handleSort = async (params: string) => {
    let direction: MassUploadParams["sort_dir"] =
      sortDir === "asc" ? "desc" : "asc";

    if (params !== sortBy) {
      direction = "asc";
    }

    setSortBy(params as MassUploadParams["sort_by"]);
    setSortDir(direction);
    await getData({
      page: 1,
      sort_by: params as MassUploadParams["sort_by"],
      sort_dir: direction,
    });
  };

  const handlePagination = async (ctrl: "next" | "prev") => {
    const isNext = ctrl === "next";
    const page = isNext ? currentPage + 1 : currentPage - 1;

    if (page > 0 && page <= lastPage) {
      await getData({
        page,
        sort_by: sortBy,
        sort_dir: sortDir,
      });
    }
  };

  const handleLimit = async (limit: string) => {
    await getData({
      page: 1,
      per_page: Number(limit),
      sort_by: "id",
      sort_dir: "asc",
    });
  };

  const handleDownload = (item: FileInfo) => {
    if (item.processed_file_location) {
      window.open(item.processed_file_location, "_blank");
    }
  };

  const handleClose = () => {
    setShowUpload(false);
    getData(defaultParams);
  };

  const handleChangeFileType = (value: FileType) => {
    setSelectedFileType(value);
    getData({
      page: 1,
      per_page: 10,
      sort_by: "id",
      sort_dir: "asc",
      type: value.value,
      status: selectedFileStatus.value,
    });
  };

  const handleChangeFileStatus = (value: FileStatus) => {
    setSelectedFileStatus(value);
    getData({
      page: 1,
      per_page: 10,
      sort_by: "id",
      sort_dir: "asc",
      type: selectedFileType.value,
      status: value.value,
    });
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} onClick={() => {}} />
      <div className="flex flex-wrap items-center justify-between gap-x-5 gap-y-3 mb-5">
      <div className="bg-white border rounded-lg px-4 py-2.5 flex items-center w-80">
          <FontAwesomeIcon icon={icon({ name: "search" })} />
          <input
            type="search"
            placeholder="Cari file..."
            className="ml-2 text-sm w-full"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div className="relative flex-1 w-36">
          <Listbox value={selectedFileType} onChange={handleChangeFileType}>
            <Listbox.Button className="relative flex w-full cursor-default items-center justify-between gap-x-1 rounded-lg border bg-white px-3.5 py-2.5 text-left text-sm">
              <span className="block truncate">{selectedFileType.label}</span>
              <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
            </Listbox.Button>
            <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {fileTypeOption.map((val, classIdx) => (
                <Listbox.Option
                  key={`${classIdx}-${val.label}`}
                  as={Fragment}
                  value={val}
                >
                  {({ active }) => (
                    <li
                      className={`relative flex items-center justify-between cursor-default select-none px-3.5 py-2.5 ${
                        active ? "bg-gray-50" : "text-gray-900"
                      }`}
                    >
                      <span className="block truncate">{val.label}</span>
                      {val.label === selectedFileType.label && (
                        <FontAwesomeIcon
                          icon={icon({ name: "check" })}
                          color="#7F56D9"
                        />
                      )}
                    </li>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>
        </div>
        <div className="relative flex-1 w-36">
          <Listbox value={selectedFileStatus} onChange={handleChangeFileStatus}>
            <Listbox.Button className="relative flex w-full cursor-default items-center justify-between gap-x-1 rounded-lg border bg-white px-3.5 py-2.5 text-left text-sm">
              <span className="block truncate">{selectedFileStatus.label}</span>
              <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
            </Listbox.Button>
            <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {FileStatusOptions.map((val, classIdx) => (
                <Listbox.Option
                  key={`${classIdx}-${val.label}`}
                  as={Fragment}
                  value={val}
                >
                  {({ active }) => (
                    <li
                      className={`relative flex items-center justify-between cursor-default select-none px-3.5 py-2.5 ${
                        active ? "bg-gray-50" : "text-gray-900"
                      }`}
                    >
                      <span className="block truncate">{val.label}</span>
                      {val.label === selectedFileStatus.label && (
                        <FontAwesomeIcon
                          icon={icon({ name: "check" })}
                          color="#7F56D9"
                        />
                      )}
                    </li>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>
        </div>
        <button
          onClick={() => setShowUpload(true)}
          className="bg-primary rounded-xl py-[0.625rem] pl-4 pr-5 flex items-center"
        >
          <FontAwesomeIcon icon={icon({ name: "plus" })} size="lg" />
          <span className="ml-[0.625rem] text-sm">Tambah</span>
        </button>
      </div>
      <Table
        data={data}
        columns={columns}
        currentLimit={currentLimit}
        currentPage={currentPage}
        total={total}
        handlePagination={handlePagination}
        handleLimit={handleLimit}
        handleSort={handleSort}
      >
        <>
          {data.length ? (
            data.map((item, index) => {
              const badgeColor = FILE_STATUS[item.status].color;

              return (
                <tr key={item.id} className="border-b">
                  <td className="p-4">
                    <div className="flex gap-x-2 items-baseline">
                      <FontAwesomeIcon
                        icon={icon({ name: "file-lines", style: "regular" })}
                      />
                      <span className="cursor-default text-start">
                        {item.file_name}
                      </span>
                    </div>
                  </td>
                  <td className="p-4">
                    <span>
                      {FILE_TYPE.filter((v) => v.value === item.type)[0]?.label}
                    </span>
                  </td>
                  <td className="p-4">
                    <Badge className="rounded-[6.25rem]" color={badgeColor}>
                      {FILE_STATUS[item.status].label}
                    </Badge>
                  </td>
                  <td className="p-4">
                    <span>{item.total_rows}</span>
                  </td>
                  <td className="p-4">
                    <span>{item.processed_rows}</span>
                  </td>
                  <td className="p-4">
                    <span>
                      {getFormatDate(item.created_at, "DD MMM YYYY HH:mm")}
                    </span>
                  </td>
                  <td className="p-4 text-end">
                    <FontAwesomeIcon
                      icon={icon({ name: "info" })}
                      onClick={() => handleModal(item)}
                      className="cursor-pointer"
                      title="info"
                    />
                    <FontAwesomeIcon
                      icon={icon({ name: "cloud-download" })}
                      onClick={() => handleDownload(item)}
                      className="cursor-pointer ml-3"
                      title="download"
                    />
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={columns.length}
                className="italic opacity-40 text-center pt-3"
              >
                {loading ? <Spinner /> : <span>Tidak ada data.</span>}
              </td>
            </tr>
          )}
        </>
      </Table>
      <FileDetail show={show} data={detail} onClose={() => setShow(false)} />
      <UploadFile show={showUpload} onClose={handleClose} />
    </>
  );
};

export default MassUpload;

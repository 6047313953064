import { Field, FormikErrors } from "formik";

type DropdownFieldProps = {
  name: any;
  label: string;
  options: { id: number; name: string }[];
  errors: FormikErrors<string>;
};
function DropdownField({
  name,
  label,
  options,
  errors,
}: DropdownFieldProps): JSX.Element {
  return (
    <div className="flex flex-col w-full mt-8 relative justify-between flex-wrap">
      <h1 className="text-[#2C3131] font-bold items-center after:content-['*'] after:font-medium after:text-rose-400 after:ml-1">
        {label}
      </h1>
      <Field
        as="select"
        name={name}
        className={
          errors[name]
            ? "outline-none border-b border-rose-400 placeholder:text-rose-400 mt-3 pb-2 w-full"
            : "outline-none border-b border-[#9DA4B3] placeholder:text-[#9DA4B3] mt-3 pb-2 w-full"
        }
      >
        <option value="">{label}</option>
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </Field>
      {errors[name] && <p className="text-[#F04438] mt-0.5">{errors[name]}</p>}
    </div>
  );
}

export default DropdownField;

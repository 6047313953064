import { API_URL } from "constant";
import { services } from "./services";
import { AxiosError } from "axios";

type Params = {
  per_page: number;
  search: string;
  sort_by: "name" | "phone" | "email" | "grade";
  order: "asc" | "desc";
  page: number;
};

type List = PaginationResponse & {
  data: StaffData[];
  unauthorized?: boolean;
};

type Errors = {
  errors: {
    [key: string]: [string];
  };
  message: string;
};

const useStaff = () => {
  const getList = async (params?: Params): Promise<List> => {
    try {
      const {
        per_page = 10,
        search = "",
        sort_by = "name",
        order = "asc",
        page = 1,
      } = params ?? {};
      const resp = await services.get(API_URL.staff, {
        params: {
          per_page,
          sort_by,
          search,
          order,
          page,
        },
      });

      return resp.data?.data;
    } catch (error) {
      return {
        current_page: 1,
        data: [],
        from: 1,
        last_page: 1,
        per_page: params?.per_page ?? 10,
        to: 1,
        total: 0,
        unauthorized: true,
      };
    }
  };

  const add = async (params: StaffPayload) => {
    try {
      await services.post(API_URL.staff, params);
    } catch (error) {
      const err = error as AxiosError;
      const { errors, message } =
        (err.response?.data as Errors) || ({} as Errors);
      const errMsg = Object.values(errors)[0]?.[0] || message;
      throw errMsg;
    }
  };

  const edit = async (id: string, params: StaffPayload) => {
    try {
      await services.put(`${API_URL.staff}/${id}`, params);
    } catch (error) {
      const err = error as AxiosError;
      const { errors, message } =
        (err.response?.data as Errors) || ({} as Errors);
      const errMsg = Object.values(errors)[0]?.[0] || message;
      throw errMsg;
    }
  };

  const getDetail = async (id: string): Promise<StaffData> => {
    try {
      const response = await services.get(`${API_URL.staff}/${id}`);

      return response.data?.data;
    } catch (error) {
      throw error;
    }
  };

  const deleteStaff = async (id: string) => {
    try {
      await services.delete(`${API_URL.staff}/${id}`);
    } catch (error) {
      throw error;
    }
  };

  return {
    getList,
    add,
    deleteStaff,
    getDetail,
    edit,
  };
};

export default useStaff;

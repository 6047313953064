import {useCallback} from 'react';
import {services} from 'services';
import {API_URL} from 'constant';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';

type LoginParams = {
  email: string
  password: string
}

const useAuth = () => {
  const login = useCallback(async (params: LoginParams) => {
    try {
      const response = await services.post(`${API_URL.auth}/login`, params);
      
      return response.data?.data;
    } catch (error) {
      const err = error as AxiosError;

      throw err.response?.data;
    }
  }, []);

  const logout = async () => {
    try {
      await services.get(`${API_URL.authLogout}`);

      Cookies.remove('SESSION');
      sessionStorage.removeItem('SESSION');
    } catch (error) {
      const err = error as AxiosError;

      throw err.response?.data;
    }
  };

  const checkSession = () => {
    const cookieSession = Cookies.get('SESSION');
    const session = sessionStorage.getItem('SESSION');
    const hasSession = cookieSession || session;

    return Boolean(hasSession)
  };

  return {
    login,
    checkSession,
    logout
  }
};

export default useAuth;

import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import { Badge } from "components";
import { Spinner } from "components/Spinner";
import { QUESTION_DIFFICULTY } from "constant";
import { FC, memo, useCallback, useEffect, useState } from "react";
import useBrowse from "services/useBrowse";
import { useDebounce } from "utilities";

type Props = {
  directoryId?: number;
  initialData?: BrowseItem[];
  indent?: number;
  onSelect: (item: BrowseItem) => void;
  selected?: BrowseItem[];
  useDirectory?: boolean;
  isLastPage?: boolean;
  onLoadMore?: () => Promise<void>;
  isLoadMore?: boolean;
  keyword?: string;
};

const List: FC<Props> = (props) => {
  const {
    directoryId,
    initialData = [],
    isLastPage,
    keyword,
    useDirectory,
  } = props;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<BrowseItem[]>(initialData);
  const debounceSearch = useDebounce("");
  const { getList } = useBrowse("question");
  const [isInLastPage, setIsInLastPage] = useState(isLastPage);

  const [page, setPage] = useState(1);
  const onLoadMore = useCallback(async () => {
    try {
      const response = await getList({
        directory_id: directoryId,
        page: page + 1,
        keyword,
      });
      const { current_page, last_page } = response;
      const isLast = last_page <= current_page;
      setPage(current_page);
      setIsInLastPage(isLast);
      setData([...data, ...(response?.data || [])]);
    } catch (error) {
      console.error("Error load more");
    }
  }, [directoryId, page, data, keyword, getList]);

  useEffect(() => {
    if (debounceSearch.length >= 3) {
      getList({
        directory_id: directoryId,
        keyword: debounceSearch,
        per_page: 10,
      })
        .then((response) => {
          setData(response?.data || []);
          setIsInLastPage(response.current_page === response.last_page);
        })
        .catch((err) => console.log("Err", err));
    }
  }, [debounceSearch, getList, directoryId]);

  useEffect(() => {
    if (initialData.length) {
      setData(initialData);
    }
  }, [initialData]);

  useEffect(() => {
    if (directoryId) {
      setLoading(true);
      getList({
        directory_id: directoryId,
        per_page: 10,
      })
        .then((response) => {
          setData(response?.data || []);
          setIsInLastPage(response.current_page === response.last_page);
        })
        .catch((err) => console.log("Err", err))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line
  }, [directoryId]);
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <RenderItem
          data={data}
          {...props}
          isLastPage={isInLastPage}
          onLoadMore={onLoadMore}
        />
      )}
    </>
  );
};

const RenderItem: FC<Props & { data: BrowseItem[] }> = memo((props) => {
  const {
    data,
    indent = 1,
    onSelect,
    selected = [],
    isLastPage,
    onLoadMore,
    isLoadMore,
    useDirectory,
  } = props;

  return data.length ? (
    <>
      {data.map((item) => {
        const isDirectory = item.type === "directory";
        const isSelected = Boolean(selected.find((val) => val.id === item.id));
        let paddingLeft = 32 * (indent - 1);
        paddingLeft = paddingLeft ? paddingLeft : 4;

        return isDirectory ? (
          <Disclosure key={`directory-${item.id}`}>
            {({ open }) => {
              const itemIcon = open
                ? icon({ name: "chevron-down" })
                : icon({ name: "chevron-right" });

              return (
                <>
                  <Disclosure.Button
                    as="div"
                    className={`
                          flex 
                          w-full 
                          items-center 
                          gap-x-4 
                          pr-4 
                          py-2 
                          text-left 
                          font-medium
                          border-b
                        `}
                    style={{
                      paddingLeft,
                    }}
                  >
                    <FontAwesomeIcon icon={itemIcon} width={16} />
                    <div className="flex items-center gap-x-3 w-full">
                      <FontAwesomeIcon icon={icon({ name: "folder" })} />
                      <span>{item.name}</span>
                    </div>
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <List
                      directoryId={item.id}
                      indent={indent + 1}
                      onSelect={onSelect}
                      selected={selected}
                      useDirectory
                      isLastPage
                    />
                  </Disclosure.Panel>
                </>
              );
            }}
          </Disclosure>
        ) : (
          <label
            key={`question-${item.id}`}
            className={`flex justify-between w-full gap-x-4 py-2 px-1 cursor-pointer hover:bg-yellow-100 border-b ${
              isSelected ? "bg-yellow-100" : "bg-white"
            }`}
          >
            <div className="flex w-full items-center gap-x-4">
              <input
                type="checkbox"
                id={`question-${item.id}`}
                className="checkbox checked:bg-yellow-400 checked:border-primary text-gray-500"
                onChange={() => onSelect(item)}
                checked={isSelected}
              />
              <div className="flex w-full items-center gap-x-2">
                <FontAwesomeIcon icon={icon({ name: "file-lines" })} />
                <p>{item.name}</p>
              </div>
            </div>
            <div className="flex items-center gap-x-2">
              <Badge color="blue">{`${item.n_multiple_choice} PG`}</Badge>
              <Badge color="success">{`${item.n_essay} essay`}</Badge>
              <Badge color="gray">
                {`${QUESTION_DIFFICULTY[Number(item.difficulty)]}`}
              </Badge>
            </div>
          </label>
        );
      })}
      {!isLastPage && useDirectory && (
        <button
          className="w-full py-2 border-b hover:bg-yellow-100"
          onClick={onLoadMore}
          disabled={isLoadMore}
        >
          {isLoadMore || !Boolean(data.length) ? (
            <Spinner />
          ) : (
            "Lihat lebih banyak"
          )}
        </button>
      )}
    </>
  ) : (
    <p className="text-center" />
  );
});

export default memo(List);

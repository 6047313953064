import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Breadcrumbs from 'components/Breadcrumbs';
import {FC, memo,} from 'react';
import {Disclosure} from '@headlessui/react';
import { ExamKind } from 'views/Silabus/Tambah';
import Questions from './Questions';

type Props = {
  packageIndex: ExamKind,
  isPreview?: boolean
  directories?: ExamItemsResponse[]
}


const Exam: FC<Props> = ({packageIndex,directories}) => {


  return (
    <>
      <section className="grid gap-y-5 mb-20">
        {directories && directories.length ? (
          directories.map((directory, index) => (
            <div
              key={`${packageIndex}-${directory.directoryId}-${index}-${directory.questions.length}`}
              className="bg-white rounded-xl border"
            >
              <Disclosure defaultOpen>
                {({ open }) => {
                  const itemIcon = open
                    ? icon({ name: "chevron-down" })
                    : icon({ name: "chevron-right" });

                  return (
                    <>
                      <div
                        className={
                          "flex w-full justify-between items-center px-5 py-4"
                        }
                      >
                        <Disclosure.Button className="flex items-center gap-x-4 w-full">
                          <FontAwesomeIcon icon={itemIcon} width={16} />
                          <Breadcrumbs crumbs={["Soal", ...directory.path.split("/")]} />
                        </Disclosure.Button>
                      </div>

                      <Disclosure.Panel
                        className={"border-t px-5 py-4 grid gap-y-5"}
                      >
                        <Questions
                          data={directory.questions}
                        />
                      </Disclosure.Panel>
                    </>
                  );
                }}
              </Disclosure>
            </div>
          ))
        ) : (
          <p className="text-gray-400">Anda belum menambahkan ujian.</p>
        )}
      </section>
    </>
  );
};

export default memo(Exam);

// React
import { useEffect, useState } from "react";

// Third-Party Libraries
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Disclosure } from "@headlessui/react";

import useAuth from "services/useAuth";
import { MENUS } from "constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

export function Layout() {
  // Hooks
  const location = useLocation();
  const navigate = useNavigate();

  const { checkSession } = useAuth();

  const [title, setTitle] = useState("");
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    if (!checkSession()) {
      navigate("/login", {
        replace: true,
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { pathname } = location;
    let _title = "";

    switch (pathname) {
      case "/":
        _title = "Dashboard";
        break;
      case "/modul":
        _title = "Modul";
        break;
      case "/modul/tambah":
        _title = "Tambah modul baru";
        break;
      case "/modul/edit":
        _title = "Edit Modul";
        break;
      case "/silabus":
        _title = "Silabus";
        break;
      case "/silabus/tambah":
        _title = "Tambah silabus baru";
        break;
      case "/mass-upload":
        _title = "Upload masal";
        break;
      case "/program":
        _title = "Program";
        break;
      case "/program/tambah":
        _title = "Buat program baru";
        break;
      case "/bank-soal":
        _title = "Soal";
        break;
      case "/tanya-pr/penjualan":
        _title = "Penjualan";
        break;
      case "/withdrawal":
        _title = "Penarikan gaji";
        break;
      case "/poin":
        _title = "Poin";
        break;
      case "/bundling":
        _title = "Bundling";
        break;
      case "/bundling/tambah":
        _title = "Buat program bundling baru";
        break;
      default:
        break;
    }

    if (pathname.includes("/program/edit")) {
      _title = "Ubah program";
    } else if (pathname.includes("/program/detail")) {
      _title = "Program Detail";
    } else if (pathname.includes("/tanya-pr/pengajar")) {
      _title = "Pengajar";
    } else if (pathname.includes("/guru")) {
      _title = "Guru";
    } else if (pathname.includes("/siswa")) {
      _title = "Siswa";
    } else if (pathname.includes("/live-class")) {
      _title = "Live class";
    } else if (pathname.includes("/promo")) {
      _title = "Promo & Voucher";
    } else if (
      pathname.includes("/bundling/") &&
      pathname !== "/bundling/tambah"
    ) {
      _title = "Program detail";
    }

    setTitle(_title);
    setShowSidebar(false);
  }, [location]);

  const isActive = (url: string) => {
    const path = location.pathname;

    if (url === "/") {
      return path === url;
    }

    return path.includes(url);
  };

  const toggleSidebar = () => setShowSidebar((prev) => !prev);

  return (
    <div className="flex h-screen overflow-y-hidden max-lg:relative">
      <aside
        className={`z-10 h-full flex flex-col flex-shrink-0 overflow-y-auto w-64 border-r relative transition-[all] duration-300 max-lg:absolute ${
          showSidebar ? "max-lg:translate-x-0" : "max-lg:translate-x-[-16rem]"
        }`}
      >
        <div className="py-5 px-4 h-20 bg-white">
          <img src="/assets/logo.png" alt="logo" />
        </div>
        <ul className="pt-2 pb-14 px-4 bg-white h-full overflow-y-auto max-lg:px-0">
          {MENUS.dashboard.map((menu, index) => (
            <li
              key={index}
              className={`flex items-center gap-x-3 px-4 py-3 cursor-pointer ${
                isActive(menu.path) ? "bg-yellow-100" : "bg-white"
              }`}
              onClick={() => navigate(menu.path)}
            >
              <FontAwesomeIcon icon={menu.icon} width={20} />
              <span className="">{menu.label}</span>
            </li>
          ))}
          <p className="text-gray-600 px-4 py-2 ">MASTER DATA</p>
          {MENUS.master.map((menu, index) => (
            <li
              key={index}
              className={`flex items-center gap-x-3 px-4 py-3 cursor-pointer ${
                isActive(menu.path) ? "bg-yellow-100" : "bg-white"
              }`}
              onClick={() => navigate(menu.path)}
            >
              <FontAwesomeIcon icon={menu.icon} width={20} />
              <span className="">{menu.label}</span>
            </li>
          ))}
          <p className="text-gray-600 px-4 py-2 ">PRODUK UTAMA</p>
          {MENUS.main.map((menu, index) =>
            Boolean(menu.subpath) ? (
              <Disclosure
                as="li"
                key={index}
                className="grid items-center gap-x-3 px-4 py-3 cursor-pointer"
                onClick={() => {
                  if (Boolean(!menu.subpath)) {
                    navigate(menu.path);
                  }
                }}
              >
                {({ open }) => {
                  const itemIcon = open
                    ? icon({ name: "chevron-down" })
                    : icon({ name: "chevron-right" });

                  return (
                    <>
                      <Disclosure.Button as="div" className="flex items-center">
                        <div className="flex flex-1 gap-x-3 items-center">
                          <FontAwesomeIcon icon={menu.icon} width={20} />
                          <span className="">{menu.label}</span>
                        </div>
                        <FontAwesomeIcon icon={itemIcon} />
                      </Disclosure.Button>
                      <Disclosure.Panel as="ul" className="grid mt-2">
                        {menu.subpath?.map((submenu, index) => (
                          <li
                            key={`submenu-${menu.path}-${index}`}
                            onClick={() => navigate(submenu.path)}
                            className={`py-2 px-9 hover:bg-yellow-100 ${
                              isActive(submenu.path)
                                ? "bg-yellow-100"
                                : "bg-white"
                            }`}
                          >
                            <span>{submenu.label}</span>
                          </li>
                        ))}
                      </Disclosure.Panel>
                    </>
                  );
                }}
              </Disclosure>
            ) : (
              <li
                key={index}
                className={`flex items-center gap-x-3 px-4 py-3 cursor-pointer ${
                  isActive(menu.path) ? "bg-yellow-100" : "bg-white"
                }`}
                onClick={() => {
                  if (Boolean(!menu.subpath)) {
                    navigate(menu.path);
                  }
                }}
              >
                <FontAwesomeIcon icon={menu.icon} width={20} />
                <span className="">{menu.label}</span>
              </li>
            )
          )}
          <p className="text-gray-600 px-4 py-2 ">TRANSAKSI & PROMOSI</p>
          {MENUS.promotion.map((menu, index) => (
            <li
              key={index}
              className={`flex items-center gap-x-3 px-4 py-3 cursor-pointer ${
                isActive(menu.path) ? "bg-yellow-100" : "bg-white"
              }`}
              onClick={() => navigate(menu.path)}
            >
              <FontAwesomeIcon icon={menu.icon} width={20} />
              <span className="">{menu.label}</span>
            </li>
          ))}
          <p className="text-gray-600 px-4 py-2 ">KEGURUAN</p>
          {MENUS.teacher.map((menu, index) => (
            <li
              key={index}
              className={`flex items-center gap-x-3 px-4 py-3 cursor-pointer ${
                isActive(menu.path) ? "bg-yellow-100" : "bg-white"
              }`}
              onClick={() => navigate(menu.path)}
            >
              <FontAwesomeIcon icon={menu.icon} width={20} />
              <span className="">{menu.label}</span>
            </li>
          ))}
          <p className="text-gray-600 px-4 py-2 ">PENGGUNA</p>
          {MENUS.users.map((menu, index) => (
            <li
              key={index}
              className={`flex items-center gap-x-3 px-4 py-3 cursor-pointer ${
                isActive(menu.path) ? "bg-yellow-100" : "bg-white"
              }`}
              onClick={() => navigate(menu.path)}
            >
              <FontAwesomeIcon icon={menu.icon} width={20} />
              <span className="">{menu.label}</span>
            </li>
          ))}
          <p className="text-gray-600 px-4 py-2 ">ALAT</p>
          {MENUS.config.map((menu, index) => (
            <li
              key={index}
              className={`flex items-center gap-x-3 px-4 py-3 cursor-pointer ${
                isActive(menu.path) ? "bg-yellow-100" : "bg-white"
              }`}
              onClick={() => navigate(menu.path)}
            >
              <FontAwesomeIcon icon={menu.icon} width={20} />
              <span className="">{menu.label}</span>
            </li>
          ))}
        </ul>
        <Link
          to={"/logout"}
          className="absolute bottom-0 left-0 right-0 flex items-center gap-x-3 bg-gray-200 px-4 py-3"
        >
          <FontAwesomeIcon icon={icon({ name: "right-to-bracket" })} />
          <span className="">Log out</span>
        </Link>
      </aside>
      {showSidebar && (
        <div
          className="bg-gray-200 opacity-50 absolute w-full h-full z-[9]"
          role="button"
          onClick={toggleSidebar}
        />
      )}
      <div className="flex flex-col flex-1 w-full">
        <header className="py-4 px-8 border-b h-20 bg-white flex items-center">
          <FontAwesomeIcon
            icon={icon({ name: "bars" })}
            className="hidden max-lg:block max-lg:mr-4 cursor-pointer"
            onClick={toggleSidebar}
          />
          <p className="font-bold text-xl">{title}</p>
        </header>

        <main className="py-6 px-8 bg-gray-100 h-full overflow-y-auto relative">
          <Outlet />
        </main>
      </div>
    </div>
  );
}

import axios from "axios";
import config from "./config";
import Cookies from "js-cookie";

// ** jwtConfig <= Will be used by this service
const jwtConfig = config();

const getToken = (): string => {
  return sessionStorage.getItem("SESSION") ?? Cookies.get("SESSION") ?? "";
};

const currentUrl = (): string => {
  return "/login";
};


// ** Request Interceptor
const services = axios.create({
  baseURL: process.env.REACT_APP_BE_API_URL,
});
services.interceptors.request.use(
  (config) => {
    // ** Get token from localStorage
    const accessToken = getToken();

    // ** If token is present add it to request's Authorization Header
    if (accessToken) {
      // ** eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `${jwtConfig.tokenType} ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// ** Add request/response interceptor
services.interceptors.response.use(
  (response) => response,
  (error) => {
    // Jika tidak memiliki refresh token
    const { response } = error;
    // Jika memiliki refresh token

    // ** if (status === 401) {
    if (response && response.status === 401) {
      Cookies.remove(jwtConfig.storageTokenKeyName);
      sessionStorage.removeItem("SESSION");
      window.location.replace(currentUrl());
    }
    return Promise.reject(error);
  }
);

export { services };

import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, memo } from "react";
import { currencyConverter } from "utilities";

type Props = {
  summary: SchoolSummary | null;
};
const SchoolInfo: FC<Props> = ({ summary }) => {
  return (
    <>
      <div className="w-full bg-white rounded-xl">
        <div className="px-5 py-4 border-b flex items-center justify-between">
          <p className="font-bold">Informasi Sekolah</p>
        </div>
        <div className="p-6 space-y-4">
          <div className="flex flex-grow gap-x-10 justify">
            <div className="space-y-5 w-1/2">
              <div className="bg-yellow-100 rounded-xl px-5 py-4 space-y-2">
                <div className="flex items-center gap-x-2">
                  <FontAwesomeIcon
                    icon={icon({ name: "user-group" })}
                    className="bg-primary rounded-full p-1.5 w-3.5 h-3.5"
                  />
                  <span className="text-sm font-medium">Jumlah Siswa</span>
                </div>
                <span className="font-bold text-5xl">
                  {currencyConverter(summary?.total_students ?? 0, true)}
                </span>
              </div>
            </div>
            <div className="space-y-5 w-1/2">
              <div className="bg-purple-100 rounded-xl px-5 py-4 space-y-2">
                <div className="flex items-center gap-x-2">
                  <FontAwesomeIcon
                    icon={icon({ name: "building" })}
                    className="bg-purple-500 rounded-full p-1.5 w-3.5 h-3.5"
                  />
                  <span className="text-sm font-medium">Jumlah Kelas</span>
                </div>
                <span className="font-bold text-5xl">
                  {currencyConverter(summary?.total_classes ?? 0, true)}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-x-10 justify">
            <div className="space-y-5 w-1/2">
              <div className="bg-red-100 rounded-xl px-5 py-4 space-y-2">
                <div className="flex items-center gap-x-2">
                  <FontAwesomeIcon
                    icon={icon({ name: "play" })}
                    className="bg-red-500 rounded-full p-1.5 w-3.5 h-3.5"
                  />
                  <span className="text-sm font-medium">
                    Aktifitas Materi
                  </span>
                </div>
                <span className="font-bold text-5xl">
                  {currencyConverter(summary?.total_views_materi ?? 0, true)}
                </span>
              </div>
            </div>

            <div className="space-y-5 w-1/2">
              <div className="bg-blue-100 rounded-xl px-5 py-4 space-y-2">
                <div className="flex items-center gap-x-2">
                  <FontAwesomeIcon
                    icon={icon({ name: "clipboard-check" })}
                    className="bg-blue-500 rounded-full p-1.5 w-3.5 h-3.5"
                  />
                  <span className="text-sm font-medium">
                    Aktifitas soal
                  </span>
                </div>
                <span className="font-bold text-5xl">
                  {currencyConverter(summary?.total_answer_question ?? 0, true)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(SchoolInfo);

import {memo, FC, useState, useEffect} from 'react';
import Modal from 'components/Modal';
import {Button} from 'components';
import List from './List';

import useBrowse from 'services/useBrowse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useDebounce } from 'utilities';
import Breadcrumbs from 'components/Breadcrumbs';

type Props = {
  show: boolean
  title: string
  onClose: () => void
  onSubmit: (data: BrowseItem[]) => void
  directoryId: number
  directories: string[]
  checkedModules: {
    module_id: number
    name: string
  }[]
}

const BrowseModule: FC<Props> = (props) => {
  const {show, title, onClose, onSubmit, directoryId, checkedModules, directories} = props;
  const {getList} = useBrowse('module');

  const [data, setData] = useState<BrowseItem[]>([]);
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState<BrowseItem[]>([]);

  const debounceSearch = useDebounce(search);

  useEffect(() => {
    const modules = checkedModules.map((val) => ({
      id: val.module_id,
      name: val.name
    }));

    setSelected(modules as BrowseItem[]);
  }, [checkedModules]);

  useEffect(() => {
    if (debounceSearch.length >= 3 || !debounceSearch.length) {
      getList({
        directory_id: directoryId,
        keyword: debounceSearch,
        per_page: 200
      })
      .then((response) => setData(response?.data || []))
      .catch((err) => console.log('Err', err));
    }
  }, [debounceSearch, getList, directoryId]);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    if (selected) {
      onSubmit(selected);
    }
  };

  return (
    <Modal
      show={show}
      title={title}
      onClose={handleClose}
      customFooter={(
        <div className="float-right flex gap-x-2">
          <Button.Cancel onButtonClick={handleClose} />
          <Button color='primary' onButtonClick={handleSubmit}>
            {title}
          </Button>
        </div>
      )}
    >
      <div className='bg-white px-4 py-[0.625rem] rounded-xl flex gap-x-2 items-center border w-full mb-4'>
        <FontAwesomeIcon icon={icon({name: 'search'})} />
        <input
          className='w-full'
          placeholder='Search...'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {Boolean(search.length) && (
          <FontAwesomeIcon
            icon={icon({name: 'close'})}
            onClick={() => setSearch('')}
            className='cursor-pointer'
          />
        )}
      </div>
      <p className="text-sm font-bold">Direktori</p>
      <Breadcrumbs crumbs={directories} />
      <List
        initialData={data}
        onSelect={(item) => setSelected(item)}
        selected={selected}
        getList={getList}
      />
    </Modal>
  )
}

export default memo(BrowseModule);

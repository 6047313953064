import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, InputNumber } from "components";
import { PROGRAM_TYPE } from "constant";
import { FC, memo, useEffect, useMemo, useState } from "react";
import { currencyConverter, getFormatDate, getProgramColor } from "utilities";
import { useFormContext, Controller } from "react-hook-form";
import ButtonDropdown from "components/dropdowns/ButtonDropdown";
import ModalProgram from "./Modal/Program";
import AskHomeworks from "./Modal/AskHomeworks";

type Props = {
  isAdd?: boolean;
  canEdit?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
};

const ProgramsAndPrice: FC<Props> = ({ isAdd, canEdit, onCancel, onSave }) => {
  const { watch, setValue,getValues, control } = useFormContext<BundleItem>();
  const { detail, total_price = 0, bundle_price = 0 } = watch();
  const { programs = [], ask_homework_packets: askHomework = [] } = detail;

  const [showModal, setShowModal] = useState(false);
  const [showAskhomework, setShowAskhomework] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const canSave = useMemo(() => {
    const bundlePrice = Number(bundle_price);
    return Boolean(total_price && bundlePrice);
  }, [total_price, bundle_price]);

  useEffect(() => {
    const totalPrograms = programs.reduce((prev, current) => {
      return prev + Number(current.price);
    }, 0);
    const totalHomework = askHomework.reduce((prev, current) => {
      return prev + Number(current.price) * current.qty;
    }, 0);

    setValue("total_price", String(totalPrograms + totalHomework));
  }, [programs, askHomework, setValue]);

  const toggleModal = () => setShowModal(!showModal);
  const toggleAskhomework = () => setShowAskhomework(!showAskhomework);

  const handleAddPrograms = (items: ProgramItem[]) => {
    const newItems = items as unknown as BundleItem["detail"]["programs"];
    setValue("detail.programs", newItems);
  };

  const handleDeleteProgram = (id: number) => {
    const newItems = programs.filter((val) => val.id !== id);
    setValue("detail.programs", newItems);
  };

  const handleUrutanProgram = (id: number, urutan: number) => {
    let newItems = getValues("detail_urutan");
    if(newItems && newItems.length > 0) {
      newItems = newItems.map((val) => {
      if (val.program_id === id) {
        val.view_priority = urutan;
      }
      return val;
    })
      
    } else {
      newItems = programs.map((val) => {
        const urutanProgram = {
          program_id: val.id,
          view_priority: val.view_priority ?? 0,
        }
        if (val.id === id) {
          urutanProgram.view_priority = urutan;
        }
        return urutanProgram;
      });
    }
    
    setValue("detail_urutan", newItems);
  }

  const handleAddHomework = (items: ProgramItem[]) => {
    const newItems =
      items as unknown as BundleItem["detail"]["ask_homework_packets"];
    const newHomework = newItems.map((val) => ({
      ...val,
      qty: val.qty ?? 1,
    }));
    setValue("detail.ask_homework_packets", newHomework);
  };

  const handleDeleteHomework = (id: number) => {
    const newItems = askHomework.filter((val) => val.id !== id);
    setValue("detail.ask_homework_packets", newItems);
  };

  const updateAskHomework = (id: number, qty: number) => {
    const newItems = askHomework.map((val) => {
      if (val.id === id) {
        val.qty = qty;
      }
      return val;
    });
    setValue("detail.ask_homework_packets", newItems);
  };

  const handleCancel = () => {
    setIsEdit(false);
    onCancel?.();
  };

  const handleSave = () => {
    if (canSave) {
      setIsEdit(false);
      onSave?.();
    }
  };

  return (
    <>
      <div className="bg-white rounded-xl">
        <div className="px-5 py-4 border-b flex items-center justify-between">
          <p className="font-bold">Daftar program</p>
          {canEdit &&
            (isEdit ? (
              <div className="space-x-3">
                <FontAwesomeIcon
                  icon={icon({ name: "xmark" })}
                  color="#F04438"
                  onClick={handleCancel}
                  className="cursor-pointer"
                />
                <FontAwesomeIcon
                  icon={icon({ name: "floppy-disk", style: "regular" })}
                  color={canSave ? "#17B26A" : "grey"}
                  onClick={handleSave}
                  className={`${
                    canSave ? "cursor-pointer" : "cursor-not-allowed"
                  }`}
                />
              </div>
            ) : (
              <FontAwesomeIcon
                icon={icon({ name: "pen-to-square", style: "regular" })}
                color="#B5A300"
                onClick={() => setIsEdit(true)}
              />
            ))}
        </div>
        <div className="p-5 space-y-5">
          {programs.length ? (
            programs.map((val) => (
              <div
                key={`program-${val.id}`}
                className="rounded-lg bg-gray-50 px-4 py-2 grid grid-cols-[7.25rem,3fr,5rem,11rem,1fr] gap-5 items-center"
              >
                <img
                  src={val.thumbnail_path}
                  alt={val.title}
                  className="rounded w-[7.25rem] h-[4.5rem] border bg-gray-100"
                />
                <div>
                  <p className="font-bold text-ellipsis line-clamp-2">
                    {val.title}
                  </p>
                  <Badge color={getProgramColor(val.type)}>
                    {PROGRAM_TYPE[val.type].toUpperCase()}
                  </Badge>
                </div>
                <div className="w-20"></div>
                <div className="w-44">
                  <p className="text-xs text-gray-600">Strata</p>
                  <p className="text-sm font-medium">{val.grade_name ?? "-"}</p>
                  <p className="text-xs text-gray-600">Periode pendaftaran</p>
                  <p className="text-sm font-medium">{`${getFormatDate(
                    val.registration_period_start
                  )} - ${getFormatDate(val.registration_period_end)}`}</p>
                  <p className="text-xs text-gray-600">Harga program</p>
                  <p className="text-sm font-medium">
                    {currencyConverter(Number(val.price))}
                  </p>
                </div>

                <div className="w-20 flex flex-row justify-end gap-2">
                  <div className="flex flex-col">
                    <p className="text-xs text-gray-600 justify-end gap-x-3">
                      Urutan
                    </p>
                    {isEdit || isAdd ? (
                      <>
                        <input
                          type="number"
                          min={0}
                          placeholder="Masukan urutan"
                          className="bg-white h-[2rem] w-[5rem] border-b-[1px] border-b-gray-500 focus:border-b-[1px] focus:border-b-[#FFF200] mb-2 text-right"
                          value={getValues(`detail_urutan`)?.filter((item) => item.program_id === val.id)[0]?.view_priority ?? val.view_priority?.toString() ?? 0}
                          onChange={(e) =>
                            handleUrutanProgram(val.id,parseInt(e.target.value))
                          }
                        />
                      </>
                    ) : (
                      <>
                        <p className="text-sm font-medium">
                          {getValues(`detail_urutan`)?.filter((item) => item.program_id === val.id)[0]?.view_priority?.toString() ?? val.view_priority?.toString() ?? '-'}
                        </p>
                      </>
                    )}
                  </div>
                  <div className="flex flex-col">
                    {isEdit && (
                      <FontAwesomeIcon
                        icon={icon({ name: "trash-alt" })}
                        className="cursor-pointer"
                        onClick={() => handleDeleteProgram(val.id)}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-400">
              Anda belum menambahkan pertemuan apapun.
            </p>
          )}
          {askHomework.map((val, index) => (
            <div
              key={`ask-homework-${val.id}`}
              className="rounded-lg bg-gray-50 px-4 py-2 grid grid-cols-[1fr,4rem,4rem,8rem,1fr] gap-5 items-center"
            >
              <div className="space-y-3">
                <p className="font-bold text-ellipsis line-clamp-1">
                  {val.title}
                </p>
                <Badge color="warning-light">TANYA PR</Badge>
              </div>
              <div>
                <p className="text-xs text-gray-600">Tipe</p>
                <div className="flex items-center gap-1.5">
                  {val.session_type === "video" ? (
                    <FontAwesomeIcon icon={icon({ name: "video" })} />
                  ) : (
                    <FontAwesomeIcon icon={icon({ name: "comment" })} />
                  )}
                  <span className="text-sm font-medium first-letter:uppercase">
                    {val.session_type}
                  </span>
                </div>
              </div>
              <div>
                <p className="text-xs text-gray-600">Durasi</p>
                <p className="text-sm font-medium">
                  {`${val.duration_minute} menit`}
                </p>
              </div>
              <div>
                <p className="text-xs text-gray-600">Harga paket</p>
                <p className="text-sm font-medium">
                  {currencyConverter(Number(val.price))}
                </p>
              </div>
              <div className="justify-self-end flex items-center gap-8">
                <div className="min-w-[4rem]">
                  <span className="text-xs text-gray-600">Jumlah</span>
                  {isEdit || isAdd ? (
                    <Controller
                      control={control}
                      name={`detail.ask_homework_packets.${index}.qty`}
                      render={({ field }) => (
                        <InputNumber
                          label=""
                          className="rounded-lg border border-gray-300 px-3 py-2 text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                          options={{
                            numeral: true,
                            delimiter: ",",
                          }}
                          value={field.value}
                          onChange={(e) => {
                            const value = e.target.rawValue;
                            updateAskHomework(val.id, Number(value));
                          }}
                        />
                      )}
                    />
                  ) : (
                    <p className="text-sm font-medium">{val.qty}</p>
                  )}
                </div>
                <div className="flex items-center gap-x-3">
                  <div>
                    <p className="text-xs text-gray-600">Total harga paket</p>
                    <p className="text-sm font-medium">
                      {currencyConverter(Number(val.price) * (val.qty ?? 0))}
                    </p>
                  </div>
                  {isEdit && (
                    <FontAwesomeIcon
                      icon={icon({ name: "trash-alt" })}
                      className="cursor-pointer"
                      onClick={() => handleDeleteHomework(val.id)}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
          <div className="flex items-center justify-between">
            <p className="bg-[#FFFCE6] w-fit px-3 py-1 rounded flex items-center gap-2">
              <span className="text-gray-700">Total harga seluruh program</span>
              <span className="text-xl font-bold">
                {currencyConverter(Number(total_price))}
              </span>
            </p>
            {(isAdd || isEdit) && (
              <ButtonDropdown
                menuItems={[
                  <button
                    className="w-full flex items-center justify-start gap-x-2 px-3.5 py-2.5 hover:bg-gray-50"
                    onClick={toggleModal}
                  >
                    <p className="text-sm">Program Guruku</p>
                  </button>,
                  <button
                    className="w-full flex items-center justify-start gap-x-2 px-3.5 py-2.5 hover:bg-gray-50"
                    onClick={toggleAskhomework}
                  >
                    <p className="text-sm">Tanya PR</p>
                  </button>,
                ]}
                widthItems="w-40"
              >
                <div className="flex items-center gap-x-1.5 bg-[#FFFCE6] px-4 py-2.5 rounded-lg text-[#8C7E00] border">
                  <FontAwesomeIcon
                    icon={icon({ name: "file-circle-plus" })}
                    className="cursor-pointer"
                  />
                  Tambah program
                  <FontAwesomeIcon
                    icon={icon({ name: "chevron-down" })}
                    className="cursor-pointer"
                  />
                </div>
              </ButtonDropdown>
            )}
          </div>
          <hr />
          <p className="text-xl font-bold">Penentuan harga paket</p>
          {isEdit || isAdd ? (
            <Controller
              control={control}
              name="bundle_price"
              render={({ field }) => (
                <InputNumber
                  label="Harga paket"
                  placeholder="Masukkan harga paket"
                  className="w-[30rem] px-3.5 py-2.5 border rounded-lg"
                  options={{
                    numeral: true,
                    delimiter: ",",
                  }}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.rawValue)}
                />
              )}
            />
          ) : (
            <p className="bg-gray-50 w-fit px-3 py-1 rounded flex items-center gap-2">
              <span className="text-gray-700">Harga bundle</span>
              <span className="text-xl font-bold">
                {currencyConverter(Number(bundle_price))}
              </span>
            </p>
          )}
        </div>
      </div>
      <ModalProgram
        show={showModal}
        onClose={toggleModal}
        onAdd={handleAddPrograms}
        listSelected={programs as unknown as ProgramItem[]}
      />
      <AskHomeworks
        show={showAskhomework}
        onClose={toggleAskhomework}
        onAdd={handleAddHomework}
        listSelected={askHomework as unknown as ProgramItem[]}
      />
    </>
  );
};

export default memo(ProgramsAndPrice);

import { API_URL } from "constant";
import { services } from "./services";
import { AxiosError } from "axios";

type Params = {
  per_page: number;
  search: string;
  order_by: "name" | "phone_number" | "email";
  sort: "asc" | "desc";
  page: number;
};

type List = PaginationResponse & {
  data: User[];
};

type Errors = {
  errors: {
    [key: string]: [string];
  };
  message: string;
};

const useTeacher = () => {
  const getList = async (params?: Params): Promise<List> => {
    try {
      const {
        per_page = 10,
        search = "",
        order_by = "name",
        sort = "asc",
        page = 1,
      } = params ?? {};
      const resp = await services.get("/admin/teachers", {
        params: {
          "per-page": per_page,
          "order-by": order_by,
          search,
          sort,
          page,
        },
      });

      return resp.data?.data;
    } catch (error) {
      return {
        current_page: 1,
        data: [],
        from: 1,
        last_page: 1,
        per_page: params?.per_page ?? 10,
        to: 1,
        total: 0,
      };
    }
  };

  const add = async (params: TeacherPayload) => {
    try {
      await services.post(API_URL.teacher, params);
    } catch (error) {
      const err = error as AxiosError;
      const { errors, message } =
        (err.response?.data as Errors) || ({} as Errors);
      const errMsg = Object.values(errors)[0]?.[0] || message;
      throw errMsg;
    }
  };

  const edit = async (id: string, params: TeacherPayload) => {
    try {
      const { password } = params;
      if (!password) {
        delete params.password;
      }
      await services.patch(`${API_URL.teacher}/${id}`, params);
    } catch (error) {
      const err = error as AxiosError;
      const { errors, message } =
        (err.response?.data as Errors) || ({} as Errors);
      const errMsg = Object.values(errors)[0]?.[0] || message;
      throw errMsg;
    }
  };

  const getDetail = async (id: string): Promise<TeacherData> => {
    try {
      const response = await services.get(`${API_URL.teacher}/${id}`);

      return response.data?.data;
    } catch (error) {
      throw error;
    }
  };

  const deleteTeacher = async (id: string) => {
    try {
      await services.delete(`${API_URL.teacher}/${id}`);
    } catch (error) {
      throw error;
    }
  };

  return {
    getList,
    add,
    deleteTeacher,
    getDetail,
    edit,
  };
};

export default useTeacher;

import { Input, InputNumber, TextArea, UploadImage } from "components";
import { memo, useMemo, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { DatePicker } from "components/datepicker";
import ToggleSwitch from "components/ToggleSwitch";
import { currencyConverter, priceByDiscount } from "utilities";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast from "react-hot-toast";
import useAsset from "services/useAsset";

const Detail = () => {
  const { control, watch, setValue } = useFormContext<TryoutPayload>();
  const isLimited = watch("is_limited_participant");
  const isFlatDisc = watch("is_flat_disc");
  const price = watch("price");
  const discount = watch("discount");
  const [isUpload, setIsUpload] = useState(false);
  const { upload } = useAsset();

  const finalPrice = useMemo(() => {
    if (isFlatDisc) {
      return currencyConverter(price - discount);
    } else {
      return priceByDiscount(price, discount);
    }
  }, [isFlatDisc, price, discount]);

  return (
    <section className="grid gap-y-5">
      <div className="bg-white rounded-xl">
        <p className="font-bold text-xl px-5 py-4 border-b">Informasi</p>
        <div className="p-5 grid grid-cols-[2fr,1fr] gap-x-5">
          <div className="grid gap-y-5">
            <Controller
              control={control}
              name="title"
              render={({ field }) => (
                <Input
                  label="Judul"
                  required
                  placeholder="Masukkan judul program"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <TextArea
                  label="Deskripsi"
                  required
                  isRichText
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
          <Controller
            control={control}
            name="thumbnail_path"
            render={({ field }) => (
              <UploadImage
                width="w-full"
                imgSrc={field.value}
                onDelete={() => field.onChange("")}
                onUpload={(data) => field.onChange(data?.full_asset)}
              />
            )}
          />
        </div>
      </div>
      <div className="flex gap-x-5">
        <div className="bg-white rounded-xl flex-1">
          <p className="font-bold text-xl px-5 py-4 border-b">Konfigurasi</p>
          <div className="p-5 grid gap-y-5">
            <div>
              <p className="text-sm after:content-['*'] after:text-rose-400 mb-1.5">
                Jumlah peserta
              </p>
              <div className="flex items-center gap-x-5">
                <Controller
                  control={control}
                  name="is_limited_participant"
                  render={({ field }) => (
                    <div className="flex items-center gap-x-3">
                      <input
                        type="radio"
                        name="participants"
                        id="unlimited"
                        className="radio checked:bg-primary checked:border-none flex"
                        checked={!field.value}
                        onChange={() => {
                          field.onChange(false);
                          setValue("number_of_participants", null);
                        }}
                      />
                      <label htmlFor="unlimited">Tidak terbatas</label>
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name="is_limited_participant"
                  render={({ field }) => (
                    <div className="flex items-center gap-x-3">
                      <input
                        type="radio"
                        name="participants"
                        id="limited"
                        className="radio checked:bg-primary checked:border-none flex"
                        checked={field.value}
                        onChange={() => field.onChange(true)}
                      />
                      <label htmlFor="limited">Dibatasi</label>
                    </div>
                  )}
                />
              </div>
            </div>
            {isLimited && (
              <Controller
                control={control}
                name="number_of_participants"
                render={({ field }) => (
                  <InputNumber
                    label=""
                    placeholder="Masukkan jumlah peserta"
                    disabled={!isLimited}
                    className="w-full border border-gray-300 px-3.5 py-2.5 rounded-lg"
                    options={{
                      numeral: true,
                      delimiter: ".",
                      numeralDecimalMark: ",",
                    }}
                    value={field.value || ""}
                    onChange={(val) => field.onChange(val.target.rawValue)}
                  />
                )}
              />
            )}
            <div className="grid grid-flow-col gap-x-5">
              <Controller
                control={control}
                name="registration_period_start"
                render={({ field }) => (
                  <DatePicker
                    label="Periode mulai pendaftaran"
                    required
                    value={field.value || ""}
                    onChange={(val) => field.onChange(val)}
                  />
                )}
              />
              <Controller
                control={control}
                name="registration_period_end"
                render={({ field }) => (
                  <DatePicker
                    label="Periode akhir pendaftaran"
                    required
                    value={field.value || ""}
                    onChange={(val) => field.onChange(val)}
                  />
                )}
              />
            </div>
            <div className="grid grid-flow-col gap-x-5">
              <Controller
                control={control}
                name="start_date"
                render={({ field }) => (
                  <DatePicker
                    label="Periode mulai pelaksanaan"
                    required
                    value={field.value || ""}
                    onChange={(val) => field.onChange(val)}
                  />
                )}
              />
              <Controller
                control={control}
                name="end_date"
                render={({ field }) => (
                  <DatePicker
                    label="Periode akhir pelaksanaan"
                    required
                    value={field.value || ""}
                    onChange={(val) => field.onChange(val)}
                  />
                )}
              />
            </div>
            <div className="grid grid-flow-col gap-x-5">
              <Controller
                control={control}
                name="question_each_student"
                render={({ field }) => (
                  <InputNumber
                    label="Jumlah soal"
                    required
                    options={{
                      numeral: true,
                      numeralDecimalMark: ",",
                      delimiter: ".",
                    }}
                    placeholder="Tentukan jumlah soal"
                    className="w-full border px-3.5 py-2.5 rounded-lg"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <Controller
                control={control}
                name="max_attempt"
                render={({ field }) => (
                  <InputNumber
                    label="Jumlah Percobaan"
                    options={{
                      numeral: true,
                      numeralDecimalMark: ",",
                      delimiter: ".",
                    }}
                    placeholder="Kosong maka tidak terbatas"
                    className="w-full border px-3.5 py-2.5 rounded-lg"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>

            <Controller
              control={control}
              name="is_explanation_showed"
              render={({ field }) => (
                <ToggleSwitch
                  label="Tampilkan pembahasan"
                  isChecked={field.value}
                  onChange={(val) => field.onChange(val)}
                />
              )}
            />

            <h1 className="font-bold text-xl my-6">Materi pembahasan soal</h1>
            <Controller
              control={control}
              name="explanation_asset"
              render={({ field }) =>
                field.value?.id ? (
                  <div className="flex items-center bg-gray-200 py-2 px-4 rounded-lg justify-between">
                    <div className="flex items-center gap-x-2">
                      <FontAwesomeIcon icon={icon({ name: "file-lines" })} />
                      <span>{field.value.name}</span>
                    </div>
                    <div className="flex items-center gap-x-2">
                      <FontAwesomeIcon
                        icon={icon({ name: "eye", style: "regular" })}
                        className="mr-3 cursor-pointer"
                        onClick={() =>
                          window.open(field.value?.full_asset, "_blank")
                        }
                      />
                      <FontAwesomeIcon
                        icon={icon({ name: "trash-can" })}
                        className="cursor-pointer"
                        onClick={() => {
                          field.onChange("explanation_asset", {
                            name: "",
                            full_asset: "",
                            id: "",
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <input
                      type="file"
                      name="discussion-material"
                      id="discussion-material"
                      accept="application/pdf"
                      hidden
                      disabled={isUpload}
                      onChange={(e) => {
                        const currentFile = e.target.files?.[0];

                        if (currentFile) {
                          setIsUpload(true);
                          toast.promise(
                            upload({
                              file: currentFile,
                              directory_id: 0,
                              type: "tryout_explanation_asset",
                            }),
                            {
                              loading: "...",
                              success: (response) => {
                                if (response) {
                                  field.onChange({
                                    name: response.name,
                                    full_asset: response.full_asset,
                                    id: response.id,
                                  });
                                }
                                setIsUpload(false);

                                return `Uploaded ${currentFile?.name} successfully!`;
                              },
                              error: () => {
                                setIsUpload(true);
                                return "Gagal upload.";
                              },
                            }
                          );
                        }
                      }}
                    />
                    <label
                      htmlFor="discussion-material"
                      className="flex items-center gap-x-2 bg-primary w-max px-3 py-2 rounded-lg cursor-pointer"
                    >
                      <FontAwesomeIcon icon={icon({ name: "add" })} />
                      <span>Tambah materi pembahasan</span>
                    </label>
                  </>
                )
              }
            />
          </div>
        </div>
        <div className="bg-white rounded-xl flex-1">
          <p className="font-bold text-xl px-5 py-4 border-b">Harga</p>
          <div className="p-5 grid gap-y-5">
            <Controller
              control={control}
              name="price"
              render={({ field }) => (
                <InputNumber
                  label="Harga"
                  required
                  className="w-full px-3.5 py-2.5 border rounded-lg"
                  placeholder="Masukkan harga"
                  options={{
                    numeral: true,
                    numeralDecimalMark: ",",
                    delimiter: ".",
                  }}
                  value={field.value}
                  onChange={(e) => field.onChange(Number(e.target.rawValue))}
                />
              )}
            />
            <div>
              <p className="font-medium text-sm">Diskon</p>
              <div className="px-3.5 py-2.5 border rounded-lg mt-1.5 flex">
                <Controller
                  control={control}
                  name="discount"
                  render={({ field }) => (
                    <InputNumber
                      label=""
                      className="w-full pr-3.5"
                      placeholder="Masukkan diskon"
                      options={{
                        numeral: true,
                        numeralDecimalMark: ",",
                        delimiter: ".",
                      }}
                      value={field.value}
                      onChange={(e) =>
                        field.onChange(Number(e.target.rawValue))
                      }
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="is_flat_disc"
                  render={({ field }) => (
                    <select
                      name="isFlatDiscount"
                      id="isFlatDiscount"
                      value={Number(field?.value)}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        field.onChange(Boolean(value));
                      }}
                    >
                      <option value={1}>Rp</option>
                      <option value={0}>%</option>
                    </select>
                  )}
                />
              </div>
            </div>
            <div>
              <p className="font-medium text-sm">Harga final</p>
              <p className="font-bold text-lg">{finalPrice}</p>
            </div>
            <p className="text-xs text-gray-500">
              * Harga final akan ditampilkan pada mobile dan web app
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default memo(Detail);

import { useCallback, useEffect, useState } from "react";
import { Button } from "components";
import DetailSettingModal from "./fragment/DetailSetting";
import SettingCard from "./fragment/settingCard";
import useHomework from "services/useAskHomework";
import Validation from "./fragment/Modal/validation";

const Setting = () => {
  const { getPackageData } = useHomework();
  const [data, setData] = useState<PackageData[]>([]);
  const [modalShow, setModalShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedCardData, setSelectedCardData] = useState<PackageData>();
  const [showValidation, setShowValidation] = useState(false);
  const [tambah, setTambah] = useState(false)
  
  const initialData = ({
    id : 0,
    title : "",
    description : "",
    session_type : "chat",
    price : "",
    duration_minute : undefined,
    is_active : 0,
    created_at : "",
    updated_at : "",
    commission_percentage : undefined,
    view_priority : 0
  })

  const getData = useCallback(async () => {
    const response: {data: PackageData[]} = await getPackageData({per_page: 100});
    setData(response.data);
  }, [getPackageData])

  useEffect(() => {
    getData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[modalShow, showValidation, refresh])

  const toggleModal = () => {
    setModalShow(!modalShow); 
  };

  const handleCardClick = (data: PackageData, tambah: boolean) => {
    setSelectedCardData(data);
    setTambah(tambah)
    toggleModal();
  };
  
  return (
  <>
    <div className="bg-white mt-5 rounded-xl">
      <div className=" px-5 py-4 border-b flex items-center justify-between">
        <p className="font-bold">Jenis paket</p>
        <Button.Create
            text="Tambah paket"
            onButtonClick={() => handleCardClick(initialData, true)}
          />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 px-5 py-4">
        {data.length ?  data.map((item,index) => {
          return(
              <SettingCard 
                data={item}
                handleClick={() => handleCardClick(item, false)}
                onRefresh={() => setRefresh(!refresh)}
              />
          )} 
        ): <p>belum ada paket</p>}
      </div>
    </div>
    <DetailSettingModal 
      show={modalShow} 
      onClose={toggleModal} 
      data={selectedCardData}
      tambah={tambah}
    />
    <Validation
      show={showValidation}
      onClose={() => {
        setShowValidation(false);
      }}
    />
  </>
  );
};

export default Setting;

import { FC, memo, useCallback, useEffect, useState } from "react";
import { Button } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Disclosure } from "@headlessui/react";
import { useFormContext } from "react-hook-form";

import SelectSyllabus from "../Modal/SelectSyllabus";
import Material from "./Material";
import Exam from "./Exam";
import { ExamKind } from "views/Silabus/Tambah";

const Syllabus: FC = () => {
  const { getValues } = useFormContext();
  const [show, setShow] = useState(false);
  const [data, setData] = useState<SyllabusDetail | null>(null);

  useEffect(() => {
    const syllabusId = getValues("syllabus_id");
    if (syllabusId) {
      const localData = localStorage.getItem("syllabus");
      if (localData) {
        setData(JSON.parse(localData));
      }
    }
  }, [getValues]);

  const handleSubmit = useCallback((item: SyllabusDetail) => {
    localStorage.setItem("syllabus", JSON.stringify(item));
    setData(item);
    setShow(false);
  }, []);

  const renderDisclosureSection = useCallback(
    (examType: ExamKind, title: string) => {
      const examData = data?.exams?.[examType]?.directories;
      return (
        examData && examData.length > 0 && (
          <section className="bg-gray-100 pl-3 pr-5 py-4 rounded-s border">
            <Disclosure defaultOpen={false}>
              {({ open }) => {
                const itemIcon = open
                  ? icon({ name: "chevron-down" })
                  : icon({ name: "chevron-right" });

                return (
                  <>
                    <div className="flex items-center justify-between">
                      <Disclosure.Button
                        className="flex w-full items-center gap-x-3"
                      >
                        <FontAwesomeIcon icon={itemIcon} />
                        <p className="text-xl font-bold">{title}</p>
                      </Disclosure.Button>
                    </div>
                    <Disclosure.Panel className="border-t mt-4 pt-4 grid gap-y-5">
                      <p className="font-bold text-lg">Soal</p>
                      <Exam packageIndex={examType} isPreview directories={examData} />
                    </Disclosure.Panel>
                  </>
                );
              }}
            </Disclosure>
          </section>
        )
      );
    },
    [data]
  );

  return (
    <>
      <div className="w-full bg-white rounded-xl mb-5">
        <div className="px-5 py-4 border-b border-gray-200 flex justify-between">
          <p className="font-bold text-xl">Silabus</p>
          <Button color="primary" onButtonClick={() => setShow(true)}>
            <FontAwesomeIcon icon={icon({ name: "plus" })} />
            <span className="ml-2">{data ? "Ganti" : "Pilih"} silabus</span>
          </Button>
        </div>
        <div className="px-5 py-4">
          {data ? (
            <>
              <div className="mb-8">
                <p className="font-bold text-sm">Judul silabus</p>
                <p className="text-base text-gray-500">{data?.name}</p>
              </div>
              <div className="mb-8">
                <p className="font-bold text-sm">Strata</p>
                <p className="text-base text-gray-500">{`Kelas ${data?.grade_name}`}</p>
              </div>
              <div className="mb-8">
                <p className="font-bold text-sm">Deskripsi</p>
                <p
                  className="text-base text-gray-500"
                  dangerouslySetInnerHTML={{ __html: data?.description || "" }}
                />
              </div>

              <p className="font-bold text-sm mb-1">Pertemuan</p>
              <div className="grid gap-y-5 mb-8">
                {data.packages?.map((item, index) => (
                  <Disclosure key={item.id}>
                    {({ open }) => {
                      const caret = open
                        ? icon({ name: "chevron-down" })
                        : icon({ name: "chevron-left" });
                      return (
                        <div className="rounded-md bg-gray-100">
                          <Disclosure.Button className="flex w-full justify-between items-center pl-3 pr-5 py-3 text-left font-bold">
                            <span>{`Pertemuan ${index + 1}`}</span>
                            <FontAwesomeIcon icon={caret} />
                          </Disclosure.Button>
                          <Disclosure.Panel className="ml-3 mr-5 py-4 text-sm text-gray-500 border-t">
                            <p className="font-medium text-sm mb-2">
                              Judul pertemuan
                            </p>
                            <p className="text-sm text-gray-400 mb-4">
                              {item.title}
                            </p>
                            <p className="font-medium text-sm mb-2">
                              Deskripsi pertemuan
                            </p>
                            <p
                              className="text-sm text-gray-400 mb-4"
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            />
                            <p className="font-medium mb-2 text-lg">Bab</p>
                            <Material data={item.directories} />
                          </Disclosure.Panel>
                        </div>
                      );
                    }}
                  </Disclosure>
                ))}
              </div>

              <p className="font-bold text-sm mb-1">Soal Ujian</p>
              <div className="grid gap-y-5">
                {renderDisclosureSection("uh", "Ulangan Harian")}
                {renderDisclosureSection("pts1", "Penilaian Tengah Semester I")}
                {renderDisclosureSection("pas", "Penilaian Akhir Semester")}
                {renderDisclosureSection("pts2", "Penilaian Tengah Semester II")}
                {renderDisclosureSection("pat", "Penilaian Akhir Tahun")}
              </div>
            </>
          ) : (
            <p>Anda belum menambahkan silabus apapun.</p>
          )}
        </div>
      </div>
      <SelectSyllabus
        show={show}
        onClose={() => setShow(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default memo(Syllabus);